import React from "react";
import './Blog-details.scss';
import { Container, Grid } from "@mui/material"; 

import { useParams as params } from "react-router-dom";
import { Markup } from 'react-render-markup';
import { blogData } from "../../../../config/blogData"; 
import Helmet from 'react-helmet';
const BlogDetailNw = function () {
    const { slug } = params(); 
    const morFiltered = blogData.find(loc => String(loc.slug) === slug);
//   

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{morFiltered.meta_title}</title>
            <meta name='description' content={morFiltered.meta_description} />
            <meta name='keywords' content={morFiltered.meta_keywords} />
        </Helmet>
            <section className="location-banner" style={{ backgroundImage: `url(${morFiltered.img})` }}> 
                <Container>
                    <Grid container className="d-flex align-items-center">
                        <Grid item xs={12}> 
                            <div className="location-inner text-center">  
                                <h1>{morFiltered.title}</h1>  
                            </div> 
                        </Grid> 
                    </Grid>
                </Container>
            </section> 

            <section className="content-sec blog-content">
                <Container>
                    <Grid sm={12} item > 
                       <Markup markup={morFiltered.description} />
                    </Grid>
                </Container>
            </section> 

           
            <div className="partner-logo-none"></div>
        </>
    );
}

export default BlogDetailNw;
