import React from "react";  
import { Container } from "@mui/material"; 
import './footer.scss'  

const Footer = () => { 
   
  
  return (
    <> 
      <footer className="join-footer">
        <Container>
            <p class="text-center">Copyright 2024. All Rights Reserved Rateshop.us</p>
        </Container>
    </footer> 
    </>
  );
};

export default Footer;