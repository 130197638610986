import React from "react"; 
import { Container } from "@mui/material";
import { NavLink } from 'react-router-dom';
import logo  from '../../../assets/public/images/usa/logo.png'; 
import './header.scss';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

const Header = () => {

    const [baseUrl, setBaseUrl] = React.useState('/');

    return (
        <>
            <header class="join-header join-packages">
                <Container>
                    <nav class="navbar navbar-expand-lg">
                        <NavLink to={baseUrl} className="navbar-brand">
                            <img src={logo} className="img-fluid" />
                        </NavLink>
                        {/* <!-- social links  --> */}
                        <div class="header-social-link">
                            <ul>
                                <li>
                                    <div>
                                        <p><CallIcon /> <a href="tel:+888-549-1005">888-549-1005</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p><EmailIcon /> <a href="mailto:careers@rateshop.us" class="cs-mail">careers@rateshop.us</a></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- social links end --> */}
                    </nav>
                </Container>
            </header>
        </>
    );
};

export default Header;
