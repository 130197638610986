import React from "react";
import './privacy-policy.scss'; 
import { Container, Grid } from "@mui/material"; 
import Helmet from 'react-helmet';

const PrivacyPolicy = function () {
 

    return (
        <>            
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy | RateShop Mortgage</title>
            <meta name='description' content="Read the privacy policy of RateShop Mortgage. Learn how we protect your personal information and ensure data security." />
            <meta name='keywords' content="rateshop mortgage, privacy policy" />
        </Helmet>
            <section className="content-banner">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid xs={12} className="text-center" item >
                            <h1>Privacy Policy</h1>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className="content-sec">
                <Container>
                    <Grid sm={12} item >
                        <p>Customer service is very important to all of us. As we continue to improve and expand our services, we recognize our customers' need and desire to preserve their privacy and confidentiality. Safeguarding our customers' privacy is also very important to us. We have adopted standards that help maintain and preserve the confidentiality of customers' nonpublic personal information. The following Statement affirms our continued efforts to safeguard customer information. </p>
                        <h2>Information We Collect </h2>
                        <p>We gather nonpublic personal information about our customers as may be necessary to conduct business with our customers.We collect nonpublic personal information about you from the following sources: </p>
                        <ul>
                            <li>Information we receive from you on applications or other forms, over the telephone or in face-to-face meetings, and via the Internet. Examples of information we receive from you include your name, address, telephone number, social security number, credit history and other financial information. </li>
                            <li>Information about your transactions with us or others. Examples of information relating to your transactions include payment histories, account balances and account activity. </li>
                            <li>Information we receive from a consumer reporting agency. Examples of information from consumer reporting agencies include your credit score, credit reports and other information relating to your creditworthiness. </li>
                            <li>From employers and others to verify information you have given to us. Examples of information provided by employers and others include verifications of employment, income or deposits. </li>
                        </ul>
                        <h2>Information We Disclose </h2>
                        <p>Your personal information will only be retained for the purpose of providing you with our response to your query and will not be made available to any third party except as necessary to be disclosed to any related entity for the purpose intended or as required to be disclosed under law. </p>
                        <p>By submitting data on our website, the visitor is providing explicit consent to transmission of data collected on the website. </p>
                        <p>We treat data as confidential within our firm and require a strict adherence of all our employees to data protection and our confidentiality policies. </p>
                        <p>All visitors, however, should be aware that our website may contain links to other sites that are not governed by this or any other privacy statement.</p>
                        <p>We reserve the right to amend (that is, add to, delete or change) the terms of this Privacy Statement from time to time.</p>
                    </Grid>
                </Container>
            </section> 

            <div className="partner-logo-none"></div>
        </>
    );
}

export default PrivacyPolicy;
