import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from "@mui/material";
import SelectUI from "../../../components/ui/select/SelectUI";

const StepEight = (props) => {
  const { register, control } = useFormContext();
  const [province, setProvince] = useState('');

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const verifyEmail = async (value) => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const response = await fetch("https://api.zerobounce.net/v2/validate?api_key=0b08e679e6894b9bb8ff1f2af9dedc72&email=" + value + "&ip_address=", requestOptions);
      const result = await response.json();
    //   console.log(result.error);
      if (result.status === 'valid') {
        console.log(result.status);
        return true;
      } else if(result.status == 'invalid') {
        return false;
      }else{
        return true;
      }
    } catch (error) {
      console.error('error', error);
      return false;
    }
  };

  return (
    <>
      <div className="heading">
        <h4>Let's check out your Offers!</h4>
        <p>Please Confirm Your Identity</p>
      </div>
      <div className="checkout-offer-form">
        <div className="quick-com-input">
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="first-name"
                variant="outlined"
                placeholder="Full Name"
                fullWidth
                margin="normal"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: { value: true, message: 'Please fill this field.' },
              pattern: { value: /^[A-Za-z -]+$/, message: 'Only allow alphabets' },
              minLength: { value: 2, message: 'Minimum length should be greater than 2' },
              maxLength: { value: 100, message: 'Maximum length should be less than 100' },
            }}
          />

          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="email"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                margin="normal"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Please fill this field',
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" },
              minLength: { value: 5, message: 'Minimum length should be greater than 5' },
              maxLength: { value: 250, message: 'Maximum length should be less than 250' },
              validate: async (v) => {
                if (!v) return 'Please fill this field';
                const isValid = await verifyEmail(v);
                if (!isValid) return 'Invalid email address';
                return true;
              }
            }}
          />
          <SelectUI
            labelId="label"
            id="select"
            default
            name="province"
            style={{ width: '100%' }}
            value={province}
            onChange={provinceHandler}
            displayEmpty
            className="province-select"
            items={[
              { label: 'Province', value: '', disabled: true },
              { label: 'Alberta', value: 'Alberta' },
              { label: 'British Columbia', value: 'British Columbia' },
              { label: 'Manitoba', value: 'Manitoba' },
              { label: 'New Brunswick', value: 'New Brunswick' },
              { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
              { label: 'Northwest Territories', value: 'Northwest Territories' },
              { label: 'Nova Scotia', value: 'Nova Scotia' },
              { label: 'Nunavut', value: 'Nunavut' },
              { label: 'Ontario', value: 'Ontario' },
              { label: 'Prince Edward Island', value: 'Prince Edward Island' },
              { label: 'Quebec', value: 'Quebec' },
              { label: 'Saskatchewan', value: 'Saskatchewan' },
              { label: 'Yukon', value: 'Yukon' },
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default StepEight;
