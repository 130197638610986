import { Container, Grid } from '@mui/material'
import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './footer.scss'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
// import RssFeedIcon from '@mui/icons-material/RssFeed';
import PinterestIcon from '@mui/icons-material/Pinterest';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarIcon from '@mui/icons-material/Star';
import YouTubeIcon from '@mui/icons-material/YouTube';
import shape1 from "../../../../assets/public/images/shape3.png";
import shape2 from "../../../../assets/public/images/shape4.png";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from "../../../shared/logo/Logo";
import logo2 from '../../../../assets/public/images/rateshop-home.svg';
import Cookies from 'universal-cookie';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import CookieConsent from "react-cookie-consent";
import PartnerLogo from '../../../shared/partner-logo/PartnerLogo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary    {...props}
    />
))(({ theme }) => ({}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));


const Footer = () => {
    const [staffDetail, setStaffDetail] = React.useState([]);
    const [baseUrl, setBaseUrl] = React.useState('/');
    const cookies = new Cookies();
    const {slug} = useParams();
    const navigate = useNavigate();
  
    // if(slug != undefined){
  
    //   const data = {
    //     type: 'single',
    //     Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
    //     slug: slug
    //   };
  
  
    //     axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
    //         .then(res => {
    //         if(res.data.status != 200){
    //             navigate('/404', { replace: true });
    //         }else{
    //           setBaseUrl('/'+cookies.get('cookieAgentSlug')+'/');
    //         }
    //     })
  
    
    // }
    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
 
   
    const [expandedLenders, setExpandedLenders] = React.useState('panel1');
    const handleChangeLenders = (panelLenders) => (event, newExpandedLenders) => {
        setExpandedLenders(newExpandedLenders ? panelLenders : false);
    };

    const accordionProvinceBtns = document.querySelectorAll(".locAtions-wrap h3 span");
    let activeProvinceBtn = "";
    accordionProvinceBtns.forEach(function (btn) {
        btn.addEventListener("click", function (e) {
            if (activeProvinceBtn !== this) {
                document.querySelector('.locAtions-wrap li.active')?.classList.remove('active');
            }
            this.closest("li").classList.toggle("active");
            activeProvinceBtn = e.target;
        })
    })
    return (
        <>
        {/* <PartnerLogo/> */}
        <footer>
            <Container>
            <CookieConsent location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#09318b" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}>This website uses cookies to enhance the user experience.</CookieConsent>

                <Accordion onChange={handleChange('locations')}>
                    <AccordionSummary aria-controls="locations-content" id="locations-header">
                        <Typography>Locations <ExpandMoreIcon /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography> 
                            <div className='locAtions-wrap'>
                                <ul>
                                    <li>
                                        <h3><Link>Texas</Link> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                            <li><Link to={`${baseUrl}texas/houston`}>Houston</Link></li>
                                            <li><Link to={`${baseUrl}texas/san-antonio`}>San Antonio</Link></li>
                                            <li><Link to={`${baseUrl}texas/dallas`}>Dallas</Link></li>
                                            <li><Link to={`${baseUrl}texas/austin`}>Austin</Link></li>
                                            <li><Link to={`${baseUrl}texas/forth-worth`}>Forth Worth</Link></li>
                                            <li><Link to={`${baseUrl}texas/el-paso`}>El Paso</Link></li>
                                            <li><Link to={`${baseUrl}texas/arlington`}>Arlington</Link></li>
                                            <li><Link to={`${baseUrl}texas/corpus-christi`}>Corpus Christi</Link></li>
                                            <li><Link to={`${baseUrl}texas/plano`}>Plano</Link></li>
                                            <li><Link to={`${baseUrl}texas/irving`}>Irving </Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><Link>Georgia</Link> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><Link to={`${baseUrl}georgia/atlanta`}>Atlanta</Link></li>
                                        <li><Link to={`${baseUrl}georgia/columbus`}>Columbus</Link></li>
                                        <li><Link to={`${baseUrl}georgia/savannah`}>Savannah</Link></li>
                                        <li><Link to={`${baseUrl}georgia/south-fulton`}>South Fulton</Link></li>
                                        <li><Link to={`${baseUrl}georgia/sandy-springs`}>Sandy Springs</Link></li>
                                        <li><Link to={`${baseUrl}georgia/roswell`}>Roswell</Link></li>
                                        <li><Link to={`${baseUrl}georgia/macon`}>Macon</Link></li>
                                        <li><Link to={`${baseUrl}georgia/johns-creek`}>Johns Creek</Link></li>
                                        <li><Link to={`${baseUrl}georgia/albany`}>Albany</Link></li>
                                        <li><Link to={`${baseUrl}georgia/athens`}>Athens </Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><Link >Florida</Link> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><Link to={`${baseUrl}florida/jacksonville`}>Jacksonville</Link></li>
                                        <li><Link to={`${baseUrl}florida/miami`}>Miami</Link></li>
                                        <li><Link to={`${baseUrl}florida/tampa`}>Tampa</Link></li>
                                        <li><Link to={`${baseUrl}florida/orlando`}>Orlando</Link></li>
                                        <li><Link to={`${baseUrl}florida/st-petersburg`}>St. Petersburg</Link></li>
                                        <li><Link to={`${baseUrl}florida/hialeah`}>Hialeah</Link></li>
                                        <li><Link to={`${baseUrl}florida/port-st-lucie`}>Port St. Lucie</Link></li>
                                        <li><Link to={`${baseUrl}florida/cape-coral`}>Cape Coral</Link></li>
                                        <li><Link to={`${baseUrl}florida/tallahassee`}>Tallahassee</Link></li>
                                        <li><Link to={`${baseUrl}florida/fort-lauderdale`}>Fort Lauderdale </Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* <Accordion onChange={handleChangeLenders('lenders')}>
                    <AccordionSummary aria-controls="locations-content" id="locations-header">
                        <Typography>Lenders <ExpandMoreIcon /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="lenders">
                                <ul>
                                    <li><Link to={`${baseUrl}mortgage-lender/parama-credit-union`}>Parama Credit Union</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/lighthouse-credit-union`}>Lighthouse Credit Union</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/vancouver-city`}>Vancouver City</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/cmls-financials`}>CMLS Financials</Link></li> 
                                </ul>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion> */}
                <div className='footer-shapes'>
                    <img src={shape1} alt="" className='shape-1' />
                    <img src={shape2} alt="" className='shape-2' />
                </div>
                <Grid container>
                    <Grid item xs={12} sm={6} md={12} lg={3}>
                            <div className="footer-logo">
                            <img src={logo2} alt="logo" />
                        </div>
                        <div className='social-icons'>
                            <ul className='d-flex align-items-center'>
                                <li>
                                    <NavLink to="https://web.facebook.com/RateShopMortgage/" aria-label="Facebook">
                                        <FacebookIcon />
                                    </NavLink>
                                </li>
                                  <li>
                                    <NavLink to="https://x.com/rateshop_us" aria-label="Twitter">
                                        <TwitterIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.instagram.com/rateshopmortgage/" aria-label="Instagram">
                                        <InstagramIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.tiktok.com/@rateshop.mortgage" aria-label="Tiktok">
                                    <svg xmlns="http://www.w3.org/2000/svg" className='tiktokcs' shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 1000 1000"><path d="M906.25 0H93.75C42.19 0 0 42.19 0 93.75v812.49c0 51.57 42.19 93.75 93.75 93.75l812.5.01c51.56 0 93.75-42.19 93.75-93.75V93.75C1000 42.19 957.81 0 906.25 0zM684.02 319.72c-32.42-21.13-55.81-54.96-63.11-94.38-1.57-8.51-2.45-17.28-2.45-26.25H515l-.17 414.65c-1.74 46.43-39.96 83.7-86.8 83.7-14.57 0-28.27-3.63-40.35-9.99-27.68-14.57-46.63-43.58-46.63-76.97 0-47.96 39.02-86.98 86.97-86.98 8.95 0 17.54 1.48 25.66 4.01V421.89c-8.41-1.15-16.95-1.86-25.66-1.86-105.01 0-190.43 85.43-190.43 190.45 0 64.42 32.18 121.44 81.3 155.92 30.93 21.72 68.57 34.51 109.14 34.51 105.01 0 190.43-85.43 190.43-190.43V400.21c40.58 29.12 90.3 46.28 143.95 46.28V343.03c-28.89 0-55.8-8.59-78.39-23.31z"/></svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.linkedin.com/company/rateshop-mortgage-llc/" aria-label="Linkedin">
                                        <LinkedInIcon />
                                    </NavLink>
                                </li> 
                                {/* <li */}
                                    {/* <NavLink to="https://www.pinterest.com/RateShopCanada/" aria-label="Pininterest">
                                        <PinterestIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.youtube.com/@rateshopinc.380" aria-label="Youtube">
                                        <YouTubeIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://ca.trustpilot.com/review/rateshop.ca" aria-label="Trustpilot">
                                        <StarIcon />
                                    </NavLink>
                                </li>   */}
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4>Quick Links</h4>
                        </div>
                        <ul className='footer-links'>
                            <li><NavLink to='/'>Home</NavLink> </li> 
                            <li><NavLink to="/contact">Contact</NavLink></li>
                            <li><NavLink to="/calculator">Calculator</NavLink></li>  
                        </ul>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4>Other Links</h4>
                        </div>
                        <ul className='footer-links'>
                            <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li> 
                            <li><NavLink to="/legal-disclaimer">Legal Disclaimer</NavLink></li> 
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4>Contact Information</h4>
                        </div>
                        <ul className='footer-links contact-information'>
                            <li>
                                <NavLink to="https://maps.app.goo.gl/H4dJ78SXsXRTafis9" target='_blank'><LocationOnIcon/>457 Laurence Dr Suite 463 Heath TX 75032</NavLink>
                            </li>
                            <li>
                                <NavLink to="tel:888-549-1005"><CallIcon/>Local: 888-549-1005</NavLink>
                            </li> 
                            <li>
                                <NavLink to="mailto:help@rateshop.us"><EmailIcon/>  help@rateshop.us</NavLink>
                            </li> 
                            <li>
                                <NavLink to="#"> NMLS# 1862092</NavLink>
                            </li> 
                        </ul>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <div className='foot-about'>
                            <p>As prohibited by federal law, we do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.
<br></br>
RateShop Mortgage LLC is a licensed mortgage broker in FL, GA, and TX</p>
                        </div>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <div className='copright'>
                            <p className='pb-0'>Copyright 2024. All Rights Reserved Rateshop.us</p>
                            <p class="designed-by pt-0"><small>Design & Marketing by <Link to="https://nanoqode.com/" target="_blank">Nanoqode.com</Link></small></p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </footer>  
        </>
    )
}

export default Footer