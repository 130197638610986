import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import "./home.scss";
import AOS from 'aos';
import "aos/dist/aos.css";
import GoogleReviews from '../../../../components/shared/google-reviews/reviews';
import CallIcon from '@mui/icons-material/Call';
import agent from '../../../../assets/public/images/agent-3.png';
import {
  Link
} from "react-router-dom";
import banner from '../../../../assets/public/images/usa/home/banner.png';
import PartnerLogo2 from "../../../../components/shared/partner-logo2/PartnerLogo";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"; 
import Helmet from 'react-helmet'; 
import mortgageSolution from '../../../../assets/public/images/usa/home/mortgage-solutions-for-every-situation-regardless-credit-income.png';
import startMortgage from '../../../../assets/public/images/usa/home/start-a-mortgage-review-with-no-strings-attached.png';
import OurLoan from '../../../../assets/public/images/usa/home/our-loan-officers-will-guide-you-step-by-step.png';
import setUp from '../../../../assets/public/images/usa/home/set-up.png'; 
// types of loans
import fha from '../../../../assets/public/images/usa/loan-type/FHA.png';
import va from '../../../../assets/public/images/usa/loan-type/VA.png';
import usda from '../../../../assets/public/images/usa/loan-type/USDA.png';
import conventional from '../../../../assets/public/images/usa/loan-type/Conventional.png';
import dscr from '../../../../assets/public/images/usa/loan-type/DSCR.png';
import bankStatement from '../../../../assets/public/images/usa/loan-type/Bank-Statement.png';
import Loan1099 from '../../../../assets/public/images/usa/loan-type/1099.png';
import assets from '../../../../assets/public/images/usa/loan-type/Asset-Based.png';
import noIncome from '../../../../assets/public/images/usa/loan-type/No-Income.png';
import plLoans from '../../../../assets/public/images/usa/loan-type/Profit-Loss.png';
import foreigNnational from '../../../../assets/public/images/usa/loan-type/Foreign-National.png';
// Sets  up apart
import apart1 from '../../../../assets/public/images/usa/sets-up-apart/1.png';
import apart2 from '../../../../assets/public/images/usa/sets-up-apart/2.png';
import apart3 from '../../../../assets/public/images/usa/sets-up-apart/3.png';
import apart4 from '../../../../assets/public/images/usa/sets-up-apart/4.png';
import apart5 from '../../../../assets/public/images/usa/sets-up-apart/5.png';
import apart6 from '../../../../assets/public/images/usa/sets-up-apart/6.png';
// Team Member  

import ArlanZuberi from '../../../../assets/public/images/usa/team-member/8.png';
import DarnellSmith from '../../../../assets/public/images/usa/team-member/2.png';
import NatashaStaplowski from '../../../../assets/public/images/usa/team-member/6.png';
import AngleOgbele from '../../../../assets/public/images/usa/team-member/4.png';

import mortgageImg from '../../../../assets/public/images/usa/home/mortgage.png';
import approvalGif from '../../../../assets/public/images/usa/home/approval.gif';

import logo from '../../../../assets/public/images/usa/logo.png';



import "swiper/css";
import "swiper/css/navigation";
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { approval } from "../../../../core/Images";
const Home = () => {
  let navigate = useNavigate();
  const cookies = new Cookies();

  const routeChange = () => {
    var path = '';
    if (cookies.get('cookieAgentSlug') != '' && cookies.get('cookieAgentSlug') != undefined) {
      var path = `apply/` + cookies.get('cookieAgentSlug');
    } else {
      var path = `apply`;
    }
    navigate(path);
  }
  const [baseUrl, setBaseUrl] = React.useState('/');
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    if (cookies.get('cookieAgentSlug') != undefined && cookies.get('agentSite')) {
      setBaseUrl('/' + cookies.get('cookieAgentSlug') + '/');
    }
  }, []);
  var option3 = {
    items: 1,
    responsiveClass: true,
    dots: false,
    autoplay: true,
    nav: false,
    autoWidth: false,
    loop: true,
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Compare Mortgage Rates in Florida, Texas, and Georgia | Best Home Loans | RateShop Mortgage</title>
        <meta name='description' content='Find the best mortgage rates in Florida, Texas, and Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.' />
        <meta name="keywords" content="best mortgage rates Texas, home loans Texas, compare mortgage rates Texas, lowest mortgage rates Texas, mortgage refinancing Texas, first-time home buyer Texas, mortgage pre-approval Texas, online mortgage application Texas, mortgage rate calculator Texas, best mortgage lenders Texas, fixed mortgage rates Texas, variable mortgage rates Texas, home equity loans Texas, private mortgage lenders Texas, mortgage broker Texas, mortgage renewal Texas, self-employed mortgage Texas, affordable mortgage rates Texas, best mortgage rates Florida, home loans Florida, compare mortgage rates Florida, lowest mortgage rates Florida, mortgage refinancing Florida, first-time home buyer Florida, mortgage pre-approval Florida, online mortgage application Florida, mortgage rate calculator Florida, best mortgage lenders Florida, fixed mortgage rates Florida, variable mortgage rates Florida, home equity loans Florida, private mortgage lenders Florida, mortgage broker Florida, mortgage renewal Florida, self-employed mortgage Florida, affordable mortgage rates Florida, best mortgage rates Georgia, home loans Georgia, compare mortgage rates Georgia, lowest mortgage rates Georgia, mortgage refinancing Georgia, first-time home buyer Georgia, mortgage pre-approval Georgia, online mortgage application Georgia, mortgage rate calculator Georgia, best mortgage lenders Georgia, fixed mortgage rates Georgia, variable mortgage rates Georgia, home equity loans Georgia, private mortgage lenders Georgia, mortgage broker Georgia, mortgage renewal Georgia, self-employed mortgage Georgia, affordable mortgage rates Georgia" />
      </Helmet>

      {/* banner start here */}
      <section className="banner main-banner">
        <img src={banner} alt="Get Canada's Lowest Mortgage Rates" className="banner-img" />
        <Container>
          <Grid container>
            <Grid lg={7} md={6} xs={12} className="d-flex flex-column align-items-start tab-100" item data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
              <div className="heading"  >
                <h1>
                  Get Approved in minutes, not days!
                </h1>
              </div>
              <div className="bottom-details">
                <ul>
                  <li>Compare upto 30 Lenders & Save thousands</li>
                  <li>Setting a Standard in Technology & Service </li>
                  <li>Secure your Best Deal when we shop for you </li>
                </ul>
                <div className="call-btn">
                  <a href="tel:888-549-1005"><CallIcon />888-549-1005</a> 
                  <div className="agent-contact-outer">
                    <div className="agent-img">
                      <img src={agent} alt=""/>
                      <span className="status-display"></span>
                    </div>
                    <div className="msg-info">
                      <h5>Sarah - MLO Online</h5>
                      <p><span className="status">Away </span><span className="average-respone-time"> Avg. respone time: 1Hour</span></p>
                    </div>
                  </div>
                </div>
                <div className="info-text">
                  <h3>Call for a Free Mortgage Saving Report</h3>
                  <p>No Credits Checks Required</p>
                </div>
                <div className="d-flex flex-wrap">
                  <NavLink to="/apply" className="mt-20 cs-btn MuiButton-contained MuiButton-containedSecondary hover-green mr-10">Contact Us</NavLink> 
                  <NavLink to="https://rateshop.my1003app.com?time=1708732600845" className="mt-20 cs-btn MuiButton-contained MuiButton-containedSecondary hover-green">Apply Now</NavLink> 
                </div>
              </div> 
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Get started today start*/}
      <section className="get-started-today">
        <Container>
          <div className="heading text-center"><h2><span>Let’s Chat </span>    about Your Mortgage Goals </h2>
            <div className="quick-com-parent">
              <div className="quick-container">
                <div className="q-i-wrap">
                  <div className="quick-inner no-border">
                    <div className="MuiGrid-root MuiGrid-container css-1d3bbye">
                      <div className="quick-form-inner">
                        <div className="step-com-main steps1 steps-animate">
                          <div className="step-radio-box">
                            <div className="step-radio-outer">
                              <NavLink to="https://purchase.rateshop.us/" target="_blank">
                                <img src={mortgageImg} alt="" />
                                <h4>Home Purchase</h4>
                              </NavLink><span>Pre-Approvals &amp; New Purchases</span>
                            </div>
                            <div className="step-radio-outer">
                              <NavLink to="https://refi.rateshop.us/" target="_blank">
                                <img src={mortgageImg} alt="" />
                                <h4>Home Refinance</h4>
                              </NavLink><span>Debt Consolidation &amp; One Lower Payment</span>
                            </div>
                            <div className="step-radio-outer">
                              <NavLink to="https://reverse.rateshop.us/" target="_blank">
                                <img src={mortgageImg} alt="" />
                                <h4>Reverse Mortgage</h4>
                              </NavLink><span>Same Mortgage Balance &amp; Lower Rates</span>
                            </div>
                            <div className="step-radio-outer">
                              <NavLink to="https://equity.rateshop.us/" target="_blank">
                                <img src={mortgageImg} alt="" />
                                <h4>Home Equity</h4>
                              </NavLink><span>Access to Home Equity Loans and Home Equity Line of Credit</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Container>
      </section> 
      {/* Reviews start here */}
      <GoogleReviews/> 

      {/* Lenders logos */}
      <PartnerLogo2 />

      {/* type of loan start */}
      <section className="types-of-loans">
        <Container>
          <div className="heading text-center"><h2><span>Mortgage Loan Types </span> for All Income & Credit</h2></div>
          <div className="sub-heading text-center"><h2>Traditional Mortgage Loans</h2></div>
          <Grid container className="justify-content-center row-gap-30">
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/fha-loans" className="loans-wrap">
              <figure>
                <img src={fha} alt=""></img>
              </figure>
              <h3>FHA</h3>
              <p>Whether you're a first-time homebuyer or looking to refinance, an FHA loan provides a pathway to homeownership. Explore the possibilities and take the first step towards owning your own home. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/va-loans" className="loans-wrap">
              <figure>
                <img src={va} alt=""></img>
              </figure>
              <h3>VA</h3>
              <p>Take advantage of this unique opportunity to secure your dream home and enjoy the financial advantages that come with a VA loan. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/usda" className="loans-wrap">
              <figure>
                <img src={usda} alt=""></img>
              </figure>
              <h3> USDA </h3>
              <p>Discover the possibilities and take advantage of this government-backed loan program to achieve your dream of homeownership in eligible rural communities. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/conventional" className="loans-wrap">
              <figure>
                <img src={conventional} alt=""></img>
              </figure>
              <h3>CONVENTIONAL</h3>
              <p>With various down payment options and loan terms available, you can tailor your mortgage to fit your specific needs. Explore the advantages of a conventional loan and take the first step towards owning your dream home. </p>
              </NavLink>
            </Grid>

          </Grid>
          <div className="sub-heading text-center mt-50"><h2>Non-QM Loans</h2></div>
          <Grid container className="justify-content-center row-gap-30">
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/dscr" className="loans-wrap">
              <figure>
                <img src={dscr} alt=""></img>
              </figure>
              <h3>DSCR  Loans</h3>
              <p>With a focus on the property's ability to generate sufficient income to cover debt payments, DSCR loans offer flexibility and competitive terms. Explore this specialized loan option and secure the financing you need for your commercial property investment. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/bank-statement-loans" className="loans-wrap">
              <figure>
                <img src={bankStatement} alt=""></img>
              </figure>
              <h3>Bank Statement Loans</h3>
              <p>With flexible qualification criteria and competitive rates, Bank Statement loans provide an alternative solution for borrowers who may not meet conventional lending requirements. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/1099-loans" className="loans-wrap">
              <figure>
                <img src={Loan1099} alt=""></img>
              </figure>
              <h3>1099 Loans</h3>
              <p>With flexible qualification criteria and tailored terms, 1099 loans provide an opportunity for those with non-traditional income sources to secure the financing they need. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/p-l-loans" className="loans-wrap">
              <figure>
                <img src={plLoans} alt=""></img>
              </figure>
              <h3>P & L Loans</h3>
              <p>Whether you need funds for expansion, equipment purchase, or working capital, explore the benefits of a P&L loan and fuel the growth of your business. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/asset-utilization" className="loans-wrap">
              <figure>
                <img src={assets} alt=""></img>
              </figure>
              <h3>Asset Utilization</h3>
              <p>Explore the benefits of Asset Utilization and discover how it can help your business achieve optimal performance and success. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/no-income-loan" className="loans-wrap">
              <figure>
                <img src={noIncome} alt=""></img>
              </figure>
              <h3>No Income Loan</h3>
              <p>Whether you're self-employed, have irregular income, or rely on non-traditional sources, a No Income loan provides flexibility and access to funds. </p>
              </NavLink>
            </Grid>
            <Grid sm={6} md={4} lg={3} className="d-flex" item>
              <NavLink to="/mortgage-program/foreign-national" className="loans-wrap">
              <figure>
                <img src={foreigNnational} alt=""></img>
              </figure>
              <h3>Foreign National</h3>
              <p>With flexible qualification criteria and specialized terms, Foreign National loans cater to the unique needs and circumstances of international buyers. </p>
              </NavLink>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* why choose rateshop start */}
       <section className="why-choose-rateshop get-approved">
        <Container>
          <div className="heading">
            <h2>
              <span>Pre Approval </span>  & Quick Qualification
            </h2>
          </div>
          <div className="choose-rate-content">
            <Grid container className="align-items-center">
              <Grid md={12} lg={12} item>
                <img src={approvalGif} alt="Pre Approval  & Quick Qualification"/>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section> 

      {/* set up apart start */}
      <section className="why-choose-rateshop setup">
        <Container>
          <div className="heading">
            <h2>
              <span>See Why </span>    Client's Choose <img src={logo} alt="Rateshop"/>
            </h2>
          </div>
          <div className="choose-rate-content">
            <Grid container className="align-items-center">
              <Grid md={12} lg={8} item>
                <Grid container>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart1} alt="Compare Rates, Lenders and Your Savings" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>We care about your personalized Mortgage Loan needs </span> </h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart2} alt="Unbiased Mortgage Advice to help Financial Growth" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>Unbiased Advise to help Choose your Mortgage Loan  </span> </h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart3} alt="Digital Experience, Guaranteed lowest Rates" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>More Lenders means More Mortgage Loan Options </span> </h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart4} alt="Digital Experience, Guaranteed lowest Rates" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>Dedicated Team of Expert Mortgage Loan Officers </span> </h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart5} alt="Digital Experience, Guaranteed lowest Rates" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>Get the Maximum Loan to Value on Your Mortgage Offers </span> </h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={apart6} alt="Digital Experience, Guaranteed lowest Rates" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title"><span>Easy & Convenient Process Application to Funding </span> </h2>
                  </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12}>
                <div className="center-img" >
                  <img src={setUp} alt="Get Best Mortgage Rates in Canada" width="541" height="366" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000" />
                </div>
              </Grid>
            </Grid>
            <div className="d-flex justify-content-center mt-20">
              <Link to={`${window.location.origin}/apply${baseUrl}`}>
                <Button variant="contained">Get Started Now</Button>
              </Link>
            </div>
          </div>
        </Container>
      </section>

      {/* expert advice start */}
      <section className="expert-advice">
        <div className="expert-images">
          <OwlCarousel
            {...option3}
          >
            <div className="item">
              <figure>
                <img src={AngleOgbele} alt="expert" className="sarah-img" />
              </figure> 
            </div>
            <div className="item">
              <figure>
                <img src={NatashaStaplowski} alt="expert" className="sarah-img" />
              </figure> 
            </div>
            <div className="item">
              <figure>
                <img src={DarnellSmith} alt="expert" />
              </figure> 
            </div>
            <div className="item">
              <figure>
                <img src={ArlanZuberi} alt="expert" />
              </figure> 
            </div>
          </OwlCarousel>
        </div>
        <Container>
          <Grid container className="align-items-center">
            <Grid sm={12} md={6} lg={6} item>
            </Grid>
            <Grid sm={12} md={6} lg={6} item className="expert-advise-outer">
              <div className="expert-advice-text">
                <div className="heading">
                  <h2><span>Fast Mortgage</span> Approvals by a Trusted Name </h2>
                </div>
                <h3>#YourBestRate, Everytime! </h3>
                <ul>
                  <li><p>Get an Unbiased Choice of lender </p></li>
                  <li><p>Program and rates that suits your personal needs. </p></li>
                  <li><p>Dedicated Loan Office from start to finish </p> </li>
                  <li><p>Unmatched Technology and convenience </p></li>
                </ul>
                <div className="expert-low-rates">
                  <ul>
                    <li>
                      <figure>
                        <img src={startMortgage} alt="No Credit Checks" height="110" width="110" />
                      </figure>
                      <h4>Start a Mortgage Review with No Strings Attached </h4>
                    </li>
                    <li>
                      <figure>
                        <img src={OurLoan} alt="Review and Compare Mortgage Rates Online" height="110" width="110" />
                      </figure>
                      <h4>Our Loan Officer’s will guide you step by step </h4>
                    </li>
                    <li>
                      <figure>
                        <img src={mortgageSolution} alt="Haggle Free Mortgage Rates" height="110" width="110" />
                      </figure>
                      <h4>Mortgage Solutions for Every Situation regardless Credit & Income</h4>
                    </li>
                  </ul>
                  <Button onClick={routeChange} variant="contained">Let's get started</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

    </>
  );
};

export default Home;
