import React from "react";
import { Container, Grid } from "@mui/material";
import Helmet from 'react-helmet';
const LegalDisclaimer = function () {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Legal Disclaimer | RateShop Mortgage</title>
            <meta name='description' content="Read the legal disclaimers for RateShop Mortgage. Learn about our legal terms, conditions, and liability disclaimers for mortgage services in these states." />
            <meta name='keywords' content="rateshop mortgage, legal disclaimer" />
        </Helmet>
            <section className="content-banner">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid xs={12} className="text-center" item >
                            <h1 >Legal Disclaimer </h1>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className="content-sec">
                <Container>
                    <Grid sm={12} item >
                        <p>We hereby authorize you to view and print information on this website subject to it being used for informational and non-commercial purposes. </p>
                        <p>The information contained in this website is believed to be reliable, but we do not warrant its completeness, timeliness or accuracy. The information on this website is not intended as an offer or solicitation for any mortgage product or any financial instrument. The information and materials contained in this website - and the terms and conditions of the access to and use of such information and materials - are subject to change without notice. Products and services described may differ among geographic locations, offices and as a result of individual conditions. Not all products and services are offered at all locations. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website. </p>
                        <p>It is our intention that data provided on a subject is of a general nature. Our website does not represent an exhaustive treatment of subjects nor is the information intended to constitute accounting, tax, legal, consulting or other professional advice.  </p>
                        <p>Prior to making any decision or taking any action we kindly request you to contact your tax or legal advisors.  </p>
                        <p>Please use this document and information at your own risk. The content of this site is copyrighted and therefore any unauthorized use of any materials on this website may violate copyright, trademark, and other laws.  </p>
                        <p>Materials on this website may not be modified, reproduced, or publicly displayed, distributed or performed for any public or commercial purposes prior to our approval. </p>


                        <p>As prohibited by federal law, we do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.</p>
                        <p>RateShop Mortgage LLC is a licensed mortgage broker in FL, GA, and TX</p>
                        <p>The following states require disclosure of licensing information. (If your state is not listed, it does not require a specific license disclosure):</p><br></br>
                        <p><strong>ALABAMA</strong><br></br>
                            Mortgage Brokers License#: 23119<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            State Banking Department of Alabama<br></br>
                            Bureau of Loans<br></br>
                            401 Adams Avenue, Suite 680<br></br>
                            Montgomery, AL 36104<br></br>
                            Phone: (334) 242-3452</p>
                        <br></br>
                        <p><strong>ARIZONA</strong><br></br>
                            Mortgage Broker License#: 1007405<br></br>
                            Mortgage Broker Branch License#: 1955709<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions<br></br>
                            100 N. 15th Avenue, Suite 261<br></br>
                            Phoenix, AZ 85007<br></br>
                            Phone: (602) 771-2800<br></br>
                            Fax: 602-381-1225</p>
                        <br></br>

                        <p><strong>CALIFORNIA</strong><br></br>
                            Financing Law License#: 60DBO-51506<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Protection and Innovation<br></br>
                            Financing Law<br></br>
                            320 West 4th Street, Suite 750<br></br>
                            Los Angeles, CA 90013-2344<br></br>
                            Phone: (866) 275-2677</p>



                        <br></br>
                        <p><strong>COLORADO</strong><br></br>
                            Mortgage Company Registration#: None<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Colorado Division of Real Estate
                            1560 Broadway, Suite 925
                            Denver, CO 80202
                            Phone: (303) 894-2166
                            Fax: 303-894-2683</p>

                        <br></br>
                        <p><strong>CONNECTICUT</strong><br></br>
                            Mortgage Broker License#: MB-891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Banking
                            Consumer Credit Division
                            260 Constitution Plaza
                            Hartford, CT 06103-1800
                            Phone: (860) 240-8225</p>
                        <br></br>

                        <p><strong>DELAWARE</strong><br></br>
                            Broker License#: 039443<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Office of the State Bank Commissioner
                            1110 Forrest Avenue
                            Dover, DE 19904
                            Phone: (302) 739-4235
                            Fax: 302-739-3609</p>

                        <br></br>
                        <p><strong>FLORIDA</strong><br></br>
                            Mortgage Broker License#: MBR1640<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Office of Financial Regulation
                            Division of Finance
                            Bureau of Regulatory Review
                            200 East Gaines Street
                            Tallahassee, FL 32399
                            Phone: (850) 410-9895
                            Fax: 850-410-9882</p>
                        <br></br>

                        <p><strong>GEORGIA</strong><br></br>
                            Mortgage Broker/Processor License/Registration#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Banking and Finance<br></br>
                            NDFI Division<br></br>
                            2990 Brandywine Road, Suite 200cx<br></br>
                            Atlanta, GA 30341-5565<br></br>
                            Phone: (770) 986-1136<br></br>
                            Fax: 770-986-1029 </p>
                        <br></br>

                        <p><strong>HAWAII</strong><br></br>
                            Mortgage Loan Originator Company License#: HI-891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Financial Institution<br></br>
                            Department of Commerce & Consumer Affairs<br></br>
                            King Kalakaua Building<br></br>
                            335 Merchant Street, Room 221<br></br>
                            Honolulu, HI 95813<br></br>
                            Phone: (808) 586-2820<br></br>
                            Fax: 808-586-2818<br></br>
                            UWM In-State Branch ID: 2388551<br></br>
                            Address:<br></br>
                            4744 Matsonia Drive<br></br>
                            Honolulu, HI 96815<br></br>
                            Phone: (763) 219-8484<br></br>
                            Fax: 763-219-8405</p>

                        <br></br>
                        <p><strong>IDAHO</strong><br></br>
                            Mortgage Broker/Lender License#: MBL-2080891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Finance<br></br>
                            800 Park Boulevard, Suite 200<br></br>
                            Boise, ID 83712<br></br>
                            Phone: (208) 332-8000</p>

                        <br></br>
                        <p><strong>ILLINOIS</strong><br></br>
                            Residential Mortgage License#: MB.6761787<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong>  REGULATOR:</strong><br></br>
                            Department of Financial and Professional Regulation, Division of Banking
                            100 W. Randolph, 9th Floor<br></br>
                            Chicago IL 60601<br></br>
                            Phone: (800) 532-8785</p>
                        <p><strong>DISCLAIMER:</strong><br></br>
                            State Of Illinois Community Reinvestment Notice: The Department of Financial and Professional Regulation (Department) evaluates our performance in meeting the financial services needs of this community, including the needs of low-income to moderate-income households. The Department takes this evaluation into account when deciding on certain applications submitted by us for approval by the Department. Your involvement is encouraged. You may obtain a copy of our evaluation. You may also submit signed, written comments about our performance in meeting community financial services needs to the Department.</p>
                        <br></br>

                        <p><strong>INDIANA</strong><br></br>
                            Loan Broker License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR: </strong><br></br>
                            Secretary of State<br></br>
                            Securities Division<br></br>
                            302 W. Washington Street Room E111<br></br>
                            Indianapolis, IN 46204<br></br>
                            Phone: (317) 232-6681</p>

                        <br></br>
                        <p><strong>KANSAS</strong><br></br>
                            Mortgage Company License#: MC.0026210<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Office of the State Bank Commissioner<br></br>
                            Division of Consumer & Mortgage Lending<br></br>
                            700 SW Jackson Street, Suite 300<br></br>
                            Topeka, KS 66603<br></br>
                            Phone: (785) 296-2266</p>
                        <br></br>

                        <p><strong>KENTUCKY</strong><br></br>
                            Mortgage Broker License#: MB765976<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions<br></br>
                            Mail Stop 2 SW 19, 500 Mero St.<br></br>
                            Frankfort, KY 40601<br></br>
                            Phone: (502) 782-9020<br></br>
                            Fax: 502-573-8787</p>

                        <br></br>
                        <p><strong>LOUISIANA</strong><br></br>
                            Residential Mortgage Lending License#: None<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Office of Financial Institutions<br></br>
                            8660 United Plaza Blvd<br></br>
                            Second Floor<br></br>
                            Baton Rouge, LA 70809<br></br>
                            Phone: (225) 925-4660<br></br>
                            Fax: 225-925-4548</p>
                        <br></br>

                        <p><strong>MAINE</strong><br></br>
                            Loan Broker License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Professional & Financial Regulation<br></br>
                            Bureau of Consumer Credit Protection<br></br>
                            35 State House Station<br></br>
                            Augusta, ME 04333<br></br>
                            Phone: (207) 624-8527</p>
                        <br></br>

                        <p><strong>MARYLAND</strong><br></br>
                            Mortgage Lender License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Labor, Licensing & Regulation<br></br>
                            500 N. Calvert Street, Suite 402<br></br>
                            Baltimore, MD 21202<br></br>
                            Phone: (410) 230-6155<br></br>
                            Fax: 410-333-0475</p>
                        <br></br>

                        <p><strong>MASSACHUSETTS</strong><br></br>
                            Mortgage Broker License#: MB891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Banks<br></br>
                            1000 Washington St., 10th Floor<br></br>
                            Boston, MA 02118-6400<br></br>
                            Phone: (617) 956-1500 ext. 61480<br></br>
                            Fax: 617-956-1599<br></br>
                            MORTGAGE BROKER ONLY, NOT A MORTGAGE LENDER OR MORTGAGE CORRESPONDENT LENDER</p>

                        <br></br>
                        <p><strong>MICHIGAN</strong><br></br>
                            1st Mortgage Broker License#: FL0023338<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Insurance & Financial Services<br></br>
                            530 W. Allegan St, 7th Floor<br></br>
                            Lansing, MI 48933<br></br>
                            Phone: (517) 284-8800
                            Fax: 517-284-8837</p>

                        <br></br>
                        <p><strong>MINNESOTA</strong><br></br>
                            Residential Mortgage Originator License#: MN-MO-891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Commerce<br></br>
                            85 7th Place East, Suite # 280<br></br>
                            Saint Paul, MN 55101<br></br>
                            Phone: (651) 539-1600</p>
                        <br></br>

                        <p><strong>MISSISSIPPI</strong><br></br>
                            Mortgage Broker License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Mississippi Department of Banking & Consumer Finance<br></br>
                            P.O. Box 12129<br></br>
                            Jackson, MS 39236-2129<br></br>
                            Phone: (601) 321-6901<br></br>
                            Fax: 601-321-6933</p>
                        <br></br>

                        <p><strong>MISSOURI</strong><br></br>
                            Mortgage Company License#: 891464<br></br>
                            Mortgage Company Brance License#: 2421135<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Finance<br></br>
                            Truman State Office Building<br></br>
                            301 West High Street<br></br>
                            Room 630<br></br>
                            Jefferson City, MO 65101<br></br>
                            Phone: (573) 751-4243</p>

                        <br></br>
                        <p><strong>NEVADA</strong><br></br>
                            Mortgage Company License#: 5690<br></br>
                            Mortgage Company Branch License#: 2470407<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Mortgage Lending<br></br>
                            1830 College Parkway, Suite 100<br></br>
                            Carson City, NV 89706<br></br>
                            Phone: (775) 684-7060<br></br>
                            Fax: 775-684-7061</p>
                        <br></br>
                        <p><strong>NEW HAMPSHIRE</strong><br></br>
                            Mortgage Broker License#: 25014-MBR<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            New Hampshire Banking Department<br></br>
                            53 Regional Drive, Suite 200<br></br>
                            Concord, NH 03301<br></br>
                            Phone: (603) 271-8675<br></br>
                            Fax: 603-271-1090</p>
                        <br></br>

                        <p><strong>NEW JERSEY</strong><br></br>
                            Residential Mortgage Broker License#: None<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Licensed by the New Jersey Department of Banking and Insurance<br></br>
                            Licensing Services Bureau, Banking<br></br>
                            20 West State Street, 8th Floor<br></br>
                            Trenton, NJ 08608<br></br>
                            Phone: (609) 292-7272<br></br>
                            Fax: 609-633-0822</p>

                        <br></br>
                        <p><strong>NEW MEXICO</strong><br></br>
                            Mortgage Loan Company License#: None<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Financial Institutions Division, Mortgage Unit<br></br>
                            2550 Cerrillos Road, 3rd Floor<br></br>
                            Santa Fe, NM 87505<br></br>
                            Phone: (505) 476-4819<br></br>
                            Fax: 505-476-4670</p>
                        <br></br>

                        <p><strong>NORTH CAROLINA</strong><br></br>
                            Mortgage Broker License#: B-201539<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Commissioner of Banks Mortgage Division<br></br>
                            316 W. Edenton Street<br></br>
                            Raleigh, NC 27603<br></br>
                            Phone: (919) 733-3016<br></br>
                            Fax: 919-733-6918</p>


                        <br></br>

                        <p><strong>NORTH DAKOTA</strong><br></br>
                            Money Broker License#: MB103370<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions Consumer Division<br></br>
                            1200 Memorial Hwy<br></br>
                            Bismarck, ND 58504<br></br>
                            Phone: (701) 328-9933<br></br>
                            Fax: 701-328-0290<br></br></p>
                        <br></br>

                        <p><strong>OHIO</strong><br></br>
                            Residential Mortgage Lending Act Certificate of Registration License#: RM.804668.000<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            The Ohio Department of Commerce<br></br>
                            Division of Financial Institutions<br></br>
                            77 South High Street<br></br>
                            21st Floor:<br></br>
                            Columbus, OH 43215-6120
                            Phone: (614) 728-8400
                            Fax: 614-728-0380</p>
                        <br></br>

                        <p><strong>OKLAHOMA</strong><br></br>
                            Mortgage Broker License#: MB012746<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Consumer Credit<br></br>
                            629 N.E. 28th Street<br></br>
                            Oklahoma City, OK 73105<br></br>
                            Phone: (405) 521-3653</p>
                        <br></br>

                        <p><strong>OREGON</strong><br></br>
                            Mortgage Lending License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Consumer and Business Services<br></br>
                            350 Winter Street, Fourth Floor<br></br>
                            Salem, OR 97301<br></br>
                            Phone: (503) 378-4100<br></br>
                            Fax: 503-378-6444</p>
                        <br></br>

                        <p><strong>PENNSYLVANIA</strong><br></br>
                            Mortgage Broker License#: 81041<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Banking and Securities<br></br>
                            Market Square Plaza<br></br>
                            17 N. Second Street, Suite 1300<br></br>
                            Harrisburg, PA 17101-2290<br></br>
                            Phone: (717) 787-3717</p>

                        <br></br>
                        <p><strong>SOUTH CAROLINA</strong><br></br>
                            Mortgage Broker License#: 891464
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Consumer Affairs<br></br>
                            293 Greystone Boulevard, Ste. 400<br></br>
                            Columbia, SC 29210<br></br>
                            Phone: (803) 734-4200</p>
                        <br></br>

                        <p><strong>SOUTH DAKOTA</strong><br></br>
                            Mortgage Brokerage License#: 891464.MB<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Banking<br></br>
                            Department of Labor and Regulation<br></br>
                            1601 N. Harrison Ave, Suite 1<br></br>
                            Pierre, SD 57501<br></br>
                            Phone: (605) 773-3421<br></br>
                            Fax: 866-326-7504</p>
                        <br></br>

                        <p><strong>UTAH – DRE</strong><br></br>
                            Mortgage Entity License#: 13389612<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Commerce<br></br>
                            Division of Real Estate<br></br>
                            Heber M. Wells Building, 2nd Floor<br></br>
                            160 East 300 South<br></br>
                            Salt Lake City, UT 84111<br></br>
                            Phone: (801) 530-6747</p>
                        <br></br>

                        <p><strong>VIRGINIA</strong><br></br>
                            Broker License#: MC-7006<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            State Corporation Commission<br></br>
                            Bureau of Financial Institutions<br></br>
                            1300 E. Main Street, Suite 800<br></br>
                            Richmond, VA 23219<br></br>
                            Phone: (804) 371-9690</p>
                        <br></br>

                        <p><strong>TENNESSEE</strong><br></br>
                            Mortgage License#: 891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions<br></br>
                            Compliance Division<br></br>
                            Tennessee Tower, 26th Floor<br></br>
                            312 Rosa L. Parks Avenue<br></br>
                            Nashville, TN 37243<br></br>
                            Phone: (615) 253-6714</p>
                        <br></br>

                        <p><strong>TEXAS – SML</strong><br></br>
                            Mortgage Company License#: NMLS # None<br></br>
                            Mortgage Company Branch License#: 1967630<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            TX Department of Savings and Mortgage Lending<br></br>
                            2601 North Lamar, Suite 201<br></br>
                            Austin, TX 78705<br></br>
                            Phone: (512) 475-1350<br></br>
                            Fax: 512-475-1360<br></br>
                            DISCLAIMERS:<br></br>
                            Consumers wishing to file a complaint against a mortgage banker or a licensed mortgage banker residential mortgage loan originator should complete and send a complaint form to the Texas Department of Savings and Mortgage Lending, 2601 North Lamar, Suite 201, Austin Texas 78705. Complaint forms and instructions may be obtained from the department’s website at www.sml.texas.gov. A toll-free consumer hotline is available at 1-877-276-5550.<br></br>
                            The department maintains a recovery fund to make payments of a certain actual out of pocket damages sustained by borrowers caused by acts of licensed mortgage banker residential mortgage loan originator. A written application for reimbursement from the recovery fund must be filed with and investigated by the department prior to the payment of a claim. For more information about the recovery fund. Please consult the department’s website at www.sml.texas.gov.</p>
                        <br></br>

                        <p><strong>UTAH – DRE</strong><br></br>
                            Mortgage Entity License#: 13389612<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Commerce<br></br>
                            Division of Real Estate<br></br>
                            Heber M. Wells Building, 2nd Floor<br></br>
                            160 East 300 South<br></br>
                            Salt Lake City, UT 84111<br></br>
                            Phone: (801) 530-6747</p>

                        <br></br>
                        <p><strong>VIRGINIA</strong><br></br>
                            Broker License#: MC-7006<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            State Corporation Commission<br></br>
                            Bureau of Financial Institutions<br></br>v
                            1300 E. Main Street, Suite 800<br></br>
                            Richmond, VA 23219<br></br>
                            Phone: (804) 371-9690</p>
                        <br></br>

                        <p><strong>WASHINGTON</strong><br></br>
                            Mortgage Broker License#: MB-891464<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions<br></br>
                            150 Israel Road SW<br></br>
                            Turnwater, WA 98501<br></br>
                            Phone: (360) 902-8703<br></br>
                            Fax: 360-586-5068</p>
                        <br></br>

                        <p><strong>WISCONSIN</strong><br></br>
                            Mortgage Broker License#: 891464BR<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Department of Financial Institutions<br></br>
                            Division of Banking<br></br>
                            4822 Madison Yards Way, North Tower<br></br>
                            Madison, WI 53705<br></br>
                            Phone: (608) 261-7578<br></br>
                            Fax: 608-267-6889</p>

                        <br></br>
                        <p><strong>WYOMING</strong><br></br>
                            Mortgage Broker License#: 4984<br></br>
                            Approved Name: Edge Home Finance Corporation, a Delaware Corporation</p>
                        <p><strong> REGULATOR:</strong><br></br>
                            Division of Banking<br></br>
                            Hathaway Building<br></br>
                            2300 Capitol Avenue, 2nd Floor<br></br>
                            Cheyenne, WY 82002<br></br>
                            Phone: (307) 777-2425<br></br>
                            Fax: 307-777-3555</p>


                    </Grid>
                </Container>
            </section>

            <div className="partner-logo-none"></div>
        </>
    );
}

export default LegalDisclaimer;
