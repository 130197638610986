import React from 'react';
import './info-bar.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/public/images/usa/logo.png';
import logo1 from '../../assets/public/images/apply-now/hands.png';
import logo2 from '../../assets/public/images/apply-now/banner-2.png';
import logo3 from '../../assets/public/images/apply-now/banner-3.png';
import logo4 from '../../assets/public/images/apply-now/banner-1.png';
import agent from '../../assets/public/images/agent-3.png';
const InfoBar = () => {
    return (
        <>
            <div className='info-bar'>
                <div className="agent-contact blue-bg">
                    <div className="agent-left">
                        <img src={logo1} />
                        <h3>You Have Questions <br></br>We have Answers! </h3>
                    </div>
                    <div className="center-img">
                        {/* <img src={logo2} />
                        <img src={logo3} />
                        <img src={logo4} /> */}
                    </div>
                    <div className="agent-right">
                        <div className="agent-contact-outer d-flex">
                            <div className='agent-img'>
                                <img src={agent} />
                                <span className='status-display'></span>
                            </div>
                            <div className='msg-info'>
                                <h5>Sarah - MLO Online</h5>
                                <p><span className='status'>Away </span><span className='average-respone-time'> Avg. respone time: 1Hour</span></p>
                            </div>
                        </div>
                        <div className='call-now'>
                            <p>Call Now for a Free Rate Quote</p>
                            <a href='tel:1800-725-9946'>888-549-1005</a>
                        </div>
                    </div>
                </div>
                <div className="terms-policy">
                    <div className='terms'>
                        <NavLink to='/legal-disclaimer'>Legal Disclaimer</NavLink>
                        <NavLink to='/privacy-policy'>Privacy</NavLink>
                    </div>
                    <div className="powered-logo">
                        <p>Powered By</p>
                        <img src={logo} />
                    </div>
                </div>
                <div className="disclaimer">
                    <p><strong>Disclaimer:</strong>As prohibited by federal law, we do not engage in business practices that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580.
<br></br>
RateShop Mortgage LLC is a licensed mortgage broker in FL, GA, and TX</p>
                </div>
            </div >
        </>
    );
}

export default InfoBar;