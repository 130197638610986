import React from 'react';
import { useFormContext } from "react-hook-form"; 
import { NavLink } from 'react-router-dom';
import mortgageImg from '../../../assets/public/images/usa/home/mortgage.png';
// assets/public/images/usa/home/mortgage.png
const QuickRadio = (props, ref) => {

  const {
    id,
    label,
    name,
    value,
    image,
    description,
    data_id = null,
    register = () => { },
    clickEventHandler = () => { }
  } = props;

  return (

    <div className="step-radio-outer">
      <input
        type="radio"
        name={name}
        value={value}
        data-id={data_id}
        id={id}
        {...register(name, { required: { value: true, message: 'This field is required.' } })}
        onClick={(e) => { clickEventHandler() }}

      />
      <label htmlFor={id} >
        {image && <img src={image} alt="Rateshop" />}
        <h4>{label}</h4>
      </label>
      {description && <span>{description}</span>}
    </div>
  );
}

const StepOne = (props) => {
 

  const { control, register, formState: { errors }, handleSubmit } = useFormContext();

  // const mortgageTypes = [

  //     {
  //         id:"mortgageType1",
  //         name:"mortgageType",
  //         label:"A Purchase",
  //         value:"purchase",
  //         description:"Pre-Approvals & New Purchases",
  //         image:purchaser, 
  //     },
  //     {
  //         id:"mortgageType2",
  //         name:"mortgageType",
  //         label:"A Refinance",
  //         value:"refinance",
  //         description:"Debt Consolidation & One Lower Payment",
  //         image:refinanceer,
  //     },
  //     {
  //         id:"mortgageType3",
  //         name:"mortgageType",
  //         label:"A Renewal/Switch",
  //         value:"renewal",
  //         description:"Same Mortgage Balance & Lower Rates",
  //         image:purchaser,
  //     },
  //     {
  //         id:"mortgageType4",
  //         name:"mortgageType",
  //         label:"A Home Equity Line",
  //         value:"heloc",
  //         description:"Access Home Equity Loans in 24 hours",
  //         image:refinanceer,
  //     }
  // ];


  return ( 
    <>
      <div className="heading">
        <h4>What are you <span>looking</span> to do? </h4>
      </div>
      <div class="step-radio-box">
        <div class="step-radio-outer">
          <NavLink to="https://purchase.rateshop.us/" target='_blank'>
            <img src={mortgageImg} alt="" />
            <h4>Home Purchase</h4>
          </NavLink><span>Pre-Approvals &amp; New Purchases</span>
        </div>
        <div class="step-radio-outer">
          <NavLink to="https://refi.rateshop.us/" target='_blank'>
            <img src={mortgageImg} alt="" />
            <h4>Home Refinance</h4>
          </NavLink><span>Debt Consolidation &amp; One Lower Payment</span>
        </div>
        <div class="step-radio-outer">
          <NavLink to="https://reverse.rateshop.us/" target='_blank'>
            <img src={mortgageImg} alt="" />
            <h4>Reverse Mortgage</h4>
          </NavLink><span>Same Mortgage Balance &amp; Lower Rates</span>
        </div>
        <div class="step-radio-outer">
          <NavLink to="https://equity.rateshop.us/" target='_blank'>
            <img src={mortgageImg} alt="" />
            <h4>Home Equity</h4>
          </NavLink><span>Access to Home Equity Loans and Home Equity Line of Credit</span>
        </div> 
      </div> 
    </>
  );
}

export default StepOne;
 

