import React, { useState } from 'react';
import './location-detail.scss';
import { Container, Grid } from "@mui/material";
import ApplyWidget from '../../../../components/shared/apply-widget/apply';
import BookAnAppointment from '../../../../components/shared/book-an-appointment/appointment';
import GoogleReviews from '../../../../components/shared/google-reviews/reviews';
import { NavLink } from 'react-router-dom';
import CalenderPopup from '../../../../components/shared/calender-popup/calender'

import { locationData } from "../../../../config/location";
import img1 from '../../../../assets/public/images/mortgage/Call-to-action-button-image.jpg';
import { useParams } from "react-router-dom";
// types of loans
import fha from '../../../../assets/public/images/usa/loan-type/FHA.png';
import va from '../../../../assets/public/images/usa/loan-type/VA.png';
import usda from '../../../../assets/public/images/usa/loan-type/USDA.png';
import conventional from '../../../../assets/public/images/usa/loan-type/Conventional.png';
import dscr from '../../../../assets/public/images/usa/loan-type/DSCR.png';
import bankStatement from '../../../../assets/public/images/usa/loan-type/Bank-Statement.png';
import Loan1099 from '../../../../assets/public/images/usa/loan-type/1099.png';
import assets from '../../../../assets/public/images/usa/loan-type/Asset-Based.png';
import noIncome from '../../../../assets/public/images/usa/loan-type/No-Income.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import plLoans from '../../../../assets/public/images/usa/loan-type/Profit-Loss.png';
import foreigNnational from '../../../../assets/public/images/usa/loan-type/Foreign-National.png';
import AngleOgbele from '../../../../assets/public/images/usa/team-member/team-member-1.png';
// mortgage
import constructionMortgage from '../../../../assets/public/images/usa/all-mortgage/Construction_mortgage.png';
import creditRepair from '../../../../assets/public/images/usa/all-mortgage/Credit_repair.png';
import HomeEquityLine from '../../../../assets/public/images/usa/all-mortgage/Home_equity_line.png';
import NewHomePurchase from '../../../../assets/public/images/usa/all-mortgage/New_Home_Purchase.png';
import PreApprovals from '../../../../assets/public/images/usa/all-mortgage/pre_approvals.png';
import Purchaseimprovement from '../../../../assets/public/images/usa/all-mortgage/Purchase_improvements.png';
import Refinance from '../../../../assets/public/images/usa/all-mortgage/Refinance.png';
import RenewalSitch from '../../../../assets/public/images/usa/all-mortgage/Renewal_Switch.png';
import SelfEmployed from '../../../../assets/public/images/usa/all-mortgage/Self_employed.png';
import VacationHomes from '../../../../assets/public/images/usa/all-mortgage/Vacation_and_2nd_homes.png';
import notfound from '../../../../assets/public/images/error.png'
import Helmet from 'react-helmet';


const LocationDetailnw = function () {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const { province, city } = useParams();
    const location = locationData.find(loc => loc.province.toLowerCase() === province.toLowerCase() && loc.city.toLowerCase() === city.toLowerCase());

    if (!location) {
        return (
            <>
            
                <section className="not-found">
                    <Container>
                        <figure>
                            <img src={notfound} alt="Not Found" />
                        </figure>
                        {/* <h1>Page Not Found</h1> */}
                        <p>Trying to get the lowest mortgage rates?</p>
                        <p> We've got your back, but looks like this page was moved. </p>
                        <p>Try our other pages below in our footer to get some amazing rates!</p>
                        {/* <p>Go to <NavLink to='/'>Home</NavLink> page</p> */}
                    </Container>
                </section>
            </> 
        )
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{location.meta_title}</title>
                <meta name='description' content={location.meta_description} />
                <meta name='keywords' content={location.meta_keywords} />
            </Helmet>
            <section className="location-banner" style={{ backgroundImage: `url(${location.bannerBg})` }}>
                <Container >
                    <Grid container className="d-flex align-items-center">
                        <Grid item xs={12} sm={12} md={6} >
                            <div className="location-inner">
                                <h1>Your Local Mortgage Loan Officer in {location.title} </h1>
                                <ul>
                                    <li>Compare upto 30 Lenders & Save thousands</li>
                                    <li>Setting a Standard in Technology & Service </li>
                                    <li>Secure your Best Deal when we shop for you </li>
                                </ul>
                                <NavLink className="rs-orange-btn large-btn" to="/apply">Start Now</NavLink>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="location-right">
                            <figure>
                                <img src={AngleOgbele} alt="" />
                            </figure>
                            <NavLink className="rs-orange-btn large-btn" onClick={handleClickOpen}> Book an Appointment</NavLink>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <ApplyWidget />

            <div className="mortage-new ne-font">


                <section className="mortgage-content-img pt-60 pb-60">
                    <Container>
                        <Grid container className="align-items-center mob-column">
                            <Grid item lg={6} md={6} xs={12} className="mob-mt-40">
                                <figure>
                                    <img className="get500" src={img1} alt="" />
                                </figure>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} >
                                <h2><span>Lowest</span> Mortgage Rates, Guaranteed!</h2>
                                <p> At RateShop Mortgage, we understand that finding the perfect mortgage solution is as crucial as finding your dream home. That's why we've dedicated ourselves to serving the vibrant community of {location.title} with unparalleled mortgage services that stand out for their quality, reliability, and customer-centric approach. </p><br></br>
                                <p>As an independent mortgage brokerage firm serving {location.title}, we take pride in helping homebuyers understand all their mortgage options to make well-informed decisions. Whether you’re purchasing your first home or refinancing an existing mortgage, you can count on our brokers to explain mortgage features and interest rates in simple terms and recommend products that align with your financial situation. </p>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                <section className="discover-best-rates w-100 pt-60 pb-60">
                    <Container>
                        <Grid container>
                            <Grid xs={12} className="tab-100" >
                                <h3><span> Why </span>  Choose RateShop Mortgage Brokerage?</h3>
                                <p><strong>Local Expertise, Nationwide Reach</strong> </p>
                                <p>With deep roots in Houston and a broad network of lenders across the country, we bring you the best of both worlds: local market knowledge and a plethora of loan options that cater to your diverse needs. </p>
                                <br></br>
                                <p><strong>Tailored Mortgage Solutions</strong> </p>
                                <p>Your financial goals are unique, and so should be your mortgage plan. Our team of experienced mortgage professionals works tirelessly to understand your situation and craft customized solutions that align with your long-term objectives. </p>
                                <br></br>
                                <p><strong>Transparent and Competitive Rates</strong> </p>
                                <p>We believe in transparency and honesty. With RateShop Mortgage Brokerage, there are no hidden fees or surprises. We provide clear, upfront information about the most competitive rates available to you. </p>
                                <br></br>
                                <p><strong>Streamlined Process</strong> </p>
                                <p>From application to closing, our process is designed to be smooth and efficient. We value your time and strive to make your mortgage experience as seamless and stress-free as possible. </p>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                {/* type of loan start */}
                <section className="types-of-loans all-mortgage">
                    <Container>
                        <div class="heading text-center"><h2><span>All Mortgages, </span> All situations, regardless of Income or Credit <br></br>The Best Rate, Everytime!</h2></div>
                        <Grid container className="justify-content-center">
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={constructionMortgage} alt=""></img>
                                </figure>
                                <NavLink to="">Construction Mortgage</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={creditRepair} alt=""></img>
                                </figure>
                                <NavLink to="">Credit Repair</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={HomeEquityLine} alt=""></img>
                                </figure>
                                <NavLink to=""> Home Equity Line </NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={NewHomePurchase} alt=""></img>
                                </figure>
                                <NavLink to="">New Home Purchase</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={PreApprovals} alt=""></img>
                                </figure>
                                <NavLink to="">Pre Approvals</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={Purchaseimprovement} alt=""></img>
                                </figure>
                                <NavLink to="">Purchase Improvements</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={Refinance} alt=""></img>
                                </figure>
                                <NavLink to="">Refinance</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={RenewalSitch} alt=""></img>
                                </figure>
                                <NavLink to="">Renewal Switch</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={SelfEmployed} alt=""></img>
                                </figure>
                                <NavLink to="">Self Employed</NavLink>
                            </Grid>
                            <Grid sm={6} md={4} lg={2} item>
                                <figure>
                                    <img src={VacationHomes} alt=""></img>
                                </figure>
                                <NavLink to="">Vacation and 2nd homes</NavLink>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                {/* type of loan start */}
                <section className="types-of-loans">
                    <Container>
                        <div class="heading text-center"><h2><span>Our</span> Services</h2></div>
                        <div class="sub-heading text-center"><h2>Traditional Mortgage Loans</h2></div>
                        <Grid container className="justify-content-center row-gap-30">
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={fha} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/fha-loans">FHA</NavLink>
                                    <p>Whether you're a first-time homebuyer or looking to refinance, an FHA loan provides a pathway to homeownership. Explore the possibilities and take the first step towards owning your own home. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={va} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/va-loans">VA</NavLink>
                                    <p>Take advantage of this unique opportunity to secure your dream home and enjoy the financial advantages that come with a VA loan. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={usda} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/usda"> USDA </NavLink>
                                    <p>Discover the possibilities and take advantage of this government-backed loan program to achieve your dream of homeownership in eligible rural communities. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={conventional} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/conventional">CONVENTIONAL</NavLink>
                                    <p>With various down payment options and loan terms available, you can tailor your mortgage to fit your specific needs. Explore the advantages of a conventional loan and take the first step towards owning your dream home. </p>
                                </div>
                            </Grid>

                        </Grid>
                        <div class="sub-heading text-center mt-50"><h2>Non-QM Loans</h2></div>
                        <Grid container className="justify-content-center row-gap-30">
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={dscr} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/dscr">DSCR  Loans</NavLink>
                                    <p>With a focus on the property's ability to generate sufficient income to cover debt payments, DSCR loans offer flexibility and competitive terms. Explore this specialized loan option and secure the financing you need for your commercial property investment. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={bankStatement} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/bank-statement-loans">Bank Statement Loans</NavLink>
                                    <p>With flexible qualification criteria and competitive rates, Bank Statement loans provide an alternative solution for borrowers who may not meet conventional lending requirements. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={Loan1099} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/1099-loans">1099 Loans</NavLink>
                                    <p>With flexible qualification criteria and tailored terms, 1099 loans provide an opportunity for those with non-traditional income sources to secure the financing they need. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={plLoans} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/p-l-loans">P & L Loans</NavLink>
                                    <p>Whether you need funds for expansion, equipment purchase, or working capital, explore the benefits of a P&L loan and fuel the growth of your business. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={assets} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/asset-utilization">Asset Utilization</NavLink>
                                    <p>Explore the benefits of Asset Utilization and discover how it can help your business achieve optimal performance and success. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={noIncome} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/no-income-loan">No Income Loan</NavLink>
                                    <p>Whether you're self-employed, have irregular income, or rely on non-traditional sources, a No Income loan provides flexibility and access to funds. </p>
                                </div>
                            </Grid>
                            <Grid sm={6} md={4} lg={3} className="d-flex" item>
                                <div class="loans-wrap">
                                    <figure>
                                        <img src={foreigNnational} alt=""></img>
                                    </figure>
                                    <NavLink to="/mortgage-program/foreign-national">Foreign National</NavLink>
                                    <p>With flexible qualification criteria and specialized terms, Foreign National loans cater to the unique needs and circumstances of international buyers. </p>
                                </div>
                            </Grid>
                        </Grid><br></br>
                        <p className="text-center">Our commitment to transparency and putting your needs first sets us apart. We are not tied to any one lender and can provide unbiased guidance. Our mortgage advisors take the time to educate you, answer all your questions, and search for options well-suited to your budget and goals.  </p>
                    </Container>
                </section>

                <section className="mortgage-content-img pt-60">
                    <Container>
                        <Grid container className="align-items-center mob-column">
                            <Grid item lg={6} md={6} xs={12} >
                                <h2><span>Get </span> Started Today! </h2>
                                <p>Your journey to the perfect mortgage starts here. Contact us at RateShop Mortgage to schedule a consultation with one of our knowledgeable mortgage advisors. Let us help you unlock the door to your new home in {location.title}. </p><br></br>
                                <p>RateShop Mortgage Brokerage – Where Your Home Financing Needs Come First. </p>
                                    <br></br>
                                <NavLink className="rs-orange-btn large-btn" to="/apply">Apply Now</NavLink>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className="mob-mt-40">
                                <figure>
                                    <img className="get500" src={img1} alt=" " />
                                </figure>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                <BookAnAppointment />

                <GoogleReviews />

                <CalenderPopup
                open={open}
                handleClose={handleClose}
            />
            </div>
        </>
    )
}

export default LocationDetailnw;