import React, { useState } from 'react';
import './mortgage-program.scss';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import { Container, Grid } from "@mui/material";
import { useParams as params } from "react-router-dom";
import Button from '@mui/material/Button';
import { Markup } from 'react-render-markup';
import { mortgagePrograms } from "../../../../config/mortgage";
import ApplyWidget from '../../../../components/shared/apply-widget/apply';
import BookAnAppointment from '../../../../components/shared/book-an-appointment/appointment';
import GoogleReviews from '../../../../components/shared/google-reviews/reviews';
import CalenderPopup from '../../../../components/shared/calender-popup/calender'
import notfound from '../../../../assets/public/images/error.png'
// Images
import agent from '../../../../assets/public/images/agent-3.png';
import Banner from '../../../../assets/public/images/usa/banner-2.png';
import Service from '../../../../assets/public/images/usa/service.png';
import Commitment from '../../../../assets/public/images/usa/commitment.png';
// Team Member
import ArlanZuberi from '../../../../assets/public/images/usa/team-member/7.png';
import DarnellSmith from '../../../../assets/public/images/usa/team-member/1.png';
import NatashaStaplowski from '../../../../assets/public/images/usa/team-member/5.png';
import AngleOgbele from '../../../../assets/public/images/usa/team-member/3.png';
import Helmet from 'react-helmet';

const MortgageProgram = function () {

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { loc_slug } = params();
    const morFiltered = mortgagePrograms.find(loc => String(loc.slug) === loc_slug);

    var options = {
        className: "owl-theme text-carousel",
        margin: 20,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            }
        }
    }
    // Check if morFiltered is undefined
    if (!morFiltered) {
        return (
            <>
                <section className="not-found">
                    <Container>
                        <figure>
                            <img src={notfound} alt="Not Found" />
                        </figure>
                        {/* <h1>Page Not Found</h1> */}
                        <p>Trying to get the lowest mortgage rates?</p>
                        <p> We've got your back, but looks like this page was moved. </p>
                        <p>Try our other pages below in our footer to get some amazing rates!</p>
                        {/* <p>Go to <NavLink to='/'>Home</NavLink> page</p> */}
                    </Container>
                </section>
            </> 
        )
    }

    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>{morFiltered.meta_title}</title>
                <meta name='description' content={morFiltered.meta_description} />
                <meta name='keywords' content={morFiltered.meta_keywords} />
            </Helmet>
            <section className="main-banner ">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid sm={12} md={6} className="tab-100" item >
                            <h1>{morFiltered.title}</h1>
                            <Markup markup={morFiltered.bannerLine} />
                            <div className="bottom-details">
                                <Markup markup={morFiltered.bannerText} />
                                <div className="call-btn">
                                    <a href="tel:888-549-1005"><CallIcon />889-549-1005</a>
                                    <div className="agent-contact-outer">
                                        <div className="agent-img">
                                            <img src={agent} alt="" />
                                            <span className="status-display"></span>
                                        </div>
                                        <div className="msg-info">
                                            <h5>Sarah - MLO Online</h5>
                                            <p><span className="status">Away </span><span className="average-respone-time"> Avg. respone time: 1Hour</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-text">
                                    <h3>Call for a Free Mortgage Saving Report</h3>
                                    <p>No Credits Checks Required</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid sm={12} md={6} className="tab-100" item>
                            <div className="right-content">
                                <figure>
                                    <img src={Banner} alt="" />
                                </figure>
                                <Button variant="contained" color="primary"   onClick={handleClickOpen}>
                                    Speak with a Broker
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <ApplyWidget />

            <section className="content-img  service-commitment">
                <Container>
                    <div className="heading text-center"><h2><span>Our </span> Service Commitment</h2>
                    </div>
                    <Grid container className="align-items-center ">
                        <Grid sm={12} md={6} className="tab-100" item >
                            <figure>
                                <img src={Commitment} alt="" />
                            </figure>
                        </Grid>
                        <Grid sm={12} md={6} className="tab-100" item   >
                            <ul>
                                <li><strong>No Hidden Fees</strong>: We believe in clear and upfront communication about all costs associated with your mortgage. </li>
                                <li><strong>Tailored Solutions</strong>: Our advisors work closely with you to find mortgage solutions that are customized to your financial situation. </li>
                                <li><strong>Tailored Solutions</strong>: Our advisors work closely with you to find mortgage solutions that are customized to your financial situation.</li>
                            </ul>
                            <p>Get Expert Advise, everytime from a licensed loan officer with a suite of products to choose from. </p>
                            <p>Our Team strives to deliver excellence, reach us day or night about any of your mortgage questions, we’re here for you when you need. </p>
                            <p>Working with us or just thinking of it, we will always be honest and transparent. No sales targets means way better service! </p>
                        </Grid>
                    </Grid>
                </Container>
                <Container className="mt-70">
                    <Grid container className="align-items-center">
                        <Grid sm={12} md={6} className="tab-100" item   >
                            <h2>Available When & Where You Want! </h2>
                            <p>Across multiple states our Licensed Loan officers will help you from the application to the funding and beyond. </p>
                            <p>We work on your schedule, with streamlined technology and support staffing, your mortgage request will always be a priority! </p>
                        </Grid>
                        <Grid sm={12} md={6} className="tab-100" item>
                            <figure>
                                <img src={Service} alt="" />
                            </figure>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <BookAnAppointment />

            {/* Reviews start here */}
            <GoogleReviews />

            <section className="team-members">
                <Container>
                    <div className="heading text-center mb-50"><h2><span>Meet</span> Our Team</h2>
                    </div>
                    <Grid container className="team-member-container">
                        <Grid sm={6} md={3} lg={3} item   >
                            <div className="team-member-wrap">
                                <figure>
                                    <img src={AngleOgbele} alt=""></img>
                                </figure>
                                <h2>Angle Ogbele</h2>
                                <NavLink to="/apply" className="cs-btn MuiButton-contained MuiButton-containedPrimary">Pre-Qualify Now</NavLink>
                                <Button variant="contained" color="primary" onClick={handleClickOpen} >
                                    Speak with a Broker
                                </Button>
                            </div>
                        </Grid>
                        <Grid sm={6} md={3} lg={3} item   >
                            <div className="team-member-wrap">
                                <figure>
                                    <img src={NatashaStaplowski} alt=""></img>
                                </figure>
                                <h2>Natasha Staplowski</h2>
                                <NavLink to="/apply" className="cs-btn MuiButton-contained MuiButton-containedPrimary">Pre-Qualify Now</NavLink>
                                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                                    Speak with a Broker
                                </Button>
                            </div>
                        </Grid>

                        <Grid sm={6} md={3} lg={3} item   >
                            <div className="team-member-wrap">
                                <figure>
                                    <img src={DarnellSmith} alt=""></img>
                                </figure>
                                <h2>Darnell Smith</h2>
                                <NavLink to="/apply" className="cs-btn MuiButton-contained MuiButton-containedPrimary">Pre-Qualify Now</NavLink>
                                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                                    Speak with a Broker
                                </Button>
                            </div>
                        </Grid>

                        <Grid sm={6} md={3} lg={3} item   >
                            <div className="team-member-wrap">
                                <figure>
                                    <img src={ArlanZuberi} alt=""></img>
                                </figure>
                                <h2>Arlan Zuberi</h2>
                                <NavLink to="/apply" className="cs-btn MuiButton-contained MuiButton-containedPrimary">Pre-Qualify Now</NavLink>
                                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                                    Speak with a Broker
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className="content-img pt-70 what-is-sec">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid sm={12} item>
                            <h2 className="text-center">What is {morFiltered.title}</h2>
                            <Markup markup={morFiltered.what_it_is} />
                        </Grid>
                    </Grid>
                </Container>
            </section>


            <section className="content-img how-does-work-sec">
                <Container>
                    <Grid container className="row-reverse align-items-start">
                        <Grid sm={12} item>
                            <h2 className="text-center">How Does a {morFiltered.title} Works ?</h2>
                            <OwlCarousel
                                {...options}
                            >
                                <Markup markup={morFiltered.how_does_it_works_carousel} />
                            </OwlCarousel>
                            <Markup markup={morFiltered.how_does_it_works_carousel_bottom} />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className="content-img benefits-sec">
                <Container>
                    <Grid container className="row-reverse align-items-start">
                        <Grid sm={12} item   >
                            <h2 className="text-center">Benefits of {morFiltered.title}</h2>
                            <div className="benefits-content">
                                <Markup markup={morFiltered.features} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <div className="benefits-img cover-bg">

                <div className="overlay-text">
                    <h2>Take the next step towards homeownership</h2>
                    <ul>
                        <li>Competitive interest rates</li>
                        <li>Various down payment options</li>
                        <li>Flexible terms to suit your needs</li>
                    </ul>
                </div>
            </div>

            <section className="content-img features-sec">
                <Container>
                    <Grid container className="align-items-start">
                        <Grid sm={12} item>
                            <h2 className="text-center">Features of {morFiltered.title}</h2>
                            <div className="features-content">
                                <Markup markup={morFiltered.benefits} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <div className="features-img cover-bg">
                <div className="overlay-text">
                    <h2>Make your dream of owning a home a reality</h2>
                    <ul>
                        <li>Low down payment options</li>
                        <li>Flexible credit requirements</li>
                        <li>Competitive interest rates</li>
                    </ul>
                </div>
            </div>

            <CalenderPopup
                open={open}
                handleClose={handleClose}
            />
            <div className="partner-logo-none"></div> 
            
        </>
    );
}

export default MortgageProgram;
