import React from 'react';
import "./apply.scss";
import { NavLink } from "react-router-dom";
import { Container } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from 'swiper';
import mortgageImg from '../../../assets/public/images/usa/home/mortgage.png';

const ApplyWidget = () => {
  return (
    <>
      <section className="widget-sec content-img apply-widget-nw">
        <Container>
          <div class="quick-com-parent">
            <div class="quick-container">
              <div class="q-i-wrap">
                <div class="quick-inner no-border">
                  <div class="MuiGrid-root MuiGrid-container css-1d3bbye">
                    <div class="text-center top-heading-cs heading ">
                      <h2><span>Start with</span> our Mortgage Approval Tool!</h2>
                      <p>Access to 65+ Lenders, Dedicated Customer Service</p> 
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        className="featuresSwiper"
                      >
                        <SwiperSlide>Shop several lenders with just 1 Credit Check</SwiperSlide>
                        <SwiperSlide>Nationwide Mortgage Approvals</SwiperSlide>
                        <SwiperSlide>No Rate Gimmicks, No Hidden Fees</SwiperSlide>
                      </Swiper> 
                    </div>
                    <div class="quick-form-inner">
                      <div class="step-com-main steps1 steps-animate">
                        <div class="step-radio-box">
                          <div class="step-radio-outer">
                              <NavLink to="https://purchase.rateshop.us/" target="_blank">
                              <img src={mortgageImg} alt=">Website Purchase Survey" />
                              <h4>Home Purchase</h4>
                            </NavLink><span>Pre-Approvals &amp; New Purchases</span>
                          </div>
                          <div class="step-radio-outer">
                              <NavLink to="https://refi.rateshop.us/" target="_blank">
                              <img src={mortgageImg} alt="Website Refi survey" />
                              <h4>Home Refinance</h4>
                            </NavLink><span>Debt Consolidation &amp; One Lower Payment</span>
                          </div>
                          <div class="step-radio-outer">
                              <NavLink to="https://reverse.rateshop.us/" target="_blank">
                              <img src={mortgageImg} alt=" Reverse Mortgage Survey" />
                              <h4>Reverse Mortgage</h4>
                            </NavLink><span>Same Mortgage Balance &amp; Lower Rates</span>
                          </div>
                          <div class="step-radio-outer">
                              <NavLink to="https://equity.rateshop.us/" target="_blank">
                              <img src={mortgageImg} alt="VA Loan Survey" />
                              <h4>Home Equity</h4>
                            </NavLink><span>Access to Home Equity Loans and Home Equity Line of Credit</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section> 
    </>
  );
}

export default ApplyWidget;