import React, { useEffect } from "react";
import './locations.scss';
import { Container, Grid } from "@mui/material";
import Blog from "../../../../components/shared/blog/Blog";
import AOS from 'aos';
import "aos/dist/aos.css";
import headOffice from "../../../../assets/public/images/pin.png";
import LinkToDetail from "../../../../components/shared/link-to-detail/LinkToDetail";
import { Link } from "react-router-dom";
// import Blog from "../../../../components/shared/blog/Blog";
import Helmet from 'react-helmet';

const Locations = function () {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact RateShop Mortgage | Texas, Florida, and Georgia</title>
          <meta name='description' content='Get in touch with RateShop Mortgage for all your mortgage needs in Texas, Florida, and Georgia. Contact our customer service team for support and inquiries. Apply online or call us today.' />
        </Helmet>
            <section className="page-heading">
                <Container>
                    <h1>Contact Us to Get Lowest Mortgage Rate in Canada</h1>
                </Container>
            </section>

            <section className="links-to-detail sec-padding">
                <Container>
                    <Grid container>
                        <Grid item sm={6}>
                            <Link to={"/contact/head-office"}>
                                <LinkToDetail image={headOffice} heading="Head Office" subHeading="6 Indell Ln, Brampton, ON L6T 3Y3, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/customer-service"}>
                                <LinkToDetail image={headOffice} heading="Customer Service" subHeading="1228 Main St E, Milton, ON L9T, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/british-columbia-office"}>
                                <LinkToDetail image={headOffice} heading="British Columbia Office" subHeading="4529 MELROSE STREET, PORT ALBERN BC V9Y 1K7 CANADA" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/alberta-office"}>
                                <LinkToDetail image={headOffice} heading="Alberta Office" subHeading="10123 99 St NW #1200, Edmonton, AB T5J 3H1, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/saskatchewan-office"}>
                                <LinkToDetail image={headOffice} heading="Saskatchewan Office" subHeading="855 Arcola Ave suite 300, Regina, SK S4N 0S9, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/winnepeg-office"}>
                                <LinkToDetail image={headOffice} heading="Winnepeg Office" subHeading="225 Vaughan St #305, Winnipeg, MB R3C 0E7, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/new-brunswick-office"}>
                                <LinkToDetail image={headOffice} heading="New Brunswick Office" subHeading="7 Emerald St, Fredericton, NB E3G 7V3, Canada" />
                            </Link>
                        </Grid>
                        <Grid item sm={6}>
                            <Link to={"/contact/prince-edward-islands-office"}>
                                <LinkToDetail image={headOffice} heading="Prince Edward Islands Office" subHeading="7 Babineau Avenue, Charlottetown, PE C1A 0C9, Canada" />
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            {/* <section className="locations-map">
                <iframe
                    src="https://www.google.com/maps/d/embed?mid=1NPr2wduC_25VHdreGwPAe_ZNhPZwGEQ&ehbc=2E312F"
                    width="100%"
                    height="480"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className="reach-us">
                    <Container>
                        <p className="text-small">You can reach the privacy, complaints and compliance officer at <a href="mailto:info@rateshop.ca">info@rateshop.ca</a> <br />or<br /> call us at <a href="tel:1800-725-9946">1800-725-9946</a></p>
                        <p>RateShop Inc. is a Mortgage Brokerage offering lowest mortgage rates to Canadians. We are provicially licensed in the following provinces: Mortgage Brokerage Ontario FSRA #12733, British Columbia BCFSA #MB600776, Alberta RECA #00523056P, Saskatchewan FCAA #00511126, PEI #160622, Manitoba FIRB #10139499, New Brunswick FCNB #88426, Newfoundland/Labrador, Yukon, Nunavut, Northwest Territories. Our Quebec Mortgage Transactions are serviced by Orbis Mortgage Group AMF# 181136.</p>
                    </Container>
                </div>
            </section> */}
            {/* <Blog /> */}
            {/* <Blog /> */}
        </>
    )
}

export default Locations;