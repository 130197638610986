import React, { useState } from "react";
import {  Menu  } from "@mui/material";
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack'; 
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

import { menuItems } from "./menuitems";
import MenuItems from "./MenuItemss";


export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const cookies = new Cookies();
  const [baseUrl, setBaseUrl] = React.useState('/');
  const [showClass, setShowClass] = React.useState('');
  React.useEffect(() => {
    if(cookies.get('cookieAgentSlug') != undefined && cookies.get('agentSite')){
        setBaseUrl('/'+cookies.get('cookieAgentSlug')+'/');
    }

  }, [showClass]);
  
  // const open = true;

  

  const anchorRef = React.useRef(null);

  const handleToggle = () => {

    setShowClass((prevClass) => ((prevClass == '') ? 'show' : ''));

  };

  // dropdown
  const [openCalc, setOpenCalc] = React.useState(false);
  const [openCalc1, setOpenCalc1] = React.useState(false);
  const anchorRefCalc = React.useRef(null);
  const anchorRefCalc1 = React.useRef(null);

  const handleToggleCalc = () => {
    setOpenCalc((prevOpenCalc) => !prevOpenCalc);
  };
  const handleToggleCalc1 = () => {
    setOpenCalc1((prevOpenCalc1) => !prevOpenCalc1);
  };
  const handleCloseCalc1 = (event) => {
    if (anchorRefCalc1.current && anchorRefCalc1.current.contains(event.target)) {
      return;
    }

    setOpenCalc1(false);
  };

  const handleCloseCalc = (event) => {
    if (anchorRefCalc.current && anchorRefCalc.current.contains(event.target)) {
      return;
    }

    setOpenCalc(false);
  };

  function handleListKeyDownCalc(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCalc(false);
    } else if (event.key === 'Escape') {
      setOpenCalc(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenCalc = React.useRef(openCalc);
  React.useEffect(() => {
    if (prevOpenCalc.current === true && openCalc === false) {
      anchorRefCalc.current.focus();
    }

    prevOpenCalc.current = openCalc;
  }, [openCalc]);
  // dropdown
  // 
 
  return (
    <div className='top-nav'>
      
      <Paper className={`desktop-main ${showClass}`}>
       
      <ul className="desktop-menu menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
      <NavLink to="https://rateshop.my1003app.com?time=1708732600845" className="apply-now" onClick={handleToggle}>Apply Now</NavLink>
      <NavLink to="/join-us" className="apply-now">Careers</NavLink>
 
      </Paper>
      <div className='mobile-menu'>
        <div className={`mobile-overlay ${showClass}`}></div>
        <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} className={`mobile-toggle-btn ${showClass}`}>
          <MenuIcon className="mobile-toggle" />
          <CloseIcon className="mobile-toggle toggle-close" />
        </Button> 
      </div>
    </div>
  );
}
