import React, { useState, useEffect } from "react"
import {Link} from 'react-router-dom';
import logo from '../../../assets/public/images/usa/logo.png';
import logo2 from '../../../assets/public/images/webp/rateshop-logo2.webp';


const Logo = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
      });
    }, []);
    return (

        <Link to="/" className={scroll ? "fixed-head" : ""}>
           <img src={logo} alt="logo" />
           <img src={logo2} alt="logo" className='fixed-logo' />
        </Link>
    )
}

export default Logo