import React, { useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import AOS from 'aos';
import "aos/dist/aos.css";
import { 
    location,
    call, 
    email,
    facebook,
    linkedin,
    twitter,
    rss,
}
    from "../../../../core/Images";
import './contact.scss'
import { NavLink } from "react-router-dom";
import Helmet from 'react-helmet';
const Contact = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact RateShop Mortgage | Texas, Florida, and Georgia</title>
            <meta name='description' content='Get in touch with RateShop Mortgage for all your mortgage needs in Texas, Florida, and Georgia. Contact our customer service team for support and inquiries. Apply online or call us today.' />
            <meta name='keywords' content='contact RateShop Mortgage Texas, RateShop Mortgage customer service Texas, RateShop Mortgage support Texas, contact mortgage broker Texas, RateShop Mortgage office Texas, contact RateShop Mortgage Georgia, RateShop Mortgage customer service Georgia, RateShop Mortgage support Georgia, contact mortgage broker Georgia, RateShop Mortgage office Georgia, contact RateShop Mortgage Florida, RateShop Mortgage customer service Florida, RateShop Mortgage support Florida, contact mortgage broker Florida, RateShop Mortgage office Florida, RateShop Mortgage contact information Texas, Georgia, Florida, RateShop Mortgage phone number Texas, Georgia, Florida, RateShop Mortgage email Texas, Georgia, Florida' />
        </Helmet>
        {/* contact banner section start here */}
        <section className='contact-main'>
            <div className='contact-inner'>
                <div className='contact-detail'>
                    <Container>
                        <Grid container>
                            <Grid xs={12} sm={6} md={6} lg={6} item className="cs-p0 mob-100">
                                <div className="detail-inner">
                                    <div className='heading'>
                                        <h3><span>Contact</span> Information</h3>
                                        <p><small>Thank you for considering RateShop Mortgage for your mortgage needs. We are dedicated to providing exceptional service and helping you find the best mortgage options tailored to your unique financial goals. If you have any questions, inquiries, or would like to discuss your mortgage requirements, we are here to assist you. </small></p>
                                        <p>Licence # - RateShop Mortgage LLC, 1862092</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={call} alt="call" />
                                            </div>
                                            <NavLink to="">
                                                888-549-1005
                                            </NavLink>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={email} alt="email" />
                                            </div>
                                            <NavLink to="">
                                                hmendez@rateshop.us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={location} alt="Loaction" />
                                            </div>
                                            <p>457 Laurence Dr Suite 463 Heath TX 75032</p>
                                        </li>
                                        {/* <li>
                                            <NavLink to="">
                                                <div className="contact-icon">
                                                    <img src={career} alt="career" />
                                                </div>
                                                Careers
                                            </NavLink>
                                        </li> */}
                                    </ul>
                                    {/* <div className='contact-button'>
                                        <Button variant="contained" color="primary">Book Appointment</Button>
                                        <Button variant="contained" color="primary">About RateShop</Button>
                                    </div> */}
                                </div>
                            </Grid>
                            <Grid lg={6} item className="cs-p0"></Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="conatact-map">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.1766038129176!2d-96.4711192!3d32.8405754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864eab94c6e70487%3A0x970eb78340286e1a!2s457%20Laurence%20Dr%20%23463%2C%20Heath%2C%20TX%2075032%2C%20USA!5e0!3m2!1sen!2sin!4v1707754412613!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <img src={contactmap} alt="contact map" /> */}
                </div>
            </div>
        </section>
        {/* contact banner section end here */}
        {/* contact alternate section start here */}
        <section className="contact-alternate d-none">
            <Container>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} item className="cs-p0">
                        <div className="alternate-inner">
                            <div className="heading">
                                <h3>Alternate way to Connect with Us</h3>
                            </div>
                            <p>We definitely love supporting our customers through social media. Drop us a comment through your preferred social network...</p>
                            <ul className="contact-social">
                                <li>
                                    <NavLink to="">
                                        <img src={facebook} alt="facebook" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="">
                                        <img src={twitter} alt="twitter" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="">
                                        <img src={linkedin} alt="linkedin" />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="">
                                        <img src={rss} alt="rss" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    </>)
}

export default Contact