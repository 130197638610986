import {
  agent1, agent2, agent3, agent4, agent5, agent6, agent7, csLogo1,
  csLogo2,
  csLogo3,
  csLogo4,
  csLogo5,
  csLogo6,
  csLogo7,
  csLogo8,
  csLogo9,
  csLogo10,
  csLogo12,
  csLogo13,
  csLogo14,
  csLogo15,
  csLogo16,
  csLogo17,
  csLogo18,
  csLogo19,
}
  from "../../core/Images";

 

import AAA_Lendings from '../../assets/public/images/usa/partner-logo/AAA Lendings.png';
import AFR from '../../assets/public/images/usa/partner-logo/AFR.png';
import Allied_Mortgage_Group from '../../assets/public/images/usa/partner-logo/Allied Mortgage Group.png';
import AmeriSave_Mortgage from '../../assets/public/images/usa/partner-logo/AmeriSave Mortgage.png'; 
import Change_Wholesale from '../../assets/public/images/usa/partner-logo/Change Wholesale.png';
import EPM from '../../assets/public/images/usa/partner-logo/EPM.png';
import Excelarate from '../../assets/public/images/usa/partner-logo/Excelarate.png';
import FGMC from '../../assets/public/images/usa/partner-logo/FGMC.png';
import Flagstar from '../../assets/public/images/usa/partner-logo/Flagstar.png';
import Freedom_Mortgage from '../../assets/public/images/usa/partner-logo/Freedom Mortgage.png';
import Kind_Lending from '../../assets/public/images/usa/partner-logo/Kind Lending.png';
import Lending_Pros from '../../assets/public/images/usa/partner-logo/Lending Pros.png';
import Loanwyse from '../../assets/public/images/usa/partner-logo/Loanwyse.png';
import Majestic_Home_Loan from '../../assets/public/images/usa/partner-logo/Majestic Home Loan.png';
import Nations_Direct_Mortgage from '../../assets/public/images/usa/partner-logo/Nations Direct Mortgage.png';
import NewFi_Lending from '../../assets/public/images/usa/partner-logo/NewFi Lending.png';
import Newrez from '../../assets/public/images/usa/partner-logo/Newrez.png';
import Pennymac from '../../assets/public/images/usa/partner-logo/Pennymac.png';
import REMN from '../../assets/public/images/usa/partner-logo/REMN.png';
import Solve_Mortgage from '../../assets/public/images/usa/partner-logo/Solve Mortgage.png';
import UWM from '../../assets/public/images/usa/partner-logo/UWM.png'; 


import lender1  from '../../assets/public/images/lenders/webp/lenders-1.webp';
import lender2  from '../../assets/public/images/lenders/webp/lenders-2.webp';
import lender3  from '../../assets/public/images/lenders/webp/lenders-3.webp';
import lender4  from '../../assets/public/images/lenders/webp/lenders-4.webp';
import lender5  from '../../assets/public/images/lenders/webp/lenders-5.webp';
import lender6  from '../../assets/public/images/lenders/webp/lenders-6.webp';
import lender7  from '../../assets/public/images/lenders/webp/lenders-7.webp';
import lender8  from '../../assets/public/images/lenders/webp/lenders-8.webp';
import lender9  from '../../assets/public/images/lenders/webp/lenders-9.webp';
import lender10  from '../../assets/public/images/lenders/webp/lenders-10.webp';
import lender11 from '../../assets/public/images/lenders/webp/lenders-11.webp';
import lender12  from '../../assets/public/images/lenders/webp/lenders-12.webp';
import lender13  from '../../assets/public/images/lenders/webp/lenders-13.webp';
import lender14  from '../../assets/public/images/lenders/webp/lenders-14.webp';
import lender15  from '../../assets/public/images/lenders/webp/lenders-15.webp';
import lender16  from '../../assets/public/images/lenders/webp/lenders-16.webp';
import lender17  from '../../assets/public/images/lenders/webp/lenders-17.webp';
import lender18  from '../../assets/public/images/lenders/webp/lenders-18.webp';
import lender19  from '../../assets/public/images/lenders/webp/lenders-19.webp';
import lender20 from '../../assets/public/images/lenders/webp/lenders-20.webp';
import lender21  from '../../assets/public/images/lenders/webp/lenders-21.webp';
import lender22  from '../../assets/public/images/lenders/webp/lenders-22.webp';
import lender23  from '../../assets/public/images/lenders/webp/lenders-23.webp';
import lender24  from '../../assets/public/images/lenders/webp/lenders-24.webp';
import lender25  from '../../assets/public/images/lenders/webp/lenders-25.webp';
import lender26  from '../../assets/public/images/lenders/webp/lenders-26.webp';
import lender27  from '../../assets/public/images/lenders/webp/lenders-27.webp';
import lender28  from '../../assets/public/images/lenders/webp/lenders-28.webp';
import lender29  from '../../assets/public/images/lenders/webp/lenders-29.webp';
import lender30  from '../../assets/public/images/lenders/webp/lenders-30.webp'; 


import rateshopCa from '../../assets/public/images/join-us/partner/rateshop-ca.png';
import rateshopUs from '../../assets/public/images/join-us/partner/rateshop-us.png';
import lendmax from '../../assets/public/images/join-us/partner/lendmax.png';
import lmc from '../../assets/public/images/join-us/partner/lmc.png';
import mbanc from '../../assets/public/images/join-us/partner/mbanc.png';
import instasure from '../../assets/public/images/join-us/partner/instasure.png';
import veevo from '../../assets/public/images/join-us/partner/veevo.png';
import orbis from '../../assets/public/images/join-us/partner/orbis.png';
import homes from '../../assets/public/images/join-us/partner/homes.png';
import nanoqode from '../../assets/public/images/join-us/partner/nanoqode.png';
import taxease from '../../assets/public/images/join-us/partner/taxease.png';


export const agentListing = [
  {
    id: 1,
    image: agent1,
    name: "Michael Squeo",
    designation: "Mortgage Broker",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 2,
    image: agent2,
    name: "Jenivia Selvarajah",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 3,
    image: agent3,
    name: "Teresa Berardi",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 4,
    image: agent4,
    name: "Rania Warde",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 5,
    image: agent5,
    name: "Gabrijela Mihajlovic",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 6,
    image: agent6,
    name: "Winston Smith",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 7,
    image: agent7,
    name: "Manpreet Bhinder",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
  {
    id: 8,
    image: agent7,
    name: "Joan Delaney",
    designation: "Agent",
    phone1: "(416) 827-2626",
    phone2: "6476575455",
    email: "test@yopmail.com",
    twitter: "www.twitter.com",
    facebook: "www.facebook.com",
    instagram: "www.instagram.com",
  },
];

 
export const partnerLogos = [
  {
    id: 1,
    img: AAA_Lendings,
  },
  {
    id: 2,
    img: AFR,
  },
  {
    id: 3,
    img: Allied_Mortgage_Group,
  },
  {
    id: 4,
    img: AmeriSave_Mortgage,
  }, 
  {
    id: 6,
    img: Change_Wholesale 
    ,
  },
  {
    id: 7,
    img: EPM,
  },
  {
    id: 8,
    img: Excelarate,
  },
  {
    id: 9,
    img: FGMC,
  },
  {
    id: 10,
    img: Flagstar,
  },
  {
    id: 11,
    img: Freedom_Mortgage,
  },
  {
    id: 12,
    img: Kind_Lending,
  },
  {
    id: 13,
    img: Lending_Pros,
  },
  {
    id: 14,
    img: Loanwyse,
  },
  {
    id: 15,
    img: Majestic_Home_Loan ,
  },
  {
    id: 16,
    img: 
    Nations_Direct_Mortgage ,
  },
  {
    id: 17,
    img:     NewFi_Lending ,
  },
  {
    id: 18,
    img:     Newrez ,
  },
  {
    id: 19,
    img:     Pennymac ,
  },
  {
    id: 20,
    img:     REMN ,
  },
  {
    id: 21,
    img:     Solve_Mortgage,
  },
  {
    id: 22,
    img:      UWM ,
  } 
]

export const partnerLogos2 = [
  {
    id: 1,
    img: rateshopCa,
  },
  {
    id: 2,
    img: rateshopUs,
  },
  {
    id: 3,
    img: lendmax,
  },
  {
    id: 4,
    img: lmc,
  },
  {
    id: 5,
    img: mbanc,
  },
  {
    id: 6,
    img: instasure,
  },
  {
    id: 7,
    img: veevo,
  },
  {
    id: 8,
    img: orbis,
  },
  {
    id: 9,
    img: homes,
  }, 
  {
    id: 10,
    img: nanoqode,
  }, 
  {
    id: 11,
    img: taxease,
  } 
]







export const partnerLogos3 = [
  {
    id: 1,
    img: lender1,
  },
  {
    id: 2,
    img: lender2,
  },
  {
    id: 3,
    img: lender3,
  },
  {
    id: 4,
    img: lender4,
  },
  {
    id: 5,
    img: lender5,
  }, 
  {
    id: 7,
    img: lender7,
  },
  {
    id: 8,
    img: lender8,
  },
  {
    id: 9,
    img: lender9,
  },
  {
    id: 10,
    img: lender10,
  },
  {
    id: 11,
    img: lender11,
  },
  {
    id: 12,
    img: lender12,
  },
  {
    id: 13,
    img: lender13,
  },
  {
    id: 14,
    img: lender14,
  }, 
  {
    id: 11,
    img: lender1,
  },
  {
    id: 12,
    img: lender2,
  },
  {
    id: 13,
    img: lender3,
  },
  {
    id: 14,
    img: lender4,
  },
  {
    id: 15,
    img: lender5,
  }, 
  {
    id: 17,
    img: lender7,
  },
  {
    id: 18,
    img: lender8,
  },
  {
    id: 19,
    img: lender9,
  },
  {
    id: 110,
    img: lender10,
  },
  {
    id: 111,
    img: lender11,
  },
  {
    id: 112,
    img: lender12,
  },
  {
    id: 113,
    img: lender13,
  },
  {
    id: 114,
    img: lender14,
  }, 
]
export const partnerLogos4 = [ 
  {
    id: 16,
    img: lender16,
  },
  {
    id: 17,
    img: lender17,
  },
  {
    id: 18,
    img: lender18,
  },
  {
    id: 19,
    img: lender19,
  },
  {
    id: 20,
    img: lender20,
  },
  {
    id: 21,
    img: lender21,
  },
  {
    id: 22,
    img: lender22,
  },
  {
    id: 23,
    img: lender23,
  },
  {
    id: 24,
    img: lender24,
  },
  {
    id: 25,
    img: lender25,
  },
  {
    id: 26,
    img: lender26,
  },
  {
    id: 27,
    img: lender27,
  },
  {
    id: 28,
    img: lender28,
  },
  {
    id: 29,
    img: lender29,
  },
  {
    id: 30,
    img: lender30,
  },
  {
    id: 116,
    img: lender16,
  },
  {
    id: 117,
    img: lender17,
  },
  {
    id: 118,
    img: lender18,
  },
  {
    id: 119,
    img: lender19,
  },
  {
    id: 120,
    img: lender20,
  },
  {
    id: 121,
    img: lender21,
  },
  {
    id: 122,
    img: lender22,
  },
  {
    id: 123,
    img: lender23,
  },
  {
    id: 124,
    img: lender24,
  },
  {
    id: 125,
    img: lender25,
  },
  {
    id: 126,
    img: lender26,
  },
  {
    id: 127,
    img: lender27,
  },
  {
    id: 128,
    img: lender28,
  },
  {
    id: 129,
    img: lender29,
  },
  {
    id: 130,
    img: lender30,
  }
]