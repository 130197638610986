import React from 'react';
import { Container } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { partnerLogos } from '../../../pages/constant';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import "./partner-logo.scss";

const PartnerLogo2 = () => {
    // var options2 = {
    //     className: "owl-theme",
    //     margin: 50,
    //     responsiveClass: true,
    //     dots: false,
    //     nav: false,
    //     autoWidth: true,
    //     loop: true,
    //     autoplay: true,
    //     smartSpeed: 2500
    // }
    return (
        <section className="cs-partner-logo logo-img-cs">

            <div className="partner-logo-wrap">
                <div className="heading text-center">
                    <h2><span>Largest Lender Network </span>   in Florida, Georgia & Texas</h2>
                </div>

                <Swiper
                breakpoints={{
                    0: { 
                      slidesPerView: 2,
                    },
                    576: { 
                      slidesPerView: 3,
                    },
                    768: { 
                      slidesPerView: 4,
                    },
                    992: { 
                      slidesPerView: 5,
                    },
                    1200: { 
                      slidesPerView: 6,
                    },
                    1300: { 
                      slidesPerView: 7,
                    },
                  }}
                    spaceBetween={50}
                    slidesPerView={7}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false,
                    }}
                    // freeMode={true}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                    // freeModeMomentum={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" width="319" height="319"/>
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </section>
    );
}

export default PartnerLogo2;