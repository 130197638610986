import React from "react";
import './affordability-calculator.scss';
import { Container } from "@mui/material";
import Helmet from 'react-helmet';
const AffordabilityCalculator = function () { 
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mortgage Calculator for Texas, Florida, and Georgia - RateShop Mortgage</title>
                <meta name='description' content='Use RateShop Mortgage&#x27;s mortgage calculator to estimate your monthly mortgage payments in Texas, Florida, and Georgia. Calculate taxes, insurance, and PMI to find out how much house you can afford.' />
                <meta name="keywords" content="mortgage calculator Texas, home loan calculator Texas, mortgage payment calculator Texas, loan affordability calculator Texas, refinance calculator Texas, mortgage interest calculator Texas, mortgage calculator Georgia, home loan calculator Georgia, mortgage payment calculator Georgia, loan affordability calculator Georgia, refinance calculator Georgia, mortgage interest calculator Georgia, mortgage calculator Florida, home loan calculator Florida, mortgage payment calculator Florida, loan affordability calculator Florida, refinance calculator Florida, mortgage interest calculator Florida, mortgage rate calculator Texas, Georgia, Florida" />
            </Helmet>
            <section className="sec-padding">
                <Container>
                    <div className="mortgage-calculator">
                        <div class="heading text-center"><h2><span>Mortgage</span> Calculator</h2></div>
                        <iframe src="https://www.mortgagecalculator.org/webmasters/?downpayment=60000&homevalue=300000&loanamount=240000&interestrate=3&loanterm=30&propertytax=2400&pmi=0&homeinsurance=1000&monthlyhoa=0" scrolling="yes" title="Mortgage Calculator"></iframe>
                    </div> 
                </Container>
            </section>
        </>
    )
}

export default AffordabilityCalculator;