 
import  houston from '../assets/public/images/usa/locations/Houston.png'
import  san_antonio  from '../assets/public/images/usa/locations/San-Antonio.png'
import  dallas  from '../assets/public/images/usa/locations/Dallas.png'
import  austin  from '../assets/public/images/usa/locations/Austin.png'
import  forth_worth  from '../assets/public/images/usa/locations/Fort-Worth.png'
import  el_paso  from '../assets/public/images/usa/locations/El-Paso.png'
import  arlington  from '../assets/public/images/usa/locations/Arlington.png'
import  corpus_christi  from '../assets/public/images/usa/locations/Corpus-Christi.png'
import  plano  from '../assets/public/images/usa/locations/Plano.png'
import  irving  from '../assets/public/images/usa/locations/Irving.png'
import  atlanta  from '../assets/public/images/usa/locations/Atlanta.png'
import  columbus  from '../assets/public/images/usa/locations/Columbus.png'
import  savannah  from '../assets/public/images/usa/locations/Savannah.png'
import  south_fulton  from '../assets/public/images/usa/locations/SouthFulton.png'
import  sandy_springs  from '../assets/public/images/usa/locations/SandySprings.png'
import  roswell  from '../assets/public/images/usa/locations/Roswell.png'
import  macon  from '../assets/public/images/usa/locations/Macon.png'
import  johns_creek  from '../assets/public/images/usa/locations/Johns-Creek.png'
import  albany  from '../assets/public/images/usa/locations/Albany.png'
import  athens  from '../assets/public/images/usa/locations/Athens.png'
import  jacksonville  from '../assets/public/images/usa/locations/Jacksonville.png'
import  miami  from '../assets/public/images/usa/locations/Miami.png'
import  tampa  from '../assets/public/images/usa/locations/Tampa.png'
import  orlando  from '../assets/public/images/usa/locations/Orlando.png'
import  st_petersburg  from '../assets/public/images/usa/locations/St-Petersburg.png'
import  hialeah  from '../assets/public/images/usa/locations/Hialeah.png'
import  port_st_lucie  from '../assets/public/images/usa/locations/Port-St-Lucie.png'  
import  cape_coral  from '../assets/public/images/usa/locations/Cape-Coral.png'
import  tallahassee  from '../assets/public/images/usa/locations/Tallahassee.png'
import  fort_lauderdale  from '../assets/public/images/usa/locations/Fort-Lauderdale.png'

export const locationData = [ 
    {
        province: "texas",
        bannerBg : houston,
        city: "houston",
        title: "Houston, Texas",
        meta_title: "Compare Mortgage Rates in Houston, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Houston, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Houston, home loans Houston, best mortgage lenders Houston, Houston real estate, mortgage brokers Houston, refinance rates Houston, FHA loans Houston, VA loans Houston, first-time home buyer Houston, mortgage calculator Houston, home financing Houston, Houston mortgage options, low mortgage rates Houston, mortgage pre-approval Houston, home equity loans Houston, Houston mortgage refinancing, jumbo loans Houston, Houston mortgage advisors, Houston real estate loans, competitive mortgage rates Houston",
    },
    {
        province: "texas",
        bannerBg :san_antonio ,
        city: "san-antonio", 
        title: "San Antonio, Texas",
        meta_title: "Compare Mortgage Rates in San-Antonio, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in San-Antonio, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates San Antonio, home loans San Antonio, best mortgage lenders San Antonio, San Antonio real estate, mortgage brokers San Antonio, refinance rates San Antonio, FHA loans San Antonio, VA loans San Antonio, first-time home buyer San Antonio, mortgage calculator San Antonio, home financing San Antonio, San Antonio mortgage options, low mortgage rates San Antonio, mortgage pre-approval San Antonio, home equity loans San Antonio, San Antonio mortgage refinancing, jumbo loans San Antonio, San Antonio mortgage advisors, San Antonio real estate loans, competitive mortgage rates San Antonio",
        
    },
    {
        province: "texas",
        bannerBg :dallas ,
        city: "dallas", 
        title: "Dallas, Texas",
        meta_title: "Compare Mortgage Rates in Dallas, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Dallas, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Dallas, home loans Dallas, best mortgage lenders Dallas, Dallas real estate, mortgage brokers Dallas, refinance rates Dallas, FHA loans Dallas, VA loans Dallas, first-time home buyer Dallas, mortgage calculator Dallas, home financing Dallas, Dallas mortgage options, low mortgage rates Dallas, mortgage pre-approval Dallas, home equity loans Dallas, Dallas mortgage refinancing, jumbo loans Dallas, Dallas mortgage advisors, Dallas real estate loans, competitive mortgage rates Dallas",
    },
    {
        province: "texas",
        bannerBg : austin,
        city: "austin", 
        title: "Austin, Texas",
        meta_title: "Compare Mortgage Rates in Austin, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Austin, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Austin, home loans Austin, best mortgage lenders Austin, Austin real estate, mortgage brokers Austin, refinance rates Austin, FHA loans Austin, VA loans Austin, first-time home buyer Austin, mortgage calculator Austin, home financing Austin, Austin mortgage options, low mortgage rates Austin, mortgage pre-approval Austin, home equity loans Austin, Austin mortgage refinancing, jumbo loans Austin, Austin mortgage advisors, Austin real estate loans, competitive mortgage rates Austin", 
    },
    {
        province: "texas",
        bannerBg : forth_worth,
        city: "forth-worth", 
        title: "Forth Worth, Texas",
        meta_title: "Compare Mortgage Rates in Forth-Worth, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Forth-Worth, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Fort Worth, home loans Fort Worth, best mortgage lenders Fort Worth, Fort Worth real estate, mortgage brokers Fort Worth, refinance rates Fort Worth, FHA loans Fort Worth, VA loans Fort Worth, first-time home buyer Fort Worth, mortgage calculator Fort Worth, home financing Fort Worth, Fort Worth mortgage options, low mortgage rates Fort Worth, mortgage pre-approval Fort Worth, home equity loans Fort Worth, Fort Worth mortgage refinancing, jumbo loans Fort Worth, Fort Worth mortgage advisors, Fort Worth real estate loans, competitive mortgage rates Fort Worth", 
    },
    {
        province: "texas",
        bannerBg : el_paso,
        city: "el-paso", 
        title: "El Paso, Texas",
        meta_title: "Compare Mortgage Rates in El-Paso, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in El-Paso, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates El Paso, home loans El Paso, best mortgage lenders El Paso, El Paso real estate, mortgage brokers El Paso, refinance rates El Paso, FHA loans El Paso, VA loans El Paso, first-time home buyer El Paso, mortgage calculator El Paso, home financing El Paso, El Paso mortgage options, low mortgage rates El Paso, mortgage pre-approval El Paso, home equity loans El Paso, El Paso mortgage refinancing, jumbo loans El Paso, El Paso mortgage advisors, El Paso real estate loans, competitive mortgage rates El Paso", 
    },
    {
        province: "texas",
        bannerBg : arlington,
        city: "arlington", 
        title: "Arlington, Texas",
        meta_title: "Compare Mortgage Rates in Arlington, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Arlington, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Arlington, home loans Arlington, best mortgage lenders Arlington, Arlington real estate, mortgage brokers Arlington, refinance rates Arlington, FHA loans Arlington, VA loans Arlington, first-time home buyer Arlington, mortgage calculator Arlington, home financing Arlington, Arlington mortgage options, low mortgage rates Arlington, mortgage pre-approval Arlington, home equity loans Arlington, Arlington mortgage refinancing, jumbo loans Arlington, Arlington mortgage advisors, Arlington real estate loans, competitive mortgage rates Arlington", 
    },
    {
        province: "texas",
        bannerBg : corpus_christi,
        city: "corpus-christi", 
        title: "Corpus Christi, Texas",
        meta_title: "Compare Mortgage Rates in Corpus-Christi, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Corpus-Christi, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Corpus Christi, home loans Corpus Christi, best mortgage lenders Corpus Christi, Corpus Christi real estate, mortgage brokers Corpus Christi, refinance rates Corpus Christi, FHA loans Corpus Christi, VA loans Corpus Christi, first-time home buyer Corpus Christi, mortgage calculator Corpus Christi, home financing Corpus Christi, Corpus Christi mortgage options, low mortgage rates Corpus Christi, mortgage pre-approval Corpus Christi, home equity loans Corpus Christi, Corpus Christi mortgage refinancing, jumbo loans Corpus Christi, Corpus Christi mortgage advisors, Corpus Christi real estate loans, competitive mortgage rates Corpus Christi", 
    },
    {
        province: "texas",
        bannerBg : plano,
        city: "plano", 
        title: "Plano, Texas",
        meta_title: "Compare Mortgage Rates in Plano, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Plano, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Plano, home loans Plano, best mortgage lenders Plano, Plano real estate, mortgage brokers Plano, refinance rates Plano, FHA loans Plano, VA loans Plano, first-time home buyer Plano, mortgage calculator Plano, home financing Plano, Plano mortgage options, low mortgage rates Plano, mortgage pre-approval Plano, home equity loans Plano, Plano mortgage refinancing, jumbo loans Plano, Plano mortgage advisors, Plano real estate loans, competitive mortgage rates Plano", 
    },
    {
        province: "texas",
        bannerBg : irving,
        city: "irving", 
        title: "Irving, Texas",
        meta_title: "Compare Mortgage Rates in Irving, Texas | Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Irving, Texas with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Irving, home loans Irving, best mortgage lenders Irving, Irving real estate, mortgage brokers Irving, refinance rates Irving, FHA loans Irving, VA loans Irving, first-time home buyer Irving, mortgage calculator Irving, home financing Irving, Irving mortgage options, low mortgage rates Irving, mortgage pre-approval Irving, home equity loans Irving, Irving mortgage refinancing, jumbo loans Irving, Irving mortgage advisors, Irving real estate loans, competitive mortgage rates Irving", 
    },
    {
        province: "georgia",
        bannerBg : atlanta,
        city: "atlanta", 
        title: "Atlanta, Georgia",
        meta_title: "Compare Mortgage Rates in Atlanta, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Atlanta, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Atlanta, home loans Atlanta, best mortgage lenders Atlanta, Atlanta real estate, mortgage brokers Atlanta, refinance rates Atlanta, FHA loans Atlanta, VA loans Atlanta, first-time home buyer Atlanta, mortgage calculator Atlanta, home financing Atlanta, Atlanta mortgage options, low mortgage rates Atlanta, mortgage pre-approval Atlanta, home equity loans Atlanta, Atlanta mortgage refinancing, jumbo loans Atlanta, Atlanta mortgage advisors, Atlanta real estate loans, competitive mortgage rates Atlanta", 
    },
    {
        province: "georgia",
        bannerBg : columbus,
        city: "columbus", 
        title: "Columbus, Georgia",
        meta_title: "Compare Mortgage Rates in Columbus, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Columbus, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Columbus, home loans Columbus, best mortgage lenders Columbus, Columbus real estate, mortgage brokers Columbus, refinance rates Columbus, FHA loans Columbus, VA loans Columbus, first-time home buyer Columbus, mortgage calculator Columbus, home financing Columbus, Columbus mortgage options, low mortgage rates Columbus, mortgage pre-approval Columbus, home equity loans Columbus, Columbus mortgage refinancing, jumbo loans Columbus, Columbus mortgage advisors, Columbus real estate loans, competitive mortgage rates Columbus", 
    },
    {
        province: "georgia",
        bannerBg : savannah,
        city: "savannah", 
        title: "Savannah, Georgia",
        meta_title: "Compare Mortgage Rates in Savannah, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Savannah, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Savannah, home loans Savannah, best mortgage lenders Savannah, Savannah real estate, mortgage brokers Savannah, refinance rates Savannah, FHA loans Savannah, VA loans Savannah, first-time home buyer Savannah, mortgage calculator Savannah, home financing Savannah, Savannah mortgage options, low mortgage rates Savannah, mortgage pre-approval Savannah, home equity loans Savannah, Savannah mortgage refinancing, jumbo loans Savannah, Savannah mortgage advisors, Savannah real estate loans, competitive mortgage rates Savannah", 
    },
    {
        province: "georgia",
        bannerBg : south_fulton ,
        city: "south-fulton", 
        title: "South Fulton, Georgia",
        meta_title: "Compare Mortgage Rates in South-Fulton, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in South-Fulton, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates South Fulton, home loans South Fulton, best mortgage lenders South Fulton, South Fulton real estate, mortgage brokers South Fulton, refinance rates South Fulton, FHA loans South Fulton, VA loans South Fulton, first-time home buyer South Fulton, mortgage calculator South Fulton, home financing South Fulton, South Fulton mortgage options, low mortgage rates South Fulton, mortgage pre-approval South Fulton, home equity loans South Fulton, South Fulton mortgage refinancing, jumbo loans South Fulton, South Fulton mortgage advisors, South Fulton real estate loans, competitive mortgage rates South Fulton", 
    },
    {
        province: "georgia",
        bannerBg : sandy_springs,
        city: "sandy-springs", 
        title: "Sandy Springs, Georgia",
        meta_title: "Compare Mortgage Rates in Sandy-Springs, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Sandy-Springs, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Sandy Springs, home loans Sandy Springs, best mortgage lenders Sandy Springs, Sandy Springs real estate, mortgage brokers Sandy Springs, refinance rates Sandy Springs, FHA loans Sandy Springs, VA loans Sandy Springs, first-time home buyer Sandy Springs, mortgage calculator Sandy Springs, home financing Sandy Springs, Sandy Springs mortgage options, low mortgage rates Sandy Springs, mortgage pre-approval Sandy Springs, home equity loans Sandy Springs, Sandy Springs mortgage refinancing, jumbo loans Sandy Springs, Sandy Springs mortgage advisors, Sandy Springs real estate loans, competitive mortgage rates Sandy Springs", 
    },
    {
        province: "georgia",
        bannerBg : roswell,
        city: "roswell", 
        title: "Roswell, Georgia",
        meta_title: "Compare Mortgage Rates in Roswell, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Roswell, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Roswell, home loans Roswell, best mortgage lenders Roswell, Roswell real estate, mortgage brokers Roswell, refinance rates Roswell, FHA loans Roswell, VA loans Roswell, first-time home buyer Roswell, mortgage calculator Roswell, home financing Roswell, Roswell mortgage options, low mortgage rates Roswell, mortgage pre-approval Roswell, home equity loans Roswell, Roswell mortgage refinancing, jumbo loans Roswell, Roswell mortgage advisors, Roswell real estate loans, competitive mortgage rates Roswell", 
    },
    {
        province: "georgia",
        bannerBg :macon ,
        city: "macon", 
        title: "Macon, Georgia",
        meta_title: "Compare Mortgage Rates in Macon, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Macon, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Macon, home loans Macon, best mortgage lenders Macon, Macon real estate, mortgage brokers Macon, refinance rates Macon, FHA loans Macon, VA loans Macon, first-time home buyer Macon, mortgage calculator Macon, home financing Macon, Macon mortgage options, low mortgage rates Macon, mortgage pre-approval Macon, home equity loans Macon, Macon mortgage refinancing, jumbo loans Macon, Macon mortgage advisors, Macon real estate loans, competitive mortgage rates Macon", 
    },
    {
        province: "georgia",
        bannerBg : johns_creek,
        city: "johns-creek", 
        title: "Johns Creek, Georgia",
        meta_title: "Compare Mortgage Rates in Johns-Creek, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Johns-Creek, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Johns Creek, home loans Johns Creek, best mortgage lenders Johns Creek, Johns Creek real estate, mortgage brokers Johns Creek, refinance rates Johns Creek, FHA loans Johns Creek, VA loans Johns Creek, first-time home buyer Johns Creek, mortgage calculator Johns Creek, home financing Johns Creek, Johns Creek mortgage options, low mortgage rates Johns Creek, mortgage pre-approval Johns Creek, home equity loans Johns Creek, Johns Creek mortgage refinancing, jumbo loans Johns Creek, Johns Creek mortgage advisors, Johns Creek real estate loans, competitive mortgage rates Johns Creek", 
    },
    {
        province: "georgia",
        bannerBg : albany,
        city: "albany", 
        title: "Albany, Georgia",
        meta_title: "Compare Mortgage Rates in Albany, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Albany, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Albany, home loans Albany, best mortgage lenders Albany, Albany real estate, mortgage brokers Albany, refinance rates Albany, FHA loans Albany, VA loans Albany, first-time home buyer Albany, mortgage calculator Albany, home financing Albany, Albany mortgage options, low mortgage rates Albany, mortgage pre-approval Albany, home equity loans Albany, Albany mortgage refinancing, jumbo loans Albany, Albany mortgage advisors, Albany real estate loans, competitive mortgage rates Albany", 
    },
    {
        province: "georgia",
        bannerBg : athens,
        city: "athens", 
        title: "Athens, Georgia",
        meta_title: "Compare Mortgage Rates in Athens, Georgia| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Athens, Georgia with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Athens, home loans Athens, best mortgage lenders Athens, Athens real estate, mortgage brokers Athens, refinance rates Athens, FHA loans Athens, VA loans Athens, first-time home buyer Athens, mortgage calculator Athens, home financing Athens, Athens mortgage options, low mortgage rates Athens, mortgage pre-approval Athens, home equity loans Athens, Athens mortgage refinancing, jumbo loans Athens, Athens mortgage advisors, Athens real estate loans, competitive mortgage rates Athens", 
    },
    {
        province: "florida",
        bannerBg : jacksonville,
        city: "jacksonville", 
        title: "Jacksonville, Florida",
        meta_title: "Compare Mortgage Rates in Jacksonville, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Jacksonville, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Jacksonville, home loans Jacksonville, best mortgage lenders Jacksonville, Jacksonville real estate, mortgage brokers Jacksonville, refinance rates Jacksonville, FHA loans Jacksonville, VA loans Jacksonville, first-time home buyer Jacksonville, mortgage calculator Jacksonville, home financing Jacksonville, Jacksonville mortgage options, low mortgage rates Jacksonville, mortgage pre-approval Jacksonville, home equity loans Jacksonville, Jacksonville mortgage refinancing, jumbo loans Jacksonville, Jacksonville mortgage advisors, Jacksonville real estate loans, competitive mortgage rates Jacksonville", 
    },
    {
        province: "florida",
        bannerBg : miami,
        city: "miami", 
        title: "Miami, Florida",
        meta_title: "Compare Mortgage Rates in Miami, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Miami, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Miami, home loans Miami, best mortgage lenders Miami, Miami real estate, mortgage brokers Miami, refinance rates Miami, FHA loans Miami, VA loans Miami, first-time home buyer Miami, mortgage calculator Miami, home financing Miami, Miami mortgage options, low mortgage rates Miami, mortgage pre-approval Miami, home equity loans Miami, Miami mortgage refinancing, jumbo loans Miami, Miami mortgage advisors, Miami real estate loans, competitive mortgage rates Miami", 
    },
    {
        province: "florida",
        bannerBg : tampa,
        city: "tampa", 
        title: "Tampa, Florida",
        meta_title: "Compare Mortgage Rates in Tampa, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Tampa, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Tampa, home loans Tampa, best mortgage lenders Tampa, Tampa real estate, mortgage brokers Tampa, refinance rates Tampa, FHA loans Tampa, VA loans Tampa, first-time home buyer Tampa, mortgage calculator Tampa, home financing Tampa, Tampa mortgage options, low mortgage rates Tampa, mortgage pre-approval Tampa, home equity loans Tampa, Tampa mortgage refinancing, jumbo loans Tampa, Tampa mortgage advisors, Tampa real estate loans, competitive mortgage rates Tampa", 
    },
    {
        province: "florida",
        bannerBg : orlando,
        city: "orlando", 
        title: "Orlando, Florida",
        meta_title: "Compare Mortgage Rates in Orlando, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Orlando, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Orlando, home loans Orlando, best mortgage lenders Orlando, Orlando real estate, mortgage brokers Orlando, refinance rates Orlando, FHA loans Orlando, VA loans Orlando, first-time home buyer Orlando, mortgage calculator Orlando, home financing Orlando, Orlando mortgage options, low mortgage rates Orlando, mortgage pre-approval Orlando, home equity loans Orlando, Orlando mortgage refinancing, jumbo loans Orlando, Orlando mortgage advisors, Orlando real estate loans, competitive mortgage rates Orlando", 
    },
    {
        province: "florida",
        bannerBg : st_petersburg,
        city: "st-petersburg", 
        title: "St. Petersburg, Florida",
        meta_title: "Compare Mortgage Rates in St-Petersburg, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in St-Petersburg, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates St. Petersburg, home loans St. Petersburg, best mortgage lenders St. Petersburg, St. Petersburg real estate, mortgage brokers St. Petersburg, refinance rates St. Petersburg, FHA loans St. Petersburg, VA loans St. Petersburg, first-time home buyer St. Petersburg, mortgage calculator St. Petersburg, home financing St. Petersburg, St. Petersburg mortgage options, low mortgage rates St. Petersburg, mortgage pre-approval St. Petersburg, home equity loans St. Petersburg, St. Petersburg mortgage refinancing, jumbo loans St. Petersburg, St. Petersburg mortgage advisors, St. Petersburg real estate loans, competitive mortgage rates St. Petersburg", 
    },
    {
        province: "florida",
        bannerBg : hialeah,
        city: "hialeah", 
        title: "Hialeah, Florida",
        meta_title: "Compare Mortgage Rates in Hialeah, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Hialeah, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Hialeah, home loans Hialeah, best mortgage lenders Hialeah, Hialeah real estate, mortgage brokers Hialeah, refinance rates Hialeah, FHA loans Hialeah, VA loans Hialeah, first-time home buyer Hialeah, mortgage calculator Hialeah, home financing Hialeah, Hialeah mortgage options, low mortgage rates Hialeah, mortgage pre-approval Hialeah, home equity loans Hialeah, Hialeah mortgage refinancing, jumbo loans Hialeah, Hialeah mortgage advisors, Hialeah real estate loans, competitive mortgage rates Hialeah", 
    },
    {
        province: "florida",
        bannerBg : port_st_lucie,
        city: "port-st-lucie", 
        title: "Port St. Lucie, Florida",
        meta_title: "Compare Mortgage Rates in Port-St-Lucie, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Port-St-Lucie, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Port St. Lucie, home loans Port St. Lucie, best mortgage lenders Port St. Lucie, Port St. Lucie real estate, mortgage brokers Port St. Lucie, refinance rates Port St. Lucie, FHA loans Port St. Lucie, VA loans Port St. Lucie, first-time home buyer Port St. Lucie, mortgage calculator Port St. Lucie, home financing Port St. Lucie, Port St. Lucie mortgage options, low mortgage rates Port St. Lucie, mortgage pre-approval Port St. Lucie, home equity loans Port St. Lucie, Port St. Lucie mortgage refinancing, jumbo loans Port St. Lucie, Port St. Lucie mortgage advisors, Port St. Lucie real estate loans, competitive mortgage rates Port St. Lucie", 
    },
    {
        province: "florida",
        bannerBg : cape_coral,
        city: "cape-coral", 
        title: "Cape Coral, Florida",
        meta_title: "Compare Mortgage Rates in Cape-Coral, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Cape-Coral, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Cape Coral, home loans Cape Coral, best mortgage lenders Cape Coral, Cape Coral real estate, mortgage brokers Cape Coral, refinance rates Cape Coral, FHA loans Cape Coral, VA loans Cape Coral, first-time home buyer Cape Coral, mortgage calculator Cape Coral, home financing Cape Coral, Cape Coral mortgage options, low mortgage rates Cape Coral, mortgage pre-approval Cape Coral, home equity loans Cape Coral, Cape Coral mortgage refinancing, jumbo loans Cape Coral, Cape Coral mortgage advisors, Cape Coral real estate loans, competitive mortgage rates Cape Coral", 
    },
    {
        province: "florida",
        bannerBg : tallahassee,
        city: "tallahassee", 
        title: "Tallahassee, Florida",
        meta_title: "Compare Mortgage Rates in Tallahassee, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Tallahassee, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Tallahassee, home loans Tallahassee, best mortgage lenders Tallahassee, Tallahassee real estate, mortgage brokers Tallahassee, refinance rates Tallahassee, FHA loans Tallahassee, VA loans Tallahassee, first-time home buyer Tallahassee, mortgage calculator Tallahassee, home financing Tallahassee, Tallahassee mortgage options, low mortgage rates Tallahassee, mortgage pre-approval Tallahassee, home equity loans Tallahassee, Tallahassee mortgage refinancing, jumbo loans Tallahassee, Tallahassee mortgage advisors, Tallahassee real estate loans, competitive mortgage rates Tallahassee", 
    },
    {
        province: "florida",
        bannerBg : fort_lauderdale,
        city: "fort-lauderdale", 
        title: "Fort Lauderdale, Florida",
        meta_title: "Compare Mortgage Rates in Fort-Lauderdale, Florida| Best Home Loans | RateShop Mortgage",
        meta_description: "Find the best mortgage rates in Fort-Lauderdale, Florida with RateShop Mortgage. Compare home loans, including FHA, VA, and conventional mortgages. Get expert guidance and secure the lowest rates for your dream home.",
        meta_keyword: "mortgage rates Fort Lauderdale, home loans Fort Lauderdale, best mortgage lenders Fort Lauderdale, Fort Lauderdale real estate, mortgage brokers Fort Lauderdale, refinance rates Fort Lauderdale, FHA loans Fort Lauderdale, VA loans Fort Lauderdale, first-time home buyer Fort Lauderdale, mortgage calculator Fort Lauderdale, home financing Fort Lauderdale, Fort Lauderdale mortgage options, low mortgage rates Fort Lauderdale, mortgage pre-approval Fort Lauderdale, home equity loans Fort Lauderdale, Fort Lauderdale mortgage refinancing, jumbo loans Fort Lauderdale, Fort Lauderdale mortgage advisors, Fort Lauderdale real estate loans, competitive mortgage rates Fort Lauderdale", 
    },
];