import React from 'react';
import "./appointment.scss";
import { Container } from "@mui/material";

const BookAnAppointment = () => {
  return (
    <>
      <section className="book-an-appointment">
        <Container>
          <div className="heading text-center">
            <h2><span>Book </span> an Appointment</h2>
          </div>
          <iframe src="https://api.leadconnectorhq.com/widget/booking/d4PMP2xrL2HVpCUlqaxR" title="Book an Appointment" scrolling="yes" id="d4PMP2xrL2HVpCUlqaxR_1707145396204"></iframe><script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
        </Container>
      </section>
    </>
  );
}

export default BookAnAppointment;