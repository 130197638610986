import React from 'react';
import "./review.scss";
import { Container, Grid } from "@mui/material";
import logo2 from '../../../assets/public/images/webp/rateshop-logo2.webp';
import google from '../../../assets/public/images/usa/google.webp';

const GoogleReviews = () => {
  return (
    <>
      {/* Reviews start here */}
      <section className="reviews">
        <Container>
          <Grid container className="align-items-center reviews-logos">
            <Grid sm={2} md={1} lg={2} item>
              <figure>
                <img src={logo2} alt="logo" className='fixed-logo' />
              </figure>
            </Grid>
            <Grid sm={8} md={10} lg={8} item>
              <div className="heading text-center"><h2><span>Mortgage Deals</span> that Make Client's Happy</h2>
              </div>
            </Grid>
            <Grid sm={2} md={1} lg={2} className="d-flex justify-content-end" item>
              <figure>
                <img src={google} alt=""></img>
              </figure>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <script type='text/javascript' src='https://api.leadconnectorhq.com/js/reviews_widget.js'></script>
          <iframe class='lc_reviews_widget' title="Booking Widget" src='https://services.leadconnectorhq.com/reputation/widgets/review_widget/PPZ6Iw1qo2ZZ312dn7or' frameborder='0' scrolling='no'  ></iframe>
        </Container>
      </section>
    </>
  );
}

export default GoogleReviews;