import React, { useEffect } from "react";
import './packages.scss';
import Helmet from 'react-helmet';
import { Container } from "@mui/material";

const Packages = function () {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Job Packages at RateShop Mortgage | Texas, Florida, and Georgia</title>
                <meta name='description' content='Explore job packages at RateShop Mortgage in Florida, Texas and Georgia. Discover competitive benefits and career growth opportunities. Apply online or contact us for more information.' />
                <meta name="keywords" content="job packages Texas, career packages Texas, employment benefits Texas, RateShop Mortgage job packages Texas, RateShop Mortgage career packages Texas, RateShop Mortgage benefits Texas, job packages Georgia, career packages Georgia, employment benefits Georgia, RateShop Mortgage job packages Georgia, RateShop Mortgage career packages Georgia, RateShop Mortgage benefits Georgia, job packages Florida, career packages Florida, employment benefits Florida, RateShop Mortgage job packages Florida, RateShop Mortgage career packages Florida, RateShop Mortgage benefits Florida, RateShop Mortgage employment packages Texas, Georgia, Florida" />
            </Helmet>
            {/* banner start */}
            <section className="inner-banner-sec">
                <Container>
                    <div className="heading"><h1><span>Become</span>  a RateShop Broker</h1></div>
                    <p>If you're interested in joining one of USA fastest up-and-coming mortgage brokerages, choose a plan below to get started.</p>
                </Container>
            </section>
            {/* leader in start */}
            <section className="zoho-iframe">
                <Container>
                <stripe-pricing-table pricing-table-id="prctbl_1OhvqqFyseGDTlWbMtMqsDQD"
                    publishable-key="pk_live_51OcWEdFyseGDTlWbd3IxjRzajtL06oXGhCCT6dhxngEqrqpl32mbJUhcKw3cP3CNK8Yx7MyN4mVWZlhcLFZOWdl500YQbKRX37">
                </stripe-pricing-table>
                </Container>
            </section>
        </>
    )
}

export default Packages;


