import React, {useEffect, useState} from "react";  
import { Container } from "@mui/material"; 
import './header.scss' 
import logo from '../../../assets/public/images/usa/logo.png';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { teamMemberOne,teamMemberTwo,teamMemberThree, defaultProfileImage } from "../../../core/Images";
import { Button  } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FaxIcon from '@mui/icons-material/Fax';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import agentImg from '../../../assets/public/images/agent-1.jpg';
import Cookies from 'universal-cookie';
import axios from 'axios';
const Header = () => { 
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams?.get('utm_source');
  const utm_medium = searchParams?.get('utm_medium');
  const utm_campaign = searchParams?.get('utm_campaign');
  const utm_content = searchParams?.get('utm_content');
  const utm_keyword = searchParams?.get('utm_keyword');
  const utm_matchtype = searchParams?.get('utm_matchtype');
  const campaign_id = searchParams?.get('campaign_id');
  const ad_group_id = searchParams?.get('ad_group_id');
  const ad_id = searchParams?.get('ad_id');
  const gclid = searchParams?.get('gclid');
  if(searchParams.size > 0){
   
    cookies.set('utm_source', utm_source, { expires: 0 });
    cookies.set('utm_source', utm_source, { expires: 0 });
    cookies.set('utm_medium', utm_medium, { expires: 0 });
    cookies.set('utm_campaign', utm_campaign, { expires: 0 });
    cookies.set('utm_content', utm_content, { expires: 0 });
    cookies.set('utm_keyword', utm_keyword, { expires: 0 });
    cookies.set('utm_matchtype', utm_matchtype, { expires: 0 });
    cookies.set('campaign_id', campaign_id, { expires: 0 });
    cookies.set('ad_group_id', ad_group_id, { expires: 0 });
    cookies.set('ad_id', ad_id, { expires: 0 });
    cookies.set('gclid', gclid, { expires: 0 });

  }
  const [openCalc1, setOpenCalc1] = React.useState(false); 
  const anchorRefCalc1 = React.useRef(null);
  const [staffDetail, setStaffDetail] = React.useState([]);
  const navigate = useNavigate();
  const {slug} = useParams();
  if(slug != undefined){

    const data = {
      type: 'single',
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
      slug: slug
    };


      axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
          .then(res => {
          if(res.data.status != 200){
              navigate('/404', { replace: true });
          }else{
            const cookies = new Cookies();
            cookies.set('agentSite', 1, { path: '/' });
            cookies.set('cookieAgentSlug', slug, { path: '/' });
            setStaffDetail(res.data.data)
          }
      })

  
  }


  const handleToggleCalc1 = () => {
    setOpenCalc1((prevOpenCalc1) => !prevOpenCalc1);
  };

  const handleCloseCalc1 = (event) => {
    if (anchorRefCalc1.current && anchorRefCalc1.current.contains(event.target)) {
      return;
    }
  
    setOpenCalc1(false);
  };
  
  const [showClass, setShowClass1] = React.useState('');

  const handleToggle1 = () => {

    setShowClass1((prevClass1) => ((prevClass1 == '') ? 'show' : ''));

  };

  function handleListKeyDownCalc1(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCalc1(false);
    } else if (event.key === 'Escape') {
      setOpenCalc1(false);
    }
  }
  return (
    <> 
      <header className="apply-header">
        <Container> 
            <div className="site-logo d-flex justify-content-center" >
               <NavLink to='/' className="navbar-brand" >
                    <img src={logo} alt=""/>
               </NavLink>  
               {
          (cookies.get('agentSite'))?<MenuList className="no-padd">
          <MenuItem className="head-agent-details">
            <Button
              ref={anchorRefCalc1}
              id="composition-button"
              aria-controls={openCalc1 ? 'composition-menu' : undefined}
              aria-expanded={openCalc1 ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggleCalc1} 
              className='has-dropdown'
              title={staffDetail[0]?.firstname}
            >

              {staffDetail.map((agent) => {
                return (
                  <>
                    {(() => {
                      // console.log('agent', agent.staffid);
                      let imagePath = agent.profile_image;
                      if (imagePath != null) {

                          if (imagePath.includes('https://www.rateshop.ca/')) {
                              return (
                                <img src={agent.profile_image} /> 
                               
                               
                              )
                          }

                          else if (agent.profile_image != null) {
                              return (
                                  <img
                                    src={`https://crm.rateshop.ca/uploads/staff_profile_images/${agent.staffid}/thumb_${agent.profile_image}`}
                                    alt={agent.firstname}
                                  />
                                 
                              )
                          } else {
                              return (
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt={agent.firstname}
                                  />
                                  
                              )
                          }
                      } else {
                          return (
                            <figure>
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt={agent.firstname}
                                  />
                                </figure>
                             
                          )
                      }
                  })()}
                  </>
                );
              })}
            </Button>
            <Popper
              open={openCalc1}
              anchorEl={anchorRefCalc1.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'right-top' ? 'right top' : 'right top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseCalc1}>
                      <MenuList
                        autoFocusItem={openCalc1}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDownCalc1}
                      >
                        <p><PersonIcon/><strong>{staffDetail[0]?.firstname} {staffDetail[0]?.lastname}</strong></p>
                        <p><EmailIcon/><strong><a href="mailto:{staffDetail[0]?.email}">{staffDetail[0]?.email}</a></strong></p>
                        <p><LocalPhoneIcon/><strong><a href="tel:{staffDetail[0]?.cell_number}">{staffDetail[0]?.cell_number}</a></strong></p>
                        <p><PhoneAndroidIcon/><strong>{staffDetail[0]?.phonenumber} Ext:{staffDetail[0]?.extension}</strong></p>
                        <p><FaxIcon/><strong>{staffDetail[0]?.Fax_number}</strong></p>
                        <p><DocumentScannerIcon/><strong>{staffDetail[0]?.lic}</strong></p>
                        <p><VerifiedUserIcon/><strong>{staffDetail[0]?.desigination}</strong></p> 
                        </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </MenuItem>
        </MenuList>:''

                }
            </div>   
        </Container>
    </header>  
    </>
  );
};

export default Header;
