import React, { useEffect, useState } from 'react';
import './join-us-new.scss';
import Helmet from 'react-helmet';
// import { partnerLogos3, partnerLogos4, partnerLogos2 } from '../../../constant';
import { Link, NavLink } from 'react-router-dom';
import { Container, Grid } from "@mui/material";
// import img0 from "../../../../assets/public/images/join-us/banner-logo.png";
// import img1 from "../../../../assets/public/images/join-us/banner-bt-1.png";
// import img2 from "../../../../assets/public/images/join-us/banner-bt-2.png";
// import img3 from "../../../../assets/public/images/join-us/banner-bt-3.png";
import outstandingMortgage from "../../../../assets/public/images/join-us/outstanding-mortgage.png";
// import flag from '../../../../assets/public/images/join-us/flag.png';
// import supportCenter from '../../../../assets/public/images/join-us/support-center.png';
// import supportCenter2 from '../../../../assets/public/images/join-us/support-center.gif';
// import joinUsRight1 from '../../../../assets/public/images/join-us/join-now-1.jpg';
// import joinUsRight2 from '../../../../assets/public/images/join-us/join-now-2.jpg';
// import rateshop from '../../../../assets/public/images/join-us/rateshop-2.mp4';
// import rateshop2 from '../../../../assets/public/images/join-us/rateShop-us.mp4';
// import advantage from '../../../../assets/public/images/join-us/advantage.mp4';
import advantages from '../../../../assets/public/images/join-us/advantages.jpg';
import logo1 from '../../../../assets/public/images/join-us/icons/logo-1.png';
import logo2 from '../../../../assets/public/images/join-us/icons/logo-2.png';
import logo3 from '../../../../assets/public/images/join-us/icons/logo-3.png';
import logo4 from '../../../../assets/public/images/join-us/icons/logo-4.png';
import logo5 from '../../../../assets/public/images/join-us/icons/logo-5.png';
import logo6 from '../../../../assets/public/images/join-us/icons/logo-6.png';
import logo7 from '../../../../assets/public/images/join-us/icons/logo-7.png';
// import logo8 from '../../../../assets/public/images/join-us/icons/logo-8.png';
import logo9 from '../../../../assets/public/images/join-us/icons/logo-9.png';
import { partnerLogos } from '../../../../pages/constant';
import mortgageNew from '../../../../assets/public/images/join-us/mortgage-des.jpg'
import flag2 from '../../../../assets/public/images/join-us/flag2.jpg'
import pdf from '../../../../assets/public/images/join-us/pdf.png'
import img21 from '../../../../assets/public/images/join-us/img-1.jpg';
import img22 from '../../../../assets/public/images/join-us/img-2.jpg';
import img55 from '../../../../assets/public/images/join-us/img55.png';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CalenderPopup2 from '../../../../components/shared/calender-popup/calender-2';
import CalenderPopup3 from '../../../../components/shared/calender-popup/calender-3';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";


import ten from '../../../../assets/public/images/join-us/block/10.png';
import brokerresponsibilities from '../../../../assets/public/images/join-us/block/broker-responsibilities.png';
import careergrowth from '../../../../assets/public/images/join-us/block/career-growth.png';
import careerpath from '../../../../assets/public/images/join-us/block/career-path.png';
import clientnetwork from '../../../../assets/public/images/join-us/block/client-network.png';
import competitivecommission from '../../../../assets/public/images/join-us/block/competitive-commission.png';
import comprehensivetraining from '../../../../assets/public/images/join-us/block/comprehensive-training.png';
import extensivetraining from '../../../../assets/public/images/join-us/block/extensive-training.png';
import fees from '../../../../assets/public/images/join-us/block/fees.png';
import highdemand from '../../../../assets/public/images/join-us/block/high-demand.png';
import howrateshopcanhelp from '../../../../assets/public/images/join-us/block/how-rateshop-can-help.png';
import lucrativeearning from '../../../../assets/public/images/join-us/block/lucrative-earning.png';
import mentorship from '../../../../assets/public/images/join-us/block/mentorship.png';
import prerequisites from '../../../../assets/public/images/join-us/block/prerequisites.png';
import requirements from '../../../../assets/public/images/join-us/block/requirements.png';
import rewardingwork from '../../../../assets/public/images/join-us/block/rewarding-work.png';
import salaryoverview from '../../../../assets/public/images/join-us/block/salary-overview.png';
import stepbystep from '../../../../assets/public/images/join-us/block/step-by-step.png';
import toolsandresources from '../../../../assets/public/images/join-us/block/tools-and-resources.png';
import topsixreasosn from '../../../../assets/public/images/join-us/block/top-six-reasosn.png';

// pages/constant

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const JoinUsNew = function () {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://js.zohostatic.com/books/zfwidgets/assets/js/zf-widget.js';
        script.onload = () => {
            const pricingTableComponentOptions = {
                id: 'zf-widget-root-id',
                product_id: '2-c708195360af4142db8b2f7423890844d6dbcde52d9ed23e07594ba45fce546f523001912db9d89c58bcad6fd428a6fd8c96db5de59c3d3595e7f706b749742d',
                template: 'solo',
                most_popular_plan: '',
                is_group_by_frequency: false,
                isFrequencyDropdown: false,
                isCurrencyDropdown: false,
                can_show_plan_freq: true,
                pricebooks: [],
                group_options: [],
                plans: [
                    {
                        plan_code: '003',
                        selectedAddons: [],
                    },
                ],
                theme: { color: '#07208B', theme_color_light: '' },
                button_text: 'Register Now',
                product_url: 'https://subscriptions.zoho.com',
                price_caption: 'Start Your Mortgage License Sponsorship ',
                language_code: 'en_ca',
                open_inSameTab: false
            };
            window.ZFWidget.init('zf-pricing-table', pricingTableComponentOptions);
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const [baseUrl] = React.useState('/');
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [open1, setOpen1] = useState(false);
    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };


    return (
        <>



            <Helmet>
                <meta charSet="utf-8" />
                <title>Become a Certified Mortgage Broker or Mortgage Loan Originator (MLO) in Florida, Texas & Georgia | RateShop Mortgage</title>
                <meta name='description' content='Join RateShop mortgage to expand your mortgage career. Learn about NMLS prerequisites, income statistics and licensing requirements' />
                <meta name="keywords" content="Mortgage broker certification US, Mortgage broker licensing requirements, How to become a mortgage broker, Mortgage broker training programs, Mortgage broker salary US, Mortgage broker exam prep, Mortgage broker education requirements, Steps to become a mortgage broker, Mortgage broker career guide, Online mortgage broker courses, Mortgage broker job outlook, Best mortgage broker schools, Mortgage broker continuing education, Mortgage broker license renewal, Mortgage broker business plan, Mortgage broker vs loan officer, Mortgage broker state regulations, Mortgage broker networking tips, Mortgage broker certification courses, Mortgage broker interview tips" />
            </Helmet>
            {/* banner start */}
            <section className="banner-sec join-banner better-now">
                <div className="banner-outer-layer">
                </div>
                <Container>
                    <div className="content-wrap">
                        <div className="banner_content">
                            <h1>Be a Mortgage Loan Originator -<br></br> Better Products & Pay!</h1>
                            <p>The mortgage industry has reformed over the past few years, and we’re here to make it more attractive for our Mortgage Loan Originators (MLO) and Mortgage Loan Officers!</p>
                            <p>A fusion of technology and mortgage experience lets you drive your results to achieve milestones. We’re here to support our teams with product knowledge, Technology improvements, and an infrastructure to grow from.</p>
                            {/* <ul className="banner-list">
                                <li>
                                    Share Volume Payouts
                                </li>
                                <li>Transparent Commission Structure</li>
                                <li>Central Underwriting Help Desk</li>
                                <li>Sales, Marketing & Submission Training</li>
                            </ul> */}
                            <Link onClick={handleClickOpen} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                Schedule a Meeting
                            </Link>
                        </div>
                        {/* <div className="bottom-outer">
                            <p>Awards humble us, but they don't fund our deals! Work with a brokerage that's past the glitter!</p>
                            <ul className="d-flex align-items-center flex-wrap justify-content-between">
                                <li>
                                    <figure>
                                        <img src={img1} alt="" />
                                    </figure>
                                </li>
                                <li>
                                    <figure>
                                        <img src={img2} alt="" />
                                    </figure>
                                </li>
                                <li>
                                    <figure>
                                        <img src={img3} alt="" />
                                    </figure>
                                </li>
                                <li>
                                    <figure>
                                        <img src={img0} alt="" />
                                    </figure>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </Container>
            </section>


            {/* <section className="leader-in-sec join-3-steps">
                <Container>
                    <div className="leader-in_content">
                        <Grid container>
                            <Grid xs={12} sm={12} md={12} lg={5} xl={6} item className="cs-p0">
                                <div className="item_card cs-left pt-0">
                                    <div class="heading"><h2><span>5 Easy Steps</span>  to Jumpstart your Mortgage Loan Originator Career </h2> 
                                        <ul>
                                            <li>We made is clear and simple to get you the right information so you can plan your success with us: </li>
                                            <li>Register Online and get Setup for your Accounts & Transfer</li>
                                            <li> Complete your Onboarding & Training</li>
                                            <li>Complete your Lender & Technology setup to start deal submissions</li>
                                            <li>Schedule your One-on-One Broker Mentor Training and join our Weekly Company and Lender Training Webinars </li>
                                        </ul></div>
                                    <Link className="cs-btn MuiButton-contained MuiButton-containedPrimary" to="tel:888-549-1005">
                                        Talk to us
                                    </Link>
                                </div>
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} item className="cs-p0">
                                <div className="item_card cs-right">
                                    <ul className="d-flex ">
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 435 525"    >
                                                        <g>
                                                            <path d="M390.18,428.39l5.34,2.88c1.42,0.77,2.82,1.6,4.14,2.47c2.81,1.81,5.29,3.78,7.38,5.87c3.53,3.54,5.31,7.75,5.31,12.53
        c0,6.69-1.73,12.06-5.13,15.95c-3.2,3.67-7.28,6.49-12.14,8.35l-4.91,1.88V428.39L390.18,428.39z M376.48,404.77l-5.78-4.3
        c-2.31-1.71-4.25-3.72-5.79-5.94c-1.75-2.53-2.64-5.87-2.64-9.91c0-2.87,0.77-5.87,2.35-9.16c1.54-3.2,3.54-5.82,5.92-7.82
        l5.94-4.95V404.77L376.48,404.77z M395.25,367.86c1.97,0.88,3.66,2.01,5,3.37c1.26,1.26,2.23,2.72,2.87,4.33
        c1.01,2.5,1.92,5,2.7,7.46c0.62,1.93,1.46,3.57,2.56,5.03c0.58,0.76,2.16,1.19,4.34,1.19c2.35,0,4.17-0.76,5.74-2.39
        c1.59-1.66,2.36-3.68,2.36-6.22c0-3.78-0.73-7.33-2.18-10.59c-1.48-3.36-3.49-6.35-6-8.92c-2.61-2.66-5.63-4.92-8.96-6.7
        c-2.31-1.23-4.68-2.22-7.04-2.94c-1.21-0.38-2.39-0.7-3.59-0.95l-2.88-0.6v-5.19c0-0.38-0.01-0.74-0.03-1.1
        c-0.32-6.01-3.05-7.23-7.05-7.23c-3.72,0-6.26,1.22-6.57,7.19c-0.03,0.4-0.04,0.77-0.04,1.14v5.06l-2.73,0.69
        c-3.63,0.92-7.06,2.37-10.18,4.3c-3.19,1.97-6.05,4.42-8.52,7.27c-2.45,2.83-4.42,6.16-5.85,9.89c-1.39,3.7-2.1,7.97-2.1,12.65
        c0,4.74,0.68,8.97,2.01,12.58c1.33,3.65,3.23,6.98,5.63,9.9c2.43,2.97,5.32,5.64,8.62,7.95c0.49,0.34,1.03,0.7,1.56,1.06
        c2.98,1.96,6.18,3.76,9.51,5.35l2.05,0.98v55.7l-4.96-1.98c-9.35-3.73-15.51-10.47-18.3-20.03c-1.27-4.65-3.66-6.61-8.01-6.61
        c-1.97,0-3.81,0.74-5.63,2.26c-1.06,0.88-2.44,2.48-2.44,6.34c0,3.72,0.88,7.47,2.63,11.14c0.59,1.21,1.18,2.29,1.8,3.29
        c1.54,2.51,3.45,4.92,5.71,7.19c3.23,3.22,7.15,6.07,11.6,8.45c4.49,2.4,9.45,4.17,14.72,5.25l2.89,0.59v7.93
        c0,5,1.85,6.86,6.85,6.86c4.98,0,6.83-1.86,6.83-6.86v-7.8l3.03-0.51c4.59-0.76,9.05-2.23,13.28-4.38
        c4.16-2.12,7.89-4.93,11.09-8.35c3.15-3.36,5.71-7.54,7.61-12.42c1.91-4.88,2.88-10.55,2.88-16.87c0-4.96-0.9-9.5-2.68-13.47
        c-0.3-0.66-0.6-1.3-0.93-1.92c-1.72-3.35-3.9-6.42-6.49-9.13c-3.2-3.36-7.03-6.43-11.37-9.13c-3.29-2.07-6.81-4.03-10.47-5.82
        c-1.48-0.72-2.69-1.29-3.8-1.79l-2.14-0.96V365.6L395.25,367.86L395.25,367.86z"></path>
                                                            <path d="M75.91,246.11c-7.65,11.31-12.12,24.99-12.12,39.65v35.21c0,2.92,1.77,5.57,4.47,6.69c2.7,1.12,5.82,0.49,7.89-1.58
        l16.32-16.4l157.5,158.28h-51.48v-6.2c0-3.99-3.24-7.23-7.23-7.23s-7.23,3.24-7.23,7.23l0,0v13.43c0,1.9,0.77,3.77,2.12,5.11
        c1.35,1.35,3.21,2.12,5.11,2.12h111.28c3.99,0,7.23-3.24,7.23-7.23c0-3.99-3.24-7.23-7.23-7.23h-32.15L97.6,294.32
        c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13l-9.09,9.13v-17.7c0-7.81,1.58-15.22,4.43-21.96
        c4.28-10.12,11.44-18.75,20.46-24.83c9.02-6.08,19.84-9.63,31.55-9.63h18.4l51.62,89.39c1.5,2.59,4.45,4,7.4,3.53
        c2.95-0.47,5.32-2.73,5.94-5.65l9.52-45.26c0.37-1.75,0.08-3.55-0.81-5.11l-11.7-20.26l9.61-16.64l19.22,0l9.61,16.64l-11.7,20.26
        c-0.89,1.55-1.18,3.36-0.81,5.11l9.2,43.59c0.62,2.93,2.99,5.18,5.94,5.65c2.95,0.47,5.9-0.94,7.4-3.53l50.65-87.72h18.4
        c7.81,0,15.22,1.58,21.97,4.43c10.12,4.28,18.75,11.44,24.84,20.45c6.09,9.02,9.63,19.83,9.64,31.55v32.17
        c0,3.99,3.24,7.23,7.23,7.23c3.99,0,7.23-3.24,7.23-7.23l0,0v-32.17c0-9.77-1.98-19.11-5.58-27.6
        c-5.39-12.73-14.37-23.54-25.68-31.18c-11.31-7.64-24.99-12.11-39.65-12.11h-22.58c-2.58,0-4.97,1.38-6.26,3.62l-43.29,74.99
        l-4.73-22.4l12.4-21.48c1.29-2.24,1.29-4.99,0-7.23l-13.78-23.87c-1.29-2.24-3.68-3.62-6.26-3.62H220.5
        c-2.58,0-4.97,1.38-6.26,3.62l-13.78,23.87c-1.29,2.24-1.29,4.99,0,7.23l12.4,21.48l-5.06,24.06l-44.26-76.64
        c-1.29-2.24-3.68-3.62-6.26-3.62H134.7c-9.77,0-19.11,1.98-27.6,5.57C94.36,225.82,83.55,234.8,75.91,246.11L75.91,246.11z"></path>
                                                            <path d="M282.95,128.59c-3.9-2.5-7.95-5.74-11.81-9.6c-4.94-4.94-8.86-10.2-11.52-15.05c-1.33-2.42-2.34-4.74-3.03-6.83
        c-0.69-2.09-1.04-3.94-1.12-5.4c-0.18-3.25-2.53-5.99-5.72-6.67c-5.31-1.13-10.72-1.7-16.12-1.7c-13.36,0-26.73,3.45-38.7,10.37
        l0,0h0c-23.96,13.83-38.71,39.39-38.71,67.05c0,3.09,0.2,6.15,0.56,9.17c-11.44-15.88-17.9-35.18-17.9-55.41
        c0-33.86,18.06-65.15,47.39-82.07l0,0c0,0,0,0,0,0c14.73-8.5,31.18-12.7,47.66-12.7c16.25,0,32.51,4.27,47.11,12.69c0,0,0,0,0,0
        c29.32,16.93,47.39,48.22,47.39,82.07c0,20.23-6.46,39.53-17.9,55.41c0.36-3.02,0.56-6.08,0.56-9.17c0-7.18-1-14.22-2.89-20.95
        c-0.97-3.45-4.33-5.67-7.88-5.22c-0.36,0.05-0.76,0.07-1.2,0.07c-1.38,0-3.21-0.27-5.32-0.9
        C290.61,132.85,286.85,131.09,282.95,128.59L282.95,128.59z M287.63,193.18c-1.77,2.96-1.22,6.73,1.32,9.06
        c2.55,2.33,6.36,2.53,9.14,0.5c27.95-20.42,44.79-53.1,44.79-88.19c0-39.02-20.82-75.08-54.61-94.6c0,0,0,0-0.01,0
        c-16.8-9.7-35.56-14.64-54.34-14.63c-18.92,0-37.89,4.82-54.89,14.63c0,0-0.01,0-0.01,0c-33.8,19.51-54.62,55.57-54.62,94.6
        c0,35.09,16.83,67.76,44.79,88.19c2.79,2.03,6.6,1.83,9.14-0.5c2.55-2.33,3.1-6.1,1.32-9.06c-5.78-9.63-8.98-20.78-8.98-32.4
        c0-22.49,12-43.28,31.48-54.53c0,0,0,0,0,0c9.74-5.62,20.6-8.43,31.47-8.43c2.77,0,5.54,0.18,8.29,0.55
        c0.26,1.09,0.57,2.18,0.93,3.27c1.51,4.59,3.85,9.27,6.89,13.92c3.04,4.65,6.79,9.27,11.18,13.66c6.1,6.09,12.63,10.95,19.09,14.39
        c3.23,1.72,6.45,3.08,9.63,4.03c1.96,0.58,3.9,1.02,5.84,1.26c0.75,3.87,1.14,7.84,1.14,11.87
        C296.61,172.39,293.41,183.54,287.63,193.18L287.63,193.18z"></path>
                                                            <path d="M124.65,469.52h-11.72v-11.7h11.72V469.52L124.65,469.52z M101.98,482.73c1.23,0.8,2.71,1.26,4.24,1.26h25.13
        c2.04,0,3.98-0.81,5.4-2.18c0.71-0.69,1.3-1.53,1.72-2.48c0.41-0.95,0.64-2.02,0.64-3.08V451.1c0-1.07-0.23-2.14-0.64-3.09
        c-0.62-1.43-1.64-2.59-2.87-3.4c-1.23-0.8-2.71-1.26-4.24-1.26h-25.13c-2.04,0-3.98,0.8-5.39,2.18c-0.71,0.69-1.3,1.52-1.72,2.48
        c-0.42,0.95-0.65,2.02-0.64,3.09v25.15c0,1.07,0.23,2.14,0.64,3.09C99.73,480.76,100.75,481.92,101.98,482.73L101.98,482.73z"></path>
                                                            <path d="M76.15,379.07l16.32-16.39l60.91,61.21c-1.73,0.12-3.39,0.86-4.62,2.09c-1.35,1.35-2.12,3.21-2.12,5.11v50.82v23.3H88.95
        c-0.05-0.41-0.1-0.81-0.15-1.17c-0.07-0.52-0.12-0.97-0.16-1.27c0-0.03-0.01-0.05-0.01-0.08V478.7v-27.6c0-1.02-0.2-2.05-0.6-2.98
        c-0.59-1.41-1.58-2.62-2.84-3.45c-1.25-0.84-2.78-1.31-4.31-1.3h-2.63h-22.5c-2.04,0-3.98,0.8-5.39,2.18
        c-0.71,0.69-1.3,1.52-1.72,2.48c-0.42,0.95-0.65,2.02-0.64,3.09v54.1h-9.69v-74.11c0-1.9-0.77-3.77-2.12-5.11
        c-1.23-1.23-2.89-1.97-4.63-2.09L76.15,379.07L76.15,379.07z M62.46,457.82h11.7v20.88v24.37c0,0.29,0.01,0.4,0.02,0.49
        c0.01,0.17,0.02,0.24,0.03,0.32c0.01,0.13,0.03,0.23,0.04,0.35c0.03,0.25,0.07,0.58,0.12,0.96H62.46V457.82L62.46,457.82z
         M7.51,433.86c1.12,2.7,3.75,4.46,6.68,4.46h9.65v74.11c0,1.9,0.77,3.77,2.12,5.11c1.35,1.35,3.21,2.12,5.11,2.12h23.45
        c0.08,0.01,0.15,0.01,0.21,0.01c0.21,0.01,0.31,0.01,0.38,0.01c0.07,0,0.1,0,0.12,0h0.01l27.14-0.03h71.48
        c1.9,0,3.77-0.77,5.11-2.12c1.35-1.35,2.12-3.21,2.12-5.11V481.9v-43.58l9.65,0c2.92,0,5.56-1.76,6.68-4.46
        c1.12-2.7,0.51-5.8-1.55-7.88L97.6,347.32c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13L65.9,368.86L9.06,425.98
        C7,428.06,6.39,431.16,7.51,433.86L7.51,433.86z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>Book a Confidential Consultation</h5>
                                                <p>Discuss your Future with us online, over the phone, or in person.</p> 
                                                <Link className="cs-btn MuiButton-contained MuiButton-containedPrimary" onClick={handleClickOpen}>
                                                    Schedule Appointment
                                                </Link>

                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 495 448"  >
                                                        <g>
                                                            <path d="M347.16,232.88l78.74,31.16c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-78.74-31.16
        c-3.48-1.38-7.42,0.33-8.79,3.81C341.98,227.57,343.68,231.51,347.16,232.88L347.16,232.88z"></path>
                                                            <path d="M379.19,191.08l51.91,20.54c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-51.91-20.54
        c-3.48-1.38-7.42,0.33-8.79,3.81C374,185.76,375.71,189.7,379.19,191.08L379.19,191.08z"></path>
                                                            <path d="M309.14,263.57l123.49,48.87c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-123.49-48.87
        c-3.48-1.38-7.41,0.33-8.79,3.81C303.96,258.26,305.66,262.2,309.14,263.57L309.14,263.57z"></path>
                                                            <path d="M291.57,307.97L291.57,307.97l24.54,9.71l98.95,39.15c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-98.95-39.15l-24.54-9.71c-3.48-1.38-7.42,0.33-8.79,3.81C286.39,302.66,288.09,306.6,291.57,307.97L291.57,307.97z"></path>
                                                            <path d="M300.19,286.19L300.19,286.19l14.22,5.63l0,0l109.27,43.24c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-109.27-43.24l0,0l-14.22-5.63c-3.48-1.38-7.42,0.33-8.79,3.81C295,280.88,296.71,284.81,300.19,286.19L300.19,286.19z"></path>
                                                            <path d="M68.77,215.55c17.3-11.68,38.1-18.49,60.56-18.5h3.61h19.03l-3.43,2.13l0,0l-5.05,3.13c-2.73,1.69-3.9,5.07-2.79,8.08
        l3.17,8.66l3.91,16.77l-0.88,10.2v0l-12.48,143.96c-0.19,2.14,0.64,4.22,2.25,5.65l24.79,22.04c2.55,2.27,6.37,2.29,8.94,0.05
        l25.11-21.81c1.62-1.41,2.48-3.49,2.31-5.64l-11.17-144l-0.8-10.27l4.05-16.71l3.27-8.5c1.15-3,0.03-6.4-2.67-8.13l-5.12-3.28
        l-3.61-2.31h18.45h3.29c13.38,0,26.16,2.42,37.98,6.85c11.81,4.42,22.65,10.85,32.1,18.88c2.53,2.15,4.93,4.52,7.21,7.06l8.79,9.8
        l-45.67,115.42l0,0c-0.85,2.15-1.26,4.41-1.26,6.61c0,3.6,1.08,7.07,3.03,10.01c1.95,2.94,4.83,5.36,8.35,6.74l0,0l57.87,22.9
        c-0.04,0.3-0.07,0.61-0.07,0.92l0,0v27.74H21.01v-124.6c0-14.97,3.03-29.21,8.51-42.16C37.73,243.79,51.48,227.23,68.77,215.55
        L68.77,215.55z M156.96,215.58c-0.06-0.28-0.14-0.52-0.23-0.79l-1.41-3.87l0.36-0.22l0,0l11.32-7.01l11.08,7.09l0.47,0.3
        l-1.44,3.76c-0.1,0.26-0.19,0.56-0.26,0.83l-3.15,12.98l-13.7-0.06L156.96,215.58L156.96,215.58z M166,403.58l-17.8-15.82
        l12.18-140.57l0,0l0.44-5.05l11.92,0.05l0.4,5.12l10.91,140.6L166,403.58L166,403.58z M325.1,186.71c0.36-0.9,0.93-1.57,1.66-2.07
        c0.73-0.49,1.61-0.76,2.48-0.76c0.54,0,1.08,0.1,1.64,0.32l8.35,3.31c1.66,0.66,3.55,0.63,5.18-0.08c1.64-0.71,2.95-2.07,3.61-3.72
        l4.39-11.1c0.86-2.17,2.3-3.88,4.09-5.08c1.79-1.2,3.93-1.86,6.11-1.86c1.34,0,2.69,0.24,4.03,0.78l90.5,35.81l2.49-6.3l-2.49,6.3
        c2.17,0.86,3.87,2.3,5.08,4.09c1.2,1.79,1.86,3.93,1.86,6.11c0,1.34-0.24,2.69-0.77,4.03l-4.39,11.1
        c-1.38,3.48,0.33,7.41,3.81,8.79l8.35,3.31c0.89,0.36,1.57,0.93,2.06,1.66c0.49,0.73,0.76,1.61,0.76,2.49
        c0,0.54-0.1,1.08-0.32,1.63v0l-68.59,173.32c-0.36,0.9-0.93,1.57-1.66,2.07c-0.73,0.49-1.61,0.76-2.48,0.76
        c-0.54,0-1.08-0.1-1.64-0.32l-78.12-30.91l-62.08-24.57l0,0c-0.89-0.36-1.56-0.92-2.06-1.66c-0.49-0.73-0.76-1.61-0.76-2.49
        c0-0.54,0.1-1.08,0.31-1.63l0,0l47.06-118.93L325.1,186.71L325.1,186.71z M28.27,237.23c-13.14,19.44-20.82,42.93-20.82,68.15
        v131.37c0,1.78,0.72,3.53,1.98,4.79c1.26,1.26,3.01,1.98,4.79,1.98h304.38c1.78,0,3.53-0.72,4.79-1.98
        c1.26-1.26,1.98-3.01,1.98-4.79v-30.1l68.85,27.25c2.16,0.85,4.41,1.27,6.62,1.27c3.6,0,7.07-1.08,10.01-3.04
        c2.94-1.96,5.35-4.83,6.74-8.35l68.59-173.32v0c0.85-2.16,1.27-4.41,1.27-6.62c0-3.6-1.08-7.07-3.04-10.01
        c-1.96-2.94-4.83-5.35-8.35-6.74l-2.06-0.81l1.9-4.8c1.17-2.94,1.73-6.01,1.72-9.02c0-4.89-1.47-9.62-4.13-13.63
        c-2.66-4-6.57-7.28-11.36-9.17l0,0l-90.5-35.81c-2.94-1.17-6.01-1.73-9.02-1.73c-4.89,0-9.62,1.47-13.63,4.13
        c-4,2.66-7.28,6.57-9.17,11.37l-1.9,4.8l-2.05-0.81c-2.16-0.85-4.42-1.27-6.63-1.27c-3.6,0-7.07,1.08-10.01,3.04
        c-2.94,1.96-5.35,4.84-6.74,8.35l-17.34,43.83l-4.27-4.76c-2.63-2.93-5.45-5.74-8.52-8.35c-10.62-9.02-22.82-16.26-36.12-21.24
        c-13.3-4.98-27.71-7.71-42.73-7.71h-3.29h-67.27h-3.61c-16.81,0-32.86,3.41-47.44,9.58C60.01,202.34,41.41,217.79,28.27,237.23
        L28.27,237.23z"></path>
                                                            <path d="M229.82,112.88c-5.22,12.34-13.95,22.86-24.94,30.28c-10.99,7.42-24.19,11.75-38.47,11.75c-9.52,0-18.55-1.93-26.78-5.4
        c-12.34-5.22-22.86-13.95-30.28-24.94c-7.42-10.99-11.75-24.19-11.75-38.47c0-9.52,1.93-18.55,5.4-26.78
        c5.22-12.34,13.95-22.86,24.94-30.28c10.99-7.42,24.19-11.75,38.47-11.75c9.52,0,18.55,1.93,26.78,5.4
        c12.34,5.22,22.86,13.95,30.28,24.94c7.42,10.99,11.75,24.19,11.75,38.47C235.23,95.61,233.3,104.65,229.82,112.88L229.82,112.88z
         M212.47,17.81c-13.14-8.88-29.02-14.07-46.05-14.07c-11.35,0-22.2,2.3-32.06,6.48c-14.79,6.26-27.35,16.69-36.23,29.83
        C89.25,53.18,84.06,69.06,84.06,86.1c0,11.35,2.3,22.2,6.48,32.06c6.26,14.79,16.69,27.35,29.83,36.23
        c13.14,8.88,29.02,14.07,46.05,14.07c11.35,0,22.2-2.3,32.06-6.48c14.79-6.26,27.35-16.69,36.23-29.83
        c8.88-13.14,14.07-29.02,14.07-46.05c0-11.35-2.3-22.2-6.48-32.06C236.05,39.25,225.61,26.68,212.47,17.81L212.47,17.81z"></path>
                                                            <path d="M360.41,183.78c3.85,1.52,8.19-0.36,9.71-4.2c1.52-3.85-0.36-8.2-4.21-9.71c-3.84-1.52-8.19,0.36-9.71,4.21
        C354.68,177.91,356.57,182.26,360.41,183.78L360.41,183.78z"></path>
                                                            <path d="M451.13,219.36c3.84,1.52,8.19-0.36,9.71-4.21c1.52-3.84-0.36-8.19-4.2-9.72c-3.85-1.52-8.2,0.37-9.72,4.21
        C445.39,213.49,447.28,217.83,451.13,219.36L451.13,219.36z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>One Flat Fee Transparent Package</h5>
                                                <p>New to the Industry or looking for something better, Build a Business with our Support</p>
                                                <Link className="cs-btn MuiButton-contained MuiButton-containedPrimary" to="/join-us/packages">
                                                    Join Us Today
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section> */}

            {/* <section className='about-rateshop'>
                <Container>
                    
                    <video  autoPlay loop muted>
                        <source src={rateshop2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> 
                </Container>
            </section> */}
            {/* join in 3 steps start */}
            <section className="leader-in-sec join-3-steps dd-none">
                <Container>
                    <div className="leader-in_content">
                        <Grid container>
                            <Grid xs={12} sm={12} md={12} lg={5} xl={6} item>
                                <div className="item_card cs-left">
                                    <div class="heading"><h2><span>Make</span> Your Move In Just 3 Steps</h2></div>
                                    <ul>
                                        <li>Talk to ur BD team! Switch for the Right Reasons</li>
                                        <li>Complete your Hiring Invite Online*</li>
                                        <li>Get Approved & Activated within 24 hours</li>
                                    </ul>

                                    <Link to="tel:6472096615" className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                        Talk To Us
                                    </Link>
                                </div>
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} item>
                                <div className="item_card cs-right">
                                    <ul className="d-flex ">
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 435 525"    >
                                                        <g>
                                                            <path d="M390.18,428.39l5.34,2.88c1.42,0.77,2.82,1.6,4.14,2.47c2.81,1.81,5.29,3.78,7.38,5.87c3.53,3.54,5.31,7.75,5.31,12.53
        c0,6.69-1.73,12.06-5.13,15.95c-3.2,3.67-7.28,6.49-12.14,8.35l-4.91,1.88V428.39L390.18,428.39z M376.48,404.77l-5.78-4.3
        c-2.31-1.71-4.25-3.72-5.79-5.94c-1.75-2.53-2.64-5.87-2.64-9.91c0-2.87,0.77-5.87,2.35-9.16c1.54-3.2,3.54-5.82,5.92-7.82
        l5.94-4.95V404.77L376.48,404.77z M395.25,367.86c1.97,0.88,3.66,2.01,5,3.37c1.26,1.26,2.23,2.72,2.87,4.33
        c1.01,2.5,1.92,5,2.7,7.46c0.62,1.93,1.46,3.57,2.56,5.03c0.58,0.76,2.16,1.19,4.34,1.19c2.35,0,4.17-0.76,5.74-2.39
        c1.59-1.66,2.36-3.68,2.36-6.22c0-3.78-0.73-7.33-2.18-10.59c-1.48-3.36-3.49-6.35-6-8.92c-2.61-2.66-5.63-4.92-8.96-6.7
        c-2.31-1.23-4.68-2.22-7.04-2.94c-1.21-0.38-2.39-0.7-3.59-0.95l-2.88-0.6v-5.19c0-0.38-0.01-0.74-0.03-1.1
        c-0.32-6.01-3.05-7.23-7.05-7.23c-3.72,0-6.26,1.22-6.57,7.19c-0.03,0.4-0.04,0.77-0.04,1.14v5.06l-2.73,0.69
        c-3.63,0.92-7.06,2.37-10.18,4.3c-3.19,1.97-6.05,4.42-8.52,7.27c-2.45,2.83-4.42,6.16-5.85,9.89c-1.39,3.7-2.1,7.97-2.1,12.65
        c0,4.74,0.68,8.97,2.01,12.58c1.33,3.65,3.23,6.98,5.63,9.9c2.43,2.97,5.32,5.64,8.62,7.95c0.49,0.34,1.03,0.7,1.56,1.06
        c2.98,1.96,6.18,3.76,9.51,5.35l2.05,0.98v55.7l-4.96-1.98c-9.35-3.73-15.51-10.47-18.3-20.03c-1.27-4.65-3.66-6.61-8.01-6.61
        c-1.97,0-3.81,0.74-5.63,2.26c-1.06,0.88-2.44,2.48-2.44,6.34c0,3.72,0.88,7.47,2.63,11.14c0.59,1.21,1.18,2.29,1.8,3.29
        c1.54,2.51,3.45,4.92,5.71,7.19c3.23,3.22,7.15,6.07,11.6,8.45c4.49,2.4,9.45,4.17,14.72,5.25l2.89,0.59v7.93
        c0,5,1.85,6.86,6.85,6.86c4.98,0,6.83-1.86,6.83-6.86v-7.8l3.03-0.51c4.59-0.76,9.05-2.23,13.28-4.38
        c4.16-2.12,7.89-4.93,11.09-8.35c3.15-3.36,5.71-7.54,7.61-12.42c1.91-4.88,2.88-10.55,2.88-16.87c0-4.96-0.9-9.5-2.68-13.47
        c-0.3-0.66-0.6-1.3-0.93-1.92c-1.72-3.35-3.9-6.42-6.49-9.13c-3.2-3.36-7.03-6.43-11.37-9.13c-3.29-2.07-6.81-4.03-10.47-5.82
        c-1.48-0.72-2.69-1.29-3.8-1.79l-2.14-0.96V365.6L395.25,367.86L395.25,367.86z"></path>
                                                            <path d="M75.91,246.11c-7.65,11.31-12.12,24.99-12.12,39.65v35.21c0,2.92,1.77,5.57,4.47,6.69c2.7,1.12,5.82,0.49,7.89-1.58
        l16.32-16.4l157.5,158.28h-51.48v-6.2c0-3.99-3.24-7.23-7.23-7.23s-7.23,3.24-7.23,7.23l0,0v13.43c0,1.9,0.77,3.77,2.12,5.11
        c1.35,1.35,3.21,2.12,5.11,2.12h111.28c3.99,0,7.23-3.24,7.23-7.23c0-3.99-3.24-7.23-7.23-7.23h-32.15L97.6,294.32
        c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13l-9.09,9.13v-17.7c0-7.81,1.58-15.22,4.43-21.96
        c4.28-10.12,11.44-18.75,20.46-24.83c9.02-6.08,19.84-9.63,31.55-9.63h18.4l51.62,89.39c1.5,2.59,4.45,4,7.4,3.53
        c2.95-0.47,5.32-2.73,5.94-5.65l9.52-45.26c0.37-1.75,0.08-3.55-0.81-5.11l-11.7-20.26l9.61-16.64l19.22,0l9.61,16.64l-11.7,20.26
        c-0.89,1.55-1.18,3.36-0.81,5.11l9.2,43.59c0.62,2.93,2.99,5.18,5.94,5.65c2.95,0.47,5.9-0.94,7.4-3.53l50.65-87.72h18.4
        c7.81,0,15.22,1.58,21.97,4.43c10.12,4.28,18.75,11.44,24.84,20.45c6.09,9.02,9.63,19.83,9.64,31.55v32.17
        c0,3.99,3.24,7.23,7.23,7.23c3.99,0,7.23-3.24,7.23-7.23l0,0v-32.17c0-9.77-1.98-19.11-5.58-27.6
        c-5.39-12.73-14.37-23.54-25.68-31.18c-11.31-7.64-24.99-12.11-39.65-12.11h-22.58c-2.58,0-4.97,1.38-6.26,3.62l-43.29,74.99
        l-4.73-22.4l12.4-21.48c1.29-2.24,1.29-4.99,0-7.23l-13.78-23.87c-1.29-2.24-3.68-3.62-6.26-3.62H220.5
        c-2.58,0-4.97,1.38-6.26,3.62l-13.78,23.87c-1.29,2.24-1.29,4.99,0,7.23l12.4,21.48l-5.06,24.06l-44.26-76.64
        c-1.29-2.24-3.68-3.62-6.26-3.62H134.7c-9.77,0-19.11,1.98-27.6,5.57C94.36,225.82,83.55,234.8,75.91,246.11L75.91,246.11z"></path>
                                                            <path d="M282.95,128.59c-3.9-2.5-7.95-5.74-11.81-9.6c-4.94-4.94-8.86-10.2-11.52-15.05c-1.33-2.42-2.34-4.74-3.03-6.83
        c-0.69-2.09-1.04-3.94-1.12-5.4c-0.18-3.25-2.53-5.99-5.72-6.67c-5.31-1.13-10.72-1.7-16.12-1.7c-13.36,0-26.73,3.45-38.7,10.37
        l0,0h0c-23.96,13.83-38.71,39.39-38.71,67.05c0,3.09,0.2,6.15,0.56,9.17c-11.44-15.88-17.9-35.18-17.9-55.41
        c0-33.86,18.06-65.15,47.39-82.07l0,0c0,0,0,0,0,0c14.73-8.5,31.18-12.7,47.66-12.7c16.25,0,32.51,4.27,47.11,12.69c0,0,0,0,0,0
        c29.32,16.93,47.39,48.22,47.39,82.07c0,20.23-6.46,39.53-17.9,55.41c0.36-3.02,0.56-6.08,0.56-9.17c0-7.18-1-14.22-2.89-20.95
        c-0.97-3.45-4.33-5.67-7.88-5.22c-0.36,0.05-0.76,0.07-1.2,0.07c-1.38,0-3.21-0.27-5.32-0.9
        C290.61,132.85,286.85,131.09,282.95,128.59L282.95,128.59z M287.63,193.18c-1.77,2.96-1.22,6.73,1.32,9.06
        c2.55,2.33,6.36,2.53,9.14,0.5c27.95-20.42,44.79-53.1,44.79-88.19c0-39.02-20.82-75.08-54.61-94.6c0,0,0,0-0.01,0
        c-16.8-9.7-35.56-14.64-54.34-14.63c-18.92,0-37.89,4.82-54.89,14.63c0,0-0.01,0-0.01,0c-33.8,19.51-54.62,55.57-54.62,94.6
        c0,35.09,16.83,67.76,44.79,88.19c2.79,2.03,6.6,1.83,9.14-0.5c2.55-2.33,3.1-6.1,1.32-9.06c-5.78-9.63-8.98-20.78-8.98-32.4
        c0-22.49,12-43.28,31.48-54.53c0,0,0,0,0,0c9.74-5.62,20.6-8.43,31.47-8.43c2.77,0,5.54,0.18,8.29,0.55
        c0.26,1.09,0.57,2.18,0.93,3.27c1.51,4.59,3.85,9.27,6.89,13.92c3.04,4.65,6.79,9.27,11.18,13.66c6.1,6.09,12.63,10.95,19.09,14.39
        c3.23,1.72,6.45,3.08,9.63,4.03c1.96,0.58,3.9,1.02,5.84,1.26c0.75,3.87,1.14,7.84,1.14,11.87
        C296.61,172.39,293.41,183.54,287.63,193.18L287.63,193.18z"></path>
                                                            <path d="M124.65,469.52h-11.72v-11.7h11.72V469.52L124.65,469.52z M101.98,482.73c1.23,0.8,2.71,1.26,4.24,1.26h25.13
        c2.04,0,3.98-0.81,5.4-2.18c0.71-0.69,1.3-1.53,1.72-2.48c0.41-0.95,0.64-2.02,0.64-3.08V451.1c0-1.07-0.23-2.14-0.64-3.09
        c-0.62-1.43-1.64-2.59-2.87-3.4c-1.23-0.8-2.71-1.26-4.24-1.26h-25.13c-2.04,0-3.98,0.8-5.39,2.18c-0.71,0.69-1.3,1.52-1.72,2.48
        c-0.42,0.95-0.65,2.02-0.64,3.09v25.15c0,1.07,0.23,2.14,0.64,3.09C99.73,480.76,100.75,481.92,101.98,482.73L101.98,482.73z"></path>
                                                            <path d="M76.15,379.07l16.32-16.39l60.91,61.21c-1.73,0.12-3.39,0.86-4.62,2.09c-1.35,1.35-2.12,3.21-2.12,5.11v50.82v23.3H88.95
        c-0.05-0.41-0.1-0.81-0.15-1.17c-0.07-0.52-0.12-0.97-0.16-1.27c0-0.03-0.01-0.05-0.01-0.08V478.7v-27.6c0-1.02-0.2-2.05-0.6-2.98
        c-0.59-1.41-1.58-2.62-2.84-3.45c-1.25-0.84-2.78-1.31-4.31-1.3h-2.63h-22.5c-2.04,0-3.98,0.8-5.39,2.18
        c-0.71,0.69-1.3,1.52-1.72,2.48c-0.42,0.95-0.65,2.02-0.64,3.09v54.1h-9.69v-74.11c0-1.9-0.77-3.77-2.12-5.11
        c-1.23-1.23-2.89-1.97-4.63-2.09L76.15,379.07L76.15,379.07z M62.46,457.82h11.7v20.88v24.37c0,0.29,0.01,0.4,0.02,0.49
        c0.01,0.17,0.02,0.24,0.03,0.32c0.01,0.13,0.03,0.23,0.04,0.35c0.03,0.25,0.07,0.58,0.12,0.96H62.46V457.82L62.46,457.82z
         M7.51,433.86c1.12,2.7,3.75,4.46,6.68,4.46h9.65v74.11c0,1.9,0.77,3.77,2.12,5.11c1.35,1.35,3.21,2.12,5.11,2.12h23.45
        c0.08,0.01,0.15,0.01,0.21,0.01c0.21,0.01,0.31,0.01,0.38,0.01c0.07,0,0.1,0,0.12,0h0.01l27.14-0.03h71.48
        c1.9,0,3.77-0.77,5.11-2.12c1.35-1.35,2.12-3.21,2.12-5.11V481.9v-43.58l9.65,0c2.92,0,5.56-1.76,6.68-4.46
        c1.12-2.7,0.51-5.8-1.55-7.88L97.6,347.32c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13L65.9,368.86L9.06,425.98
        C7,428.06,6.39,431.16,7.51,433.86L7.51,433.86z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>Book a Confidential Consultation</h5>
                                                <p>Discuss your Future with us online, over the phone, or in person.</p>
                                                <Link onClick={handleClickOpen} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                                    Schedule a Meeting
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 495 448"  >
                                                        <g>
                                                            <path d="M347.16,232.88l78.74,31.16c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-78.74-31.16
        c-3.48-1.38-7.42,0.33-8.79,3.81C341.98,227.57,343.68,231.51,347.16,232.88L347.16,232.88z"></path>
                                                            <path d="M379.19,191.08l51.91,20.54c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-51.91-20.54
        c-3.48-1.38-7.42,0.33-8.79,3.81C374,185.76,375.71,189.7,379.19,191.08L379.19,191.08z"></path>
                                                            <path d="M309.14,263.57l123.49,48.87c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-123.49-48.87
        c-3.48-1.38-7.41,0.33-8.79,3.81C303.96,258.26,305.66,262.2,309.14,263.57L309.14,263.57z"></path>
                                                            <path d="M291.57,307.97L291.57,307.97l24.54,9.71l98.95,39.15c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-98.95-39.15l-24.54-9.71c-3.48-1.38-7.42,0.33-8.79,3.81C286.39,302.66,288.09,306.6,291.57,307.97L291.57,307.97z"></path>
                                                            <path d="M300.19,286.19L300.19,286.19l14.22,5.63l0,0l109.27,43.24c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-109.27-43.24l0,0l-14.22-5.63c-3.48-1.38-7.42,0.33-8.79,3.81C295,280.88,296.71,284.81,300.19,286.19L300.19,286.19z"></path>
                                                            <path d="M68.77,215.55c17.3-11.68,38.1-18.49,60.56-18.5h3.61h19.03l-3.43,2.13l0,0l-5.05,3.13c-2.73,1.69-3.9,5.07-2.79,8.08
        l3.17,8.66l3.91,16.77l-0.88,10.2v0l-12.48,143.96c-0.19,2.14,0.64,4.22,2.25,5.65l24.79,22.04c2.55,2.27,6.37,2.29,8.94,0.05
        l25.11-21.81c1.62-1.41,2.48-3.49,2.31-5.64l-11.17-144l-0.8-10.27l4.05-16.71l3.27-8.5c1.15-3,0.03-6.4-2.67-8.13l-5.12-3.28
        l-3.61-2.31h18.45h3.29c13.38,0,26.16,2.42,37.98,6.85c11.81,4.42,22.65,10.85,32.1,18.88c2.53,2.15,4.93,4.52,7.21,7.06l8.79,9.8
        l-45.67,115.42l0,0c-0.85,2.15-1.26,4.41-1.26,6.61c0,3.6,1.08,7.07,3.03,10.01c1.95,2.94,4.83,5.36,8.35,6.74l0,0l57.87,22.9
        c-0.04,0.3-0.07,0.61-0.07,0.92l0,0v27.74H21.01v-124.6c0-14.97,3.03-29.21,8.51-42.16C37.73,243.79,51.48,227.23,68.77,215.55
        L68.77,215.55z M156.96,215.58c-0.06-0.28-0.14-0.52-0.23-0.79l-1.41-3.87l0.36-0.22l0,0l11.32-7.01l11.08,7.09l0.47,0.3
        l-1.44,3.76c-0.1,0.26-0.19,0.56-0.26,0.83l-3.15,12.98l-13.7-0.06L156.96,215.58L156.96,215.58z M166,403.58l-17.8-15.82
        l12.18-140.57l0,0l0.44-5.05l11.92,0.05l0.4,5.12l10.91,140.6L166,403.58L166,403.58z M325.1,186.71c0.36-0.9,0.93-1.57,1.66-2.07
        c0.73-0.49,1.61-0.76,2.48-0.76c0.54,0,1.08,0.1,1.64,0.32l8.35,3.31c1.66,0.66,3.55,0.63,5.18-0.08c1.64-0.71,2.95-2.07,3.61-3.72
        l4.39-11.1c0.86-2.17,2.3-3.88,4.09-5.08c1.79-1.2,3.93-1.86,6.11-1.86c1.34,0,2.69,0.24,4.03,0.78l90.5,35.81l2.49-6.3l-2.49,6.3
        c2.17,0.86,3.87,2.3,5.08,4.09c1.2,1.79,1.86,3.93,1.86,6.11c0,1.34-0.24,2.69-0.77,4.03l-4.39,11.1
        c-1.38,3.48,0.33,7.41,3.81,8.79l8.35,3.31c0.89,0.36,1.57,0.93,2.06,1.66c0.49,0.73,0.76,1.61,0.76,2.49
        c0,0.54-0.1,1.08-0.32,1.63v0l-68.59,173.32c-0.36,0.9-0.93,1.57-1.66,2.07c-0.73,0.49-1.61,0.76-2.48,0.76
        c-0.54,0-1.08-0.1-1.64-0.32l-78.12-30.91l-62.08-24.57l0,0c-0.89-0.36-1.56-0.92-2.06-1.66c-0.49-0.73-0.76-1.61-0.76-2.49
        c0-0.54,0.1-1.08,0.31-1.63l0,0l47.06-118.93L325.1,186.71L325.1,186.71z M28.27,237.23c-13.14,19.44-20.82,42.93-20.82,68.15
        v131.37c0,1.78,0.72,3.53,1.98,4.79c1.26,1.26,3.01,1.98,4.79,1.98h304.38c1.78,0,3.53-0.72,4.79-1.98
        c1.26-1.26,1.98-3.01,1.98-4.79v-30.1l68.85,27.25c2.16,0.85,4.41,1.27,6.62,1.27c3.6,0,7.07-1.08,10.01-3.04
        c2.94-1.96,5.35-4.83,6.74-8.35l68.59-173.32v0c0.85-2.16,1.27-4.41,1.27-6.62c0-3.6-1.08-7.07-3.04-10.01
        c-1.96-2.94-4.83-5.35-8.35-6.74l-2.06-0.81l1.9-4.8c1.17-2.94,1.73-6.01,1.72-9.02c0-4.89-1.47-9.62-4.13-13.63
        c-2.66-4-6.57-7.28-11.36-9.17l0,0l-90.5-35.81c-2.94-1.17-6.01-1.73-9.02-1.73c-4.89,0-9.62,1.47-13.63,4.13
        c-4,2.66-7.28,6.57-9.17,11.37l-1.9,4.8l-2.05-0.81c-2.16-0.85-4.42-1.27-6.63-1.27c-3.6,0-7.07,1.08-10.01,3.04
        c-2.94,1.96-5.35,4.84-6.74,8.35l-17.34,43.83l-4.27-4.76c-2.63-2.93-5.45-5.74-8.52-8.35c-10.62-9.02-22.82-16.26-36.12-21.24
        c-13.3-4.98-27.71-7.71-42.73-7.71h-3.29h-67.27h-3.61c-16.81,0-32.86,3.41-47.44,9.58C60.01,202.34,41.41,217.79,28.27,237.23
        L28.27,237.23z"></path>
                                                            <path d="M229.82,112.88c-5.22,12.34-13.95,22.86-24.94,30.28c-10.99,7.42-24.19,11.75-38.47,11.75c-9.52,0-18.55-1.93-26.78-5.4
        c-12.34-5.22-22.86-13.95-30.28-24.94c-7.42-10.99-11.75-24.19-11.75-38.47c0-9.52,1.93-18.55,5.4-26.78
        c5.22-12.34,13.95-22.86,24.94-30.28c10.99-7.42,24.19-11.75,38.47-11.75c9.52,0,18.55,1.93,26.78,5.4
        c12.34,5.22,22.86,13.95,30.28,24.94c7.42,10.99,11.75,24.19,11.75,38.47C235.23,95.61,233.3,104.65,229.82,112.88L229.82,112.88z
         M212.47,17.81c-13.14-8.88-29.02-14.07-46.05-14.07c-11.35,0-22.2,2.3-32.06,6.48c-14.79,6.26-27.35,16.69-36.23,29.83
        C89.25,53.18,84.06,69.06,84.06,86.1c0,11.35,2.3,22.2,6.48,32.06c6.26,14.79,16.69,27.35,29.83,36.23
        c13.14,8.88,29.02,14.07,46.05,14.07c11.35,0,22.2-2.3,32.06-6.48c14.79-6.26,27.35-16.69,36.23-29.83
        c8.88-13.14,14.07-29.02,14.07-46.05c0-11.35-2.3-22.2-6.48-32.06C236.05,39.25,225.61,26.68,212.47,17.81L212.47,17.81z"></path>
                                                            <path d="M360.41,183.78c3.85,1.52,8.19-0.36,9.71-4.2c1.52-3.85-0.36-8.2-4.21-9.71c-3.84-1.52-8.19,0.36-9.71,4.21
        C354.68,177.91,356.57,182.26,360.41,183.78L360.41,183.78z"></path>
                                                            <path d="M451.13,219.36c3.84,1.52,8.19-0.36,9.71-4.21c1.52-3.84-0.36-8.19-4.2-9.72c-3.85-1.52-8.2,0.37-9.72,4.21
        C445.39,213.49,447.28,217.83,451.13,219.36L451.13,219.36z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>Start Mortgage Broker Registration</h5>
                                                <p>New to the Industry or looking for something better, Build a Business with our Support</p>


                                                <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                                    Start Now
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            {/* marketing-share start */}
            <section className="marketing-share dd-none">
                <Container>
                    <div className="Marketing-solution-content">
                        <div class="heading text-center"><h2><span>Do you</span> Feel the Market share tipping your way?</h2></div>

                        <figure>
                            <img src={outstandingMortgage} alt="" className='roung-cs-img'></img>
                        </figure>
                    </div>
                </Container>
            </section>
            <section className="build-business advantages full-991 pd-80">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid xs={12} sm={12} md={6} lg={6} item >
                            <img src={img55} alt='' className='roung-cs-img mb-30' />
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <div class="heading cmb-30"><h2><span>What’s  </span>different about RateShop  </h2></div>
                            <ul>
                                <li>Competitive Compensation: RateShop Mortgage offers a generous payout structure of up to 90%, providing significant earning potential for Mortgage Loan Originators and Mortgage Loan Officers. </li>
                                <li>Access to Multiple Lenders: With over 100+ lenders in their network, RateShop Mortgage LLC provides access to a wide range of options for borrowers, increasing the likelihood of finding the best mortgage solutions for clients.</li>
                                <li>Specialty Mortgage Loan Programs: RateShop offers all specialty mortgage loan programs, ensuring that Mortgage Loan Originators can cater to diverse client needs, including FHA, VA, USDA, Non-QM, and more.  </li>
                                <li>Par Rates: Access to par rates ensures competitive pricing for borrowers, enhancing the attractiveness of the mortgage offerings.</li>
                                <li>Zoom Training and Support: The company provides comprehensive training and support through Zoom screen share sessions, Regular training ensures that Mortgage Loan Originators are equipped with the knowledge and skills needed to excel in their roles. </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            {/* Build a business */}
            <section className="build-business advantages bg-grey full-991">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid xs={12} sm={12} item>
                            <div class="heading text-center mb-40"><h2><span>The RateShop </span> Advantage </h2></div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item className='text-block'>
                            <ul>
                                <li>Access to 100+ Specialty Wholesale Lenders: This includes a variety of options such as FHA, VA, Conventional, Non-QM, and commercial lenders. </li>
                                <li>FULL CRM Provided: Access to a comprehensive Customer Relationship Management system.</li>
                                <li>Free LOS (Loan Origination System): Access to a Loan Origination System at no cost. </li>
                                <li>Full-Time Processor Provided: Support from a full-time processor at no additional cost.</li>
                                <li>Pull Your Own Credit Reports: Capability to access and pull credit reports. </li>

                            </ul>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item >
                            <img src={img21} alt='' className='roung-cs-img' />
                        </Grid>
                    </Grid>
                    <Grid container className="align-items-center mob-reverse">
                        <Grid xs={12} sm={12} md={6} lg={6} item >
                            <img src={img22} alt='' className='roung-cs-img' />
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item className='text-block'>
                            <ul>
                                <li>Par Rates from Top Wholesale Lenders with NO Add-ons: Access to competitive rates without additional mark-ups.</li>
                                <li>Credit Score Approvals Down to 500: Ability to secure approvals for borrowers with credit scores as low as 500. </li>
                                <li>Same-Day Underwriting Approvals: Expedited underwriting process for faster approvals. </li>
                                <li>Discount Mortgage Insurance Premiums: Access to discounted premiums for mortgage insurance.</li>
                                <li>Newly Licensed Loan Officer Training: Training programs specifically designed for newly licensed loan officers to facilitate their development and success in the role. </li>
                            </ul>
                        </Grid>
                        <Grid xs={12}item>
                    <div  className='mt-30 d-flex justify-content-center' >
                    <Link onClick={handleClickOpen1} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
    {/* New */}
                                    Schedule a Meeting 
                                </Link>
                                </div></Grid>
                    </Grid>
                </Container>
            </section>



            {/* marketing solution start */}
           
            {/* <section className="build-business advantages need-to-succed">
                <Container>
                    <Grid container className="align-items-center">
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <div class="heading"><h2><span>What   </span> you need to succeed as a Mortgage Loan Originator</h2></div>
                            <ul class="cs-font-20">
                                <li>Ongoing Training & One-on-One Coaching  </li>
                                <li>Dedicated Underwriting Support </li>
                                <li>Agent Resources & technology   </li>
                                <li>Complete Marketing Solution</li>
                                <li>Lender Training & Webinars  </li>
                            </ul>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item >
                            <img src={advantages} alt='' className='roung-cs-img'></img>
                        </Grid>
                    </Grid>
                </Container>
            </section> */}

            {/* <section className='advantages-img'>
                <Container>
                    <img src={advantages} alt=''></img>
                </Container>
            </section> */}

            {/* straight-talk start */}
            {/* <section className="straight-talk">
                <Container>
                    <div className="straight-talk-content">
                        <div class="heading top-heading"><h2><span>Straight</span>  Talk ! </h2></div>
                        <ul>
                            <li>Bank on Value, not just discounts. </li>
                            <li>Learn how o become a resource for your client rather than just a rate finder.</li>
                            <li>Clients want solution, education and guidance.</li>
                        </ul>
                        <Link onClick={handleClickOpen} className="cs-btn MuiButton-contained MuiButton-containedPrimary">Book a Call!</Link>
 
                    </div>
                </Container>
                {/* <div className="straight-talk-scroller">
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                            },
                            576: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            992: {
                                slidesPerView: 5,
                            },
                            1200: {
                                slidesPerView: 6,
                            },
                            1300: {
                                slidesPerView: 7,
                            },
                        }}
                        spaceBetween={50}
                        loop={true}
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: true,
                        }}
                        speed={2000}
                        simulateTouch={false}
                        modules={[Autoplay]}
                        allowTouchMove={false}
                    >
                        {partnerLogos2.map((item) =>
                            <SwiperSlide className="item">
                                <figure>
                                    <img src={item.img} alt="partner" />
                                </figure>
                            </SwiperSlide>
                        )}
                        {partnerLogos2.map((item) =>
                            <SwiperSlide className="item">
                                <figure>
                                    <img src={item.img} alt="partner" />
                                </figure>
                            </SwiperSlide>
                        )}
                    </Swiper>

                </div>  
            </section> */}
            {/* fund across */}
            {/* <section className="fund-across join-now-included download-pdf">
                <Grid container className="align-items-center">
                    <Grid xs={12} sm={12} md={6} lg={6} item className="right-areas-content">
                        <Grid container>
                            <Grid xs={12} sm={12} item>
                                <div class="heading text-center"><h2><span> What you </span> need to know about becoming a Mortgage Loan Originator </h2></div>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                <a href='https://mortgage.nationwidelicensingsystem.org/slr/publishedstatedocuments/fl-mlo-new-app-checklist.pdf' target='_blank'>
                                    <img src={pdf} alt=""></img>
                                    <span>Florida Mortgage Loan Originator License Requirments Checklist </span>
                                </a>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                <a href='https://mortgage.nationwidelicensingsystem.org/slr/publishedstatedocuments/ga-mlo-new-application-checklist.pdf' target='_blank'>
                                    <img src={pdf} alt=""></img>
                                    <span>Georgia Mortgage Loan Originator License Requirments Checklis</span>
                                </a>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                <a href='https://mortgage.nationwidelicensingsystem.org/slr/PublishedStateDocuments/Texas_SML_Individual-New-App-Checklist.pdf' target='_blank'>
                                    <img src={pdf} alt=""></img>
                                    <span>Texas Mortgage Loan Originator License Requirments Checklist </span>
                                </a>
                            </Grid>
                            <Grid xs={12} sm={12} item className='justify-content-center d-flex'>
                                <Link className="cs-btn MuiButton-contained MuiButton-containedPrimary w-auto" to="/join-us/packages">
                                    Join Us Today
                                </Link></Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={12} sm={12} md={6} lg={6} item>
                        <img src={flag2} alt="" className='roung-cs-img'></img>
                    </Grid>
                </Grid> */}
            {/* </section> */}
            {/* join now included */}
            {/* <section className="build-business join-now-included">
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={12} item>
                            <div class="heading text-center"><h2><span>A diverse Mortgage</span>  Product Offering </h2></div>
                            <ul className='product-offering-list'>
                                <li>
                                    <img src={logo1} alt=''></img>
                                    <p><NavLink to="/mortgage-program/fha-loans">100% FHA Loan:</NavLink>
                                        <span>Minimum 580 credit score.   </span>
                                        <span>Second mortgage for 3.5% down payment.</span>
                                        <span>Must be approved by Automated Underwriting System (AUS). </span>
                                    </p> </li>
                                <li>
                                    <img src={logo2} alt=''></img>
                                    <p><NavLink to="/">1% Down Payment Program:</NavLink>
                                        <span>Up to $200k loan amount.</span>
                                        <span>Lender pays 2% toward the 3% down payment.</span>
                                    </p> </li>
                                <li>
                                    <img src={logo3} alt=''></img>
                                    <p><NavLink to="/">Home Ready SCPC Program:</NavLink>
                                        <span>3% down payment.</span>
                                        <span>$5,000 grant, $500 appraisal credit, $500 home warranty.</span>
                                    </p> </li>
                                <li>
                                    <img src={logo4} alt=''></img>
                                    <p><NavLink to="/mortgage-program/bank-statement-loans">Bank Statement Programs:</NavLink>
                                        <span>3, 12, and 24-month bank statement programs. </span>
                                        <span>Bank statement only loans for self-employed individuals, no tax returns needed, up to 90% loan-to-value (LTV). </span>

                                    </p> </li>
                                <li>
                                    <img src={logo5} alt=''></img>
                                    <p><NavLink to="/">Credit Score Flexibility</NavLink>
                                        <span>FHA, VA loans available down to 500 credit score with minimum 10% down payment. </span>
                                        <span>No credit score FHA, VA loans using rental history, utility bills, car insurance, etc. </span>
                                    </p> </li>
                                <li>
                                    <img src={logo6} alt=''></img>
                                    <p><NavLink to="/mortgage-program/va-loans">Specialized Programs:</NavLink>
                                        <span>Manual underwriting for FHA/VA loans. </span>
                                        <span>Debt-Service Coverage Ratio (DSCR) loans using rental income or Airbnb. </span>
                                        <span>No prepayment penalty on DSCR loans, cash-out up to 75%, minimum 620 FICO score. </span>
                                    </p> </li>
                                <li>
                                    <img src={logo7} alt=''></img>
                                    <p><NavLink to="/">Alternative Income Documentation: </NavLink>
                                        <span>No income verification primary loans with 20% down payment, minimum 680 credit score. </span>
                                        <span>No income verification primary loans with 20% down payment, minimum 680 credit score. </span>
                                    </p> </li>
                                <li>
                                    <img src={logo9} alt=''></img>
                                    <p><NavLink to="/mortgage-program/conventional">Fast Approvals and Closings: </NavLink>
                                        <span>24/48-hour approvals with an average 15-21 day closing time for FHA, VA, and conventional loans. </span>
                                        <span>Access to commercial lenders for specialized financing needs.</span>
                                        <span>Options for reverse mortgages and super jumbo loans. </span>
                                    </p> </li>

                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </section> */}


            <section className='tab-sec pt-80'>
                {/* <Container> */}
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} className='cs-tabs-join' aria-label="tabs">
                            <Tab label="Florida" {...a11yProps(0)} />
                            <Tab label="Georgia" {...a11yProps(1)} />
                            <Tab label="Texas " {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}><Container>
                        <div className='six-reasons'>
                            <Grid container>
                                <Grid xs={12} sm={12} item>
                                    <div class="heading text-center">
                                        <h2><span>Six Top Reasons</span> to Become a Mortgage Broker in Florida, Georgia, and Texas with RateShop Mortgage </h2>
                                    </div>
                                    <p className='text-center'>Are you considering a career as a Mortgage Broker or Mortgage Loan Originator in Florida, Georgia, or Texas? This dynamic and rewarding profession offers numerous benefits, making it an excellent choice for those looking to enter the real estate finance industry. Here are six top reasons to become a mortgage broker in these states, and how RateShop Mortgage can support you in this journey. </p>
                                </Grid>
                                {/* <Grid xs={12} sm={5} item>
                                <img src={topsixreasosn}></img>
                            </Grid> */}
                            </Grid>
                            <ol className='top-six-reasons'>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={highdemand}></img>
                                    </figure><div className='space-x-10 d-flex flex-column'>
                                        <h3>High Demand for Mortgage Brokers</h3>
                                        <p>With the booming real estate markets in Florida, Georgia, and Texas, there is a constant demand for mortgage brokers. Homebuyers need professional guidance to navigate the complex mortgage process. As a mortgage broker, you'll be in a prime position to help clients secure the best loan options, ensuring a steady stream of business opportunities. RateShop Mortgage connects you with a broad network of clients, increasing your visibility and potential client base. </p></div></li>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={lucrativeearning}></img>
                                    </figure><div className='space-x-10  d-flex flex-column'>
                                        <h3>Lucrative Earnings Potential</h3>
                                        <p>Mortgage brokers in the US, especially in high-demand states like Florida, Georgia, and Texas, can earn a substantial income. According to industry data, the salary for mortgage brokers varies, but with dedication and a strong client base, you can achieve significant financial rewards. Your earnings are often commission-based, so the more loans you close, the higher your income. RateShop Mortgage provides competitive commission structures and incentives to maximize your earnings. </p></div></li>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={careerpath}></img>
                                    </figure><div className='space-x-10  d-flex flex-column'>
                                        <h3>Flexible Career Path</h3>
                                        <p>Becoming a mortgage broker offers a flexible career path. Whether you're transitioning from another profession or starting fresh, you can find mortgage broker training programs that fit your schedule. Online mortgage broker courses provide the flexibility to learn at your own pace, making it easier to balance your education with other commitments. RateShop Mortgage offers support and training programs along with ongoing one-on-one coaching to help you succeed. </p></div></li>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={comprehensivetraining}></img>
                                    </figure><div className='space-x-10  d-flex flex-column'>
                                        <h3>Comprehensive Training and Support </h3>
                                        <p>Mortgage broker certification and licensing requirements are well-structured in these states. You can find mortgage broker training programs and certification courses that prepare you thoroughly for the mortgage broker exam. Additionally, continuing education is essential to stay updated with the latest industry trends and regulations, ensuring you remain a competitive professional. RateShop Mortgage provides access to the best mortgage broker schools and resources to keep you informed and compliant. </p></div></li>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={careergrowth}></img>
                                    </figure><div className='space-x-10  d-flex flex-column'>
                                        <h3>Career Growth Opportunities </h3>
                                        <p>The career outlook for mortgage brokers and mortgage loan originators is promising. As the real estate market continues to grow, so does the need for skilled professionals. Steps to become a mortgage broker include gaining experience, building a network, and possibly starting your own mortgage brokerage business. The entrepreneurial aspect of this career can lead to significant growth and success. RateShop Mortgage offers mentorship and business development resources to help you grow your career. </p></div></li>
                                <li className='space-x-15'>
                                    <figure>
                                        <img src={rewardingwork}></img>
                                    </figure><div className='space-x-10  d-flex flex-column'>
                                        <h3>Impactful and Rewarding Work </h3>
                                        <p>Helping clients achieve their dream of homeownership is one of the most rewarding aspects of being a mortgage broker. Your role in securing the best mortgage loan terms for your clients can have a lasting positive impact on their financial well-being. This sense of fulfillment, combined with the potential for a lucrative career, makes becoming a mortgage broker an appealing choice. RateShop Mortgage supports you with tools and resources to deliver exceptional service to your clients. </p></div></li>

                            </ol>
                        </div>
                        {/*  */}
                        <div className='how-rateshop-help'>
                            <Grid container>
                                <Grid xs={12} item className=' text-center'>
                                    <div class="heading">
                                        <h2><span>How RateShop Mortgage</span> Can Help </h2>
                                        <p>RateShop Mortgage is dedicated to helping aspiring mortgage brokers in Florida, Georgia, and Texas succeed. Our comprehensive support includes: </p>
                                    </div>
                                </Grid>

                                <Grid xs={12} lg={6} item>
                                    <ul>
                                        <li>
                                            <figure>
                                                <img src={extensivetraining}></img>
                                            </figure>
                                            <p><strong>Extensive Training Programs:</strong> Access to top mortgage broker training programs and certification courses.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={ten}></img>
                                            </figure><p><strong>Ongoing Education:</strong> Resources for continuing education to keep you updated with industry trends and regulations.</p></li>
                                        <li>
                                            <figure>
                                                <img src={competitivecommission}></img>
                                            </figure><p><strong>Competitive Commission Structures:</strong> Lucrative earnings potential with competitive commission rates and incentives. </p></li>
                                        <li>
                                            <figure>
                                                <img src={mentorship}></img>
                                            </figure><p><strong>Mentorship and Business Development:</strong> Guidance and resources to help you grow and succeed in your career.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={clientnetwork}></img>
                                            </figure><p><strong>Client Network:</strong> Connection to a broad network of clients to enhance your visibility and client base. </p> </li>
                                        <li>
                                            <figure>
                                                <img src={toolsandresources}></img>
                                            </figure><p><strong>Exceptional Tools and Resources:</strong> Tools and resources to provide outstanding service to your clients. </p> </li>
                                    </ul>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <img src={howrateshopcanhelp}></img>
                                </Grid>
                                <Grid xs={12} className='d-flex justify-content-center mt-30'>
                                    {/* new  */}
                            <Link onClick={handleClickOpen1} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                Schedule a Meeting
                            </Link>
                                </Grid>

                            </Grid>
                        </div>
</Container>
                        <section className="work-with">
                <Container>
                    <div class="heading text-center"><h2><span>Why work </span> with some, When you can work with All of them</h2></div>
                </Container>
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                        1300: {
                            slidesPerView: 7,
                        },
                    }}
                    spaceBetween={50}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                    }}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" />
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper> 
            </section>
            <Container>
                        <div className='sales-overview mortgage-broker pt-0'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={salaryoverview}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Salary Overview </h2></div>
                                    <p>Mortgage brokers in the US have the potential to earn a lucrative income, often based on commission structures. The average salary can vary widely depending on the state and the broker's experience level. Here, we analyze mortgage broker salaries in some of the top states for this profession: Florida, Texas, and Georgia. </p>
                                </Grid>
                            </Grid>
                        </div>

                        {/* state */}
                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>High Demand</span> and Competitive Salaries </h2>
                                <p>Florida's real estate market is vibrant, with a constant demand for mortgage brokers. According to industry data, the average mortgage broker salary in Florida ranges from $50,000 to $100,000 annually, with top performers earning even more. Factors influencing this income include the broker's network, the number of loans closed, and the overall real estate market conditions in key cities like Miami and Orlando. </p></div>
                            <p><strong>Steps to Become a Mortgage Broker in Florida: </strong></p>
                            <ul>
                                <li>Complete pre-licensing education through approved mortgage broker training programs.</li>
                                <li>Pass the mortgage broker exam.</li>
                                <li>Obtain the necessary licensing through the Florida Office of Financial Regulation. </li>
                                <li>Engage in continuing education to stay updated with industry trends and regulations. </li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>Key Factors</span> Influencing Mortgage Broker Income </h2>
                                <p>Several factors influence the income and compensation of mortgage brokers across different states: </p>  </div>

                            <ul>
                                <li><strong>Market Demand:</strong> States with booming real estate markets tend to offer higher earning potential for mortgage brokers. </li>
                                <li><strong>Experience and Network:</strong> Experienced brokers with a strong network of clients and real estate professionals typically earn more. </li>
                                <li><strong>Commission Structure:</strong> Mortgage brokers often earn a significant portion of their income through commissions, making the number of loans closed a crucial factor. </li>
                                <li><strong>Education and Certification:</strong> Completing mortgage broker training programs and obtaining the necessary certifications can enhance earning potential. </li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='sales-overview  build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Job Responsibilities </h2></div>
                                    <p>A career as a mortgage broker or mortgage loan originator (MLO) is both rewarding and impactful, blending financial expertise with the ability to help clients achieve their homeownership dreams. Understanding the job responsibilities of a mortgage broker is crucial for those considering this profession. Here, we explore the key duties of a mortgage broker, incorporating essential keywords like mortgage broker certification, training programs, licensing requirements, and more. </p>
                                </Grid>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={brokerresponsibilities}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    <div class="heading pt-30">
                                        <h2> Key Responsibilities  of a Mortgage Broker</h2></div>
                                    <ol className='key-responsibilities'>
                                        <li>
                                            <h3>Client Consultation and Assessment </h3>
                                            <p>One of the primary responsibilities of a mortgage broker is to consult with clients to understand their financial situation, needs, and goals. This involves: </p>
                                            <ul>
                                                <li>Conducting in-depth interviews to gather financial information.     </li>
                                                <li>Assessing credit reports and financial documents. </li>
                                                <li>Advising clients on mortgage products that best suit their needs. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Loan Application and Documentation</h3>
                                            <p>Mortgage brokers and mortgage loan originators play a crucial role in the loan application process. Their duties include: </p>
                                            <ul>
                                                <li>Helping clients complete mortgage loan applications. </li>
                                                <li>Ensuring all required documentation is collected and accurate. </li>
                                                <li>Submitting loan applications to lenders for approval. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Market Research and Analysis </h3>
                                            <p>A successful mortgage broker stays informed about market trends and mortgage products. This involves: </p>
                                            <ul>
                                                <li>Researching different loan products and interest rates. </li>
                                                <li>Analyzing market trends to provide clients with the best mortgage options. </li>
                                                <li>Keeping up-to-date with changes in mortgage broker licensing requirements and regulations. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Liaison Between Clients and Lenders </h3>
                                            <p>Mortgage brokers act as intermediaries between clients and lenders. Their responsibilities include: </p>
                                            <ul>
                                                <li>Communicating with lenders to find the best loan terms and interest rates. </li>
                                                <li>Negotiating loan terms on behalf of clients. </li>
                                                <li>Keeping clients informed throughout the loan approval process. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3> Compliance and Regulatory Adherence </h3>
                                            <p>Ensuring compliance with state and federal regulations is a critical aspect of a mortgage broker's job. This involves: </p>
                                            <ul>
                                                <li>Understanding and adhering to mortgage broker state regulations. </li>
                                                <li>Keeping up with continuing education requirements to maintain mortgage broker certification. </li>
                                                <li>Ensuring all transactions comply with legal and ethical standards. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Building and Maintaining Relationships </h3>
                                            <p>Building strong relationships with clients, lenders, and other real estate professionals is essential for a mortgage broker. This includes: </p>
                                            <ul>
                                                <li>Networking with real estate agents, lenders, and clients. </li>
                                                <li>Providing excellent customer service to build trust and repeat business. </li>
                                                <li>Engaging in professional development through mortgage broker training programs and certification courses. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Marketing and Business Development </h3>
                                            <p>Mortgage brokers often engage in marketing and business development activities to grow their client base. This involves: </p>
                                            <ul>
                                                <li>Developing a business plan and marketing strategy. </li>
                                                <li>Utilizing social media and online platforms to attract clients. </li>
                                                <li>Attending industry events and networking opportunities to build professional connections. </li>
                                            </ul>
                                        </li>

                                    </ol>
                                </Grid>

<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                              Join Now
                          </Link>
                              </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={prerequisites}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Prerequisites</span> to Become a Mortgage Broker in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific prerequisites and following a series of steps. This guide outlines the essential requirements and steps to help you embark on a successful career in this dynamic field. </p>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Prerequisites for Becoming a Mortgage Broker in Florida </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Education Requirements </h3>
                                            <ul>
                                                <li><strong>Pre-Licensing Education:</strong> Complete 20 hours of pre-licensing education through an NMLS-approved mortgage broker training program. This education typically covers federal and state laws, ethics, and mortgage origination. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam </h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test:</strong> This test includes both a national and state-specific component for Florida. Preparing with mortgage broker exam prep courses can significantly enhance your chances of success. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:</strong> Submit fingerprints for a criminal background check. Any criminal history may impact your eligibility. </li>
                                                <li><strong>Credit Report:</strong> A credit report review is required to assess financial responsibility. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration:</strong> Create an account with the Nationwide Multistate Licensing System (NMLS) and submit your application. </li>
                                                <li><strong>Florida State License:</strong> Apply for your mortgage broker license through the Florida Office of Financial Regulation (OFR). </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}
                                </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker Licensing</span> Requirements in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific requirements. This guide outlines the essential steps and prerequisites for obtaining a mortgage broker license, integrating the role of an MLO, and showcasing how RateShop Mortgage can assist you in this process.  </p>
                                </Grid>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={requirements}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Mortgage Broker Licensing Requirements in Florida </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3> Pre-Licensing Education</h3>
                                            <ul>
                                                <li><strong>Complete Pre-Licensing Education:</strong>Aspiring mortgage brokers in Florida must complete 20 hours of NMLS-approved pre-licensing education. This education covers federal and state laws, ethics, and mortgage origination practices.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam</h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test: </strong> This test includes a national component and a state-specific component for Florida. Taking mortgage broker certification courses can significantly improve your chances of passing. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check  </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:  </strong> Submit fingerprints for a thorough criminal background check.  </li>
                                                <li><strong>Credit Report: </strong> A credit report review is required to assess your financial responsibility.  </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration:</strong> Create an account with the Nationwide Multistate Licensing System (NMLS) and submit your application.</li>
                                                <li><strong>Florida State License</strong> Apply for your mortgage broker license through the Florida Office of Financial Regulation (OFR). </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}

                                </Grid>
                              
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60 build-business'>
                            <Grid container  >
                                <Grid xs={12} item>
                                    <div class="heading">
                                        <h2><span>Understanding</span> the Nationwide Multistate Licensing System (NMLS) </h2></div>
                                    <p>The Nationwide Multistate Licensing System (NMLS) is an essential component for anyone aspiring to become a mortgage broker or mortgage loan originator (MLO) in the United States. It serves as the centralized platform for licensing and regulatory information for the financial services industry. This guide provides an in-depth look at what NMLS is, its purpose, and how it impacts the mortgage industry, integrating relevant keywords like mortgage broker certification, training programs, and licensing requirements.  </p>
                                    <div class="heading pt-20">
                                        <h2>What is NMLS?</h2></div>
                                    <p>The NMLS is a secure, web-based system created to streamline the licensing process for mortgage professionals, including mortgage brokers and MLOs. It was developed by the Conference of State Bank Supervisors (CSBS) and the American Association of Residential Mortgage Regulators (AARMR) to enhance regulatory oversight and consumer protection. </p>

                                    <h3 className='pt-20'>Key Functions of NMLS </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Centralized Licensing Platform </h3>
                                            <p>NMLS provides a single platform for managing state licenses for mortgage professionals. It allows users to apply for, renew, and maintain their licenses in one place, ensuring compliance with state-specific regulations.</p>
                                        </li>
                                        <li>
                                            <h3>Uniform Licensing Standards </h3>
                                            <p>By establishing uniform licensing standards, NMLS helps ensure that mortgage brokers and MLOs meet consistent education and ethical requirements across states. This includes pre-licensing education, continuing education, and background checks.  </p>
                                        </li>
                                        <li>
                                            <h3> Consumer Protection </h3>
                                            <p>NMLS enhances consumer protection by maintaining a public access database where consumers can verify the licensing status and history of mortgage professionals. This transparency helps build trust and credibility in the mortgage industry. </p>
                                        </li>
                                        <li>
                                            <h3>Regulatory Oversight </h3>
                                            <p>The system facilitates better regulatory oversight by providing state regulators with a comprehensive tool to monitor and supervise licensed mortgage professionals. It also aids in identifying and addressing any regulatory issues promptly. </p>
                                        </li>
                                    </ol>
                                    <div class="heading pt-60">
                                        <h2>Steps to Use NMLS for Mortgage Licensing</h2></div>
                                    <ol className='text-pt-0   cs-markers rs-steps-cs'>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 1</p>
                                                <h3>Create an NMLS Account </h3>
                                            </div>
                                            <ul>
                                                <li> The first step is to create an account on the NMLS website. This account will be used to manage your licensing applications and renewals.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 2</p>
                                                <h3> Complete Pre-Licensing Education </h3>
                                            </div>
                                            <ul>
                                                <li>  Complete the required 20 hours of NMLS-approved pre-licensing education, covering topics like federal and state mortgage laws, ethics, and mortgage origination.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 3</p>
                                                <h3>Pass the SAFE Mortgage Loan Originator Test </h3>
                                            </div>
                                            <ul>
                                                <li> The test includes both national and state-specific components. Preparation through mortgage broker training programs and certification courses can significantly improve your chances of passing.     </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 4</p>
                                                <h3>Submit Background Checks and Credit Report </h3>
                                            </div>
                                            <ul>
                                                <li>  Submit fingerprints for a thorough criminal background check.  </li>
                                                <li>  Provide a credit report for a financial responsibility assessment.    </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 5</p>
                                                <h3>Apply for State Licenses</h3>
                                            </div>
                                            <ul>
                                                <li> Use the NMLS platform to apply for licenses in your desired states. Each state may have specific requirements and fees.   </li>
                                            </ul>

                                        </li>
                                    </ol>

                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={fees}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Licensing Fees</span> and Step-by-Step Process Guide for Mortgage Brokers </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) involves several steps, each with associated fees. Understanding the licensing process and associated costs can help you prepare financially and logistically. This guide provides a comprehensive overview of the step-by-step process and licensing fees for aspiring mortgage brokers, incorporating relevant keywords like mortgage broker certification, training programs, and licensing requirements.   </p>
                                </Grid>
                                <Grid xs={12} item>
                                    <h3 className='pt-30'>Licensing Fees for Mortgage Brokers</h3>
                                    <p>The fees for obtaining a mortgage broker license vary by state and include several components. Below are typical fees associated with the licensing process: </p>
                                    <ol className='cs-markers2'>
                                        <li><strong>NMLS Registration Fee:</strong> Typically $30.</li>

                                        <li><strong>Credit Report Fee:</strong> Approximately $15. </li>

                                        <li><strong>FBI Criminal Background Check Fee:</strong> Around $36.25.</li>

                                        <li><strong>State Licensing Fees:</strong> Vary by state, ranging from $100 to $1,000. Some states may also charge an application fee. </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-0  build-business rs-steps'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} item>
                                    <div class="heading mb-20">
                                        <h2><span>Step-by-Step</span> Process Guide for Mortgage Brokers </h2></div>
                                </Grid>
                            </Grid>
                            <Grid container className='steps-container'>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 1: Pre-Licensing Education </h3>
                                        <h4>1.1. Complete Pre-Licensing Education </h4>
                                        <ul>
                                            <li><strong>NMLS-Approved Courses:</strong> Enroll in and complete 20 hours of NMLS-approved pre-licensing education. These courses cover federal and state mortgage laws, ethics, and mortgage origination practices.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 2: Create an NMLS Account </h3>
                                        <h4>2.1. NMLS Account Registration </h4>
                                        <ul>
                                            <li><strong>Online Registration: </strong> Visit the NMLS website and create an individual account. This account will be used to manage your licensing applications, renewals, and continuing education.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <img src={stepbystep}></img>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 3: Pass the SAFE Mortgage Loan Originator Test  </h3>
                                        <h4>3.1. Schedule the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Components: </strong>The SAFE MLO test includes both a national component and a state-specific component. Schedule your test through the NMLS portal. </li>
                                        </ul>
                                        <h4>3.2. Prepare for the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Prep Courses: </strong> Utilize mortgage broker exam prep resources and certification courses to increase your chances of passing.</li>
                                        </ul>
                                        <h4>3.3. Take and Pass the Exam </h4>
                                        <ul>
                                            <li><strong>Passing Scores: </strong> Achieve the required passing scores for both the national and state components of the exam.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 4: Submit Background Checks and Credit Report</h3>
                                        <h4>4.1. Criminal Background Check </h4>

                                        <ul>
                                            <li><strong>Fingerprint Submission:</strong>  Submit your fingerprints through an approved vendor for a thorough criminal background check. </li>
                                        </ul>

                                        <h4>4.2. Credit Report </h4>

                                        <ul>
                                            <li><strong>Credit Check:</strong>  Authorize NMLS to obtain a credit report to assess your financial responsibility. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 5: Apply for State Licenses </h3>

                                        <h4>5.1. Complete State-Specific Applications </h4>

                                        <ul>
                                            <li><strong>NMLS Submission:</strong>  Use the NMLS platform to submit your application for each state where you wish to be licensed. Each state may have unique requirements and fees. </li>
                                        </ul>

                                        <h4>5.2. Pay State Licensing Fees </h4>

                                        <ul>
                                            <li><strong>Fee Payment:</strong>  Pay the required state licensing fees through the NMLS system. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 6: Obtain Surety Bond (if required) </h3>

                                        <h4>6.1. Surety Bond Requirement </h4>

                                        <ul>
                                            <li><strong>State Requirements:</strong>  Some states require mortgage brokers to obtain a surety bond. The bond amount varies by state and serves as a financial guarantee of your professional conduct. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 7: Receive Your License </h3>

                                        <h4>7.1. Application Review </h4>

                                        <ul>
                                            <li><strong>Regulatory Approval: </strong> The state regulatory authority will review your application, background check, credit report, and exam results. </li>
                                        </ul>

                                        <h4>7.2. License Issuance </h4>

                                        <ul>
                                            <li><strong>Receive License: </strong> Upon approval, you will receive your mortgage broker license, allowing you to legally operate in your chosen state(s). </li>
                                        </ul>
                                    </div>
                                </Grid>
<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                              Join Now
                          </Link>
                              </Grid>
                            </Grid>
                            {/* </Grid> */}
                        </div>
                        </Container>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}><Container>
                        <div className='six-reasons'>
                            <Grid container>
                                <Grid xs={12} sm={12} item>
                                    <div class="heading text-center">
                                        <h2><span>Six Top Reasons</span> to Become a Mortgage Broker in Florida, Georgia, and Texas with RateShop Mortgage </h2>
                                    </div>
                                    <p className='text-center'>Are you considering a career as a Mortgage Broker or Mortgage Loan Originator in Florida, Georgia, or Texas? This dynamic and rewarding profession offers numerous benefits, making it an excellent choice for those looking to enter the real estate finance industry. Here are six top reasons to become a mortgage broker in these states, and how RateShop Mortgage can support you in this journey. </p>
                                </Grid>
                                {/* <Grid xs={12} sm={5} item>
                                <img src={topsixreasosn}></img>
                            </Grid> */}
                            </Grid>
                            <ol className='top-six-reasons'>
                                <li>
                                    <figure>
                                        <img src={highdemand}></img>
                                    </figure><div>
                                        <h3>High Demand for Mortgage Brokers</h3>
                                        <p>With the booming real estate markets in Florida, Georgia, and Texas, there is a constant demand for mortgage brokers. Homebuyers need professional guidance to navigate the complex mortgage process. As a mortgage broker, you'll be in a prime position to help clients secure the best loan options, ensuring a steady stream of business opportunities. RateShop Mortgage connects you with a broad network of clients, increasing your visibility and potential client base. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={lucrativeearning}></img>
                                    </figure><div>
                                        <h3>Lucrative Earnings Potential</h3>
                                        <p>Mortgage brokers in the US, especially in high-demand states like Florida, Georgia, and Texas, can earn a substantial income. According to industry data, the salary for mortgage brokers varies, but with dedication and a strong client base, you can achieve significant financial rewards. Your earnings are often commission-based, so the more loans you close, the higher your income. RateShop Mortgage provides competitive commission structures and incentives to maximize your earnings. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={careerpath}></img>
                                    </figure><div>
                                        <h3>Flexible Career Path</h3>
                                        <p>Becoming a mortgage broker offers a flexible career path. Whether you're transitioning from another profession or starting fresh, you can find mortgage broker training programs that fit your schedule. Online mortgage broker courses provide the flexibility to learn at your own pace, making it easier to balance your education with other commitments. RateShop Mortgage offers support and training programs along with ongoing one-on-one coaching to help you succeed. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={comprehensivetraining}></img>
                                    </figure><div>
                                        <h3>Comprehensive Training and Support </h3>
                                        <p>Mortgage broker certification and licensing requirements are well-structured in these states. You can find mortgage broker training programs and certification courses that prepare you thoroughly for the mortgage broker exam. Additionally, continuing education is essential to stay updated with the latest industry trends and regulations, ensuring you remain a competitive professional. RateShop Mortgage provides access to the best mortgage broker schools and resources to keep you informed and compliant. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={careergrowth}></img>
                                    </figure><div>
                                        <h3>Career Growth Opportunities </h3>
                                        <p>The career outlook for mortgage brokers and mortgage loan originators is promising. As the real estate market continues to grow, so does the need for skilled professionals. Steps to become a mortgage broker include gaining experience, building a network, and possibly starting your own mortgage brokerage business. The entrepreneurial aspect of this career can lead to significant growth and success. RateShop Mortgage offers mentorship and business development resources to help you grow your career. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={rewardingwork}></img>
                                    </figure><div>
                                        <h3>Impactful and Rewarding Work </h3>
                                        <p>Helping clients achieve their dream of homeownership is one of the most rewarding aspects of being a mortgage broker. Your role in securing the best mortgage loan terms for your clients can have a lasting positive impact on their financial well-being. This sense of fulfillment, combined with the potential for a lucrative career, makes becoming a mortgage broker an appealing choice. RateShop Mortgage supports you with tools and resources to deliver exceptional service to your clients. </p></div></li>

                            </ol>
                        </div>
                        {/*  */}
                        <div className='how-rateshop-help'>
                            <Grid container>
                                <Grid xs={12} item className=' text-center'>
                                    <div class="heading">
                                        <h2><span>How RateShop Mortgage</span> Can Help </h2>
                                        <p>RateShop Mortgage is dedicated to helping aspiring mortgage brokers in Florida, Georgia, and Texas succeed. Our comprehensive support includes: </p>
                                    </div>
                                </Grid>

                                <Grid xs={12} lg={6} item>
                                    <ul>
                                        <li>
                                            <figure>
                                                <img src={extensivetraining}></img>
                                            </figure>
                                            <p><strong>Extensive Training Programs:</strong> Access to top mortgage broker training programs and certification courses.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={ten}></img>
                                            </figure><p><strong>Ongoing Education:</strong> Resources for continuing education to keep you updated with industry trends and regulations.</p></li>
                                        <li>
                                            <figure>
                                                <img src={competitivecommission}></img>
                                            </figure><p><strong>Competitive Commission Structures:</strong> Lucrative earnings potential with competitive commission rates and incentives. </p></li>
                                        <li>
                                            <figure>
                                                <img src={mentorship}></img>
                                            </figure><p><strong>Mentorship and Business Development:</strong> Guidance and resources to help you grow and succeed in your career.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={clientnetwork}></img>
                                            </figure><p><strong>Client Network:</strong> Connection to a broad network of clients to enhance your visibility and client base. </p> </li>
                                        <li>
                                            <figure>
                                                <img src={toolsandresources}></img>
                                            </figure><p><strong>Exceptional Tools and Resources:</strong> Tools and resources to provide outstanding service to your clients. </p> </li>
                                    </ul>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <img src={howrateshopcanhelp}></img>
                                </Grid>

                                <Grid xs={12} className='d-flex justify-content-center mt-30'>
                                    {/* new  */}
                            <Link onClick={handleClickOpen1} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                Schedule a Meeting
                            </Link>
                                </Grid>
                            </Grid>
                        </div>
                        </Container>
                        <section className="work-with">
                <Container>
                    <div class="heading text-center"><h2><span>Why work </span> with some, When you can work with All of them</h2></div>
                </Container>
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                        1300: {
                            slidesPerView: 7,
                        },
                    }}
                    spaceBetween={50}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                    }}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" />
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper>
                {/* <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                        1300: {
                            slidesPerView: 7,
                        },
                    }}
                    spaceBetween={50}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                        reverseDirection: true,
                    }}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" />
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper> */}
            </section><Container>
                        <div className='sales-overview mortgage-broker pt-0'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={salaryoverview}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Salary Overview </h2></div>
                                    <p>Mortgage brokers in the US have the potential to earn a lucrative income, often based on commission structures. The average salary can vary widely depending on the state and the broker's experience level. Here, we analyze mortgage broker salaries in some of the top states for this profession: Florida, Texas, and Georgia. </p>
                                </Grid>
                            </Grid>
                        </div>

                        {/* state */}
                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>Growing Market</span>  with Competitive Compensation </h2>
                                <p>Georgia's real estate market is growing, offering promising opportunities for mortgage brokers. The average mortgage broker salary in Georgia ranges from $50,000 to $95,000 annually, with higher earnings potential for experienced brokers in metropolitan areas like Atlanta. This can change drastically and push upwards of $200,000 if you follow our lead generation program and convert brokerage leads for higher compensation.  </p></div>
                            <p><strong>Steps to Become a Mortgage Broker in Georgia:  </strong></p>
                            <ul>
                                <li>Complete the required education through mortgage broker certification courses. </li>
                                <li>Pass the mortgage broker exam. </li>
                                <li>Obtain licensing through the Georgia Department of Banking and Finance.  </li>
                                <li>Stay informed with continuing education to remain competitive in the market.</li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>Key Factors</span> Influencing Mortgage Broker Income </h2>
                                <p>Several factors influence the income and compensation of mortgage brokers across different states: </p>  </div>

                            <ul>
                                <li><strong>Market Demand:</strong> States with booming real estate markets tend to offer higher earning potential for mortgage brokers. </li>
                                <li><strong>Experience and Network:</strong> Experienced brokers with a strong network of clients and real estate professionals typically earn more. </li>
                                <li><strong>Commission Structure:</strong> Mortgage brokers often earn a significant portion of their income through commissions, making the number of loans closed a crucial factor. </li>
                                <li><strong>Education and Certification:</strong> Completing mortgage broker training programs and obtaining the necessary certifications can enhance earning potential. </li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='sales-overview  build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Job Responsibilities </h2></div>
                                    <p>A career as a mortgage broker or mortgage loan originator (MLO) is both rewarding and impactful, blending financial expertise with the ability to help clients achieve their homeownership dreams. Understanding the job responsibilities of a mortgage broker is crucial for those considering this profession. Here, we explore the key duties of a mortgage broker, incorporating essential keywords like mortgage broker certification, training programs, licensing requirements, and more. </p>
                                </Grid>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={brokerresponsibilities}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    <div class="heading pt-30">
                                        <h2> Key Responsibilities  of a Mortgage Broker</h2></div>
                                    <ol className='key-responsibilities'>
                                        <li>
                                            <h3>Client Consultation and Assessment </h3>
                                            <p>One of the primary responsibilities of a mortgage broker is to consult with clients to understand their financial situation, needs, and goals. This involves: </p>
                                            <ul>
                                                <li>Conducting in-depth interviews to gather financial information.     </li>
                                                <li>Assessing credit reports and financial documents. </li>
                                                <li>Advising clients on mortgage products that best suit their needs. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Loan Application and Documentation</h3>
                                            <p>Mortgage brokers and mortgage loan originators play a crucial role in the loan application process. Their duties include: </p>
                                            <ul>
                                                <li>Helping clients complete mortgage loan applications. </li>
                                                <li>Ensuring all required documentation is collected and accurate. </li>
                                                <li>Submitting loan applications to lenders for approval. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Market Research and Analysis </h3>
                                            <p>A successful mortgage broker stays informed about market trends and mortgage products. This involves: </p>
                                            <ul>
                                                <li>Researching different loan products and interest rates. </li>
                                                <li>Analyzing market trends to provide clients with the best mortgage options. </li>
                                                <li>Keeping up-to-date with changes in mortgage broker licensing requirements and regulations. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Liaison Between Clients and Lenders </h3>
                                            <p>Mortgage brokers act as intermediaries between clients and lenders. Their responsibilities include: </p>
                                            <ul>
                                                <li>Communicating with lenders to find the best loan terms and interest rates. </li>
                                                <li>Negotiating loan terms on behalf of clients. </li>
                                                <li>Keeping clients informed throughout the loan approval process. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3> Compliance and Regulatory Adherence </h3>
                                            <p>Ensuring compliance with state and federal regulations is a critical aspect of a mortgage broker's job. This involves: </p>
                                            <ul>
                                                <li>Understanding and adhering to mortgage broker state regulations. </li>
                                                <li>Keeping up with continuing education requirements to maintain mortgage broker certification. </li>
                                                <li>Ensuring all transactions comply with legal and ethical standards. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Building and Maintaining Relationships </h3>
                                            <p>Building strong relationships with clients, lenders, and other real estate professionals is essential for a mortgage broker. This includes: </p>
                                            <ul>
                                                <li>Networking with real estate agents, lenders, and clients. </li>
                                                <li>Providing excellent customer service to build trust and repeat business. </li>
                                                <li>Engaging in professional development through mortgage broker training programs and certification courses. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Marketing and Business Development </h3>
                                            <p>Mortgage brokers often engage in marketing and business development activities to grow their client base. This involves: </p>
                                            <ul>
                                                <li>Developing a business plan and marketing strategy. </li>
                                                <li>Utilizing social media and online platforms to attract clients. </li>
                                                <li>Attending industry events and networking opportunities to build professional connections. </li>
                                            </ul>
                                        </li>

                                    </ol>
                                </Grid>
<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                              Join Now
                          </Link>
                              </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={prerequisites}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Prerequisites</span> to Become a Mortgage Broker in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific prerequisites and following a series of steps. This guide outlines the essential requirements and steps to help you embark on a successful career in this dynamic field. </p>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Prerequisites for Becoming a Mortgage Broker in Georgia </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Education Requirements </h3>
                                            <ul>
                                                <li><strong>Pre-Licensing Education:</strong> Complete at least 20 hours of NMLS-approved pre-licensing education. Topics include federal and state mortgage laws, ethics, and mortgage origination practices. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam </h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test:</strong> This includes a national test and a state-specific component for Georgia. Consider taking mortgage broker certification courses to prepare effectively. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:</strong> Submit fingerprints for a thorough criminal background check.  </li>
                                                <li><strong>Credit Report: </strong> Provide a credit report for a financial responsibility assessment.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration:</strong> Register with the NMLS and submit your application.</li>
                                                <li><strong>Georgia State License:</strong> Apply for your mortgage broker license through the Georgia Department of Banking and Finance.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}
                                </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker Licensing</span> Requirements in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific requirements. This guide outlines the essential steps and prerequisites for obtaining a mortgage broker license, integrating the role of an MLO, and showcasing how RateShop Mortgage can assist you in this process.  </p>
                                </Grid>
                                <Grid xs={12} md={4} item className=' mob-30'>
                                    <img src={requirements}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Mortgage Broker Licensing Requirements in Georgia  </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3> Pre-Licensing Education</h3>
                                            <ul>
                                                <li><strong>Complete Pre-Licensing Education:</strong>Mortgage Broker Licensing Requirements in Georgia </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam</h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test: </strong>Mortgage Broker Licensing Requirements in Georgia  </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check  </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:  </strong> Submit fingerprints for a criminal background check.  </li>
                                                <li><strong>Credit Report: </strong> Provide a credit report for a financial responsibility assessment.  </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration: </strong> Register with the NMLS and submit your application. </li>
                                                <li><strong>Georgia State License:  </strong>Apply for your mortgage broker license through the Georgia Department of Banking and Finance.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}

                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60 build-business'>
                            <Grid container  >
                                <Grid xs={12} item>
                                    <div class="heading">
                                        <h2><span>Understanding</span> the Nationwide Multistate Licensing System (NMLS) </h2></div>
                                    <p>The Nationwide Multistate Licensing System (NMLS) is an essential component for anyone aspiring to become a mortgage broker or mortgage loan originator (MLO) in the United States. It serves as the centralized platform for licensing and regulatory information for the financial services industry. This guide provides an in-depth look at what NMLS is, its purpose, and how it impacts the mortgage industry, integrating relevant keywords like mortgage broker certification, training programs, and licensing requirements.  </p>
                                    <div class="heading pt-20">
                                        <h2>What is NMLS?</h2></div>
                                    <p>The NMLS is a secure, web-based system created to streamline the licensing process for mortgage professionals, including mortgage brokers and MLOs. It was developed by the Conference of State Bank Supervisors (CSBS) and the American Association of Residential Mortgage Regulators (AARMR) to enhance regulatory oversight and consumer protection. </p>

                                    <h3 className='pt-20'>Key Functions of NMLS </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Centralized Licensing Platform </h3>
                                            <p>NMLS provides a single platform for managing state licenses for mortgage professionals. It allows users to apply for, renew, and maintain their licenses in one place, ensuring compliance with state-specific regulations.</p>
                                        </li>
                                        <li>
                                            <h3>Uniform Licensing Standards </h3>
                                            <p>By establishing uniform licensing standards, NMLS helps ensure that mortgage brokers and MLOs meet consistent education and ethical requirements across states. This includes pre-licensing education, continuing education, and background checks.  </p>
                                        </li>
                                        <li>
                                            <h3> Consumer Protection </h3>
                                            <p>NMLS enhances consumer protection by maintaining a public access database where consumers can verify the licensing status and history of mortgage professionals. This transparency helps build trust and credibility in the mortgage industry. </p>
                                        </li>
                                        <li>
                                            <h3>Regulatory Oversight </h3>
                                            <p>The system facilitates better regulatory oversight by providing state regulators with a comprehensive tool to monitor and supervise licensed mortgage professionals. It also aids in identifying and addressing any regulatory issues promptly. </p>
                                        </li>
                                    </ol>
                                    <div class="heading pt-60">
                                        <h2>Steps to Use NMLS for Mortgage Licensing</h2></div>
                             
                                        <ol className='text-pt-0   cs-markers rs-steps-cs'>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 1</p>
                                                <h3>Create an NMLS Account </h3>
                                            </div>
                                            <ul>
                                                <li> The first step is to create an account on the NMLS website. This account will be used to manage your licensing applications and renewals.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 2</p>
                                                <h3> Complete Pre-Licensing Education </h3>
                                            </div>
                                            <ul>
                                                <li>  Complete the required 20 hours of NMLS-approved pre-licensing education, covering topics like federal and state mortgage laws, ethics, and mortgage origination.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 3</p>
                                                <h3>Pass the SAFE Mortgage Loan Originator Test </h3>
                                            </div>
                                            <ul>
                                                <li> The test includes both national and state-specific components. Preparation through mortgage broker training programs and certification courses can significantly improve your chances of passing.     </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 4</p>
                                                <h3>Submit Background Checks and Credit Report </h3>
                                            </div>
                                            <ul>
                                                <li>  Submit fingerprints for a thorough criminal background check.  </li>
                                                <li>  Provide a credit report for a financial responsibility assessment.    </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 5</p>
                                                <h3>Apply for State Licenses</h3>
                                            </div>
                                            <ul>
                                                <li> Use the NMLS platform to apply for licenses in your desired states. Each state may have specific requirements and fees.   </li>
                                            </ul>

                                        </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60  build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={fees}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Licensing Fees</span> and Step-by-Step Process Guide for Mortgage Brokers </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) involves several steps, each with associated fees. Understanding the licensing process and associated costs can help you prepare financially and logistically. This guide provides a comprehensive overview of the step-by-step process and licensing fees for aspiring mortgage brokers, incorporating relevant keywords like mortgage broker certification, training programs, and licensing requirements.   </p>
                                </Grid>
                                <Grid xs={12} item>
                                    <h3 className='pt-30'>Licensing Fees for Mortgage Brokers</h3>
                                    <p>The fees for obtaining a mortgage broker license vary by state and include several components. Below are typical fees associated with the licensing process: </p>
                                    <ol className='cs-markers2'>
                                        <li><strong>NMLS Registration Fee:</strong> Typically $30.</li>

                                        <li><strong>Credit Report Fee:</strong> Approximately $15. </li>

                                        <li><strong>FBI Criminal Background Check Fee:</strong> Around $36.25.</li>

                                        <li><strong>State Licensing Fees:</strong> Vary by state, ranging from $100 to $1,000. Some states may also charge an application fee. </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-0  build-business rs-steps'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} item>
                                    <div class="heading mb-20">
                                        <h2><span>Step-by-Step</span> Process Guide for Mortgage Brokers </h2></div>
                                </Grid>
                            </Grid>
                            <Grid container className='steps-container'>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 1: Pre-Licensing Education </h3>
                                        <h4>1.1. Complete Pre-Licensing Education </h4>
                                        <ul>
                                            <li><strong>NMLS-Approved Courses:</strong> Enroll in and complete 20 hours of NMLS-approved pre-licensing education. These courses cover federal and state mortgage laws, ethics, and mortgage origination practices.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 2: Create an NMLS Account </h3>
                                        <h4>2.1. NMLS Account Registration </h4>
                                        <ul>
                                            <li><strong>Online Registration: </strong> Visit the NMLS website and create an individual account. This account will be used to manage your licensing applications, renewals, and continuing education.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <img src={stepbystep}></img>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 3: Pass the SAFE Mortgage Loan Originator Test  </h3>
                                        <h4>3.1. Schedule the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Components: </strong>The SAFE MLO test includes both a national component and a state-specific component. Schedule your test through the NMLS portal. </li>
                                        </ul>
                                        <h4>3.2. Prepare for the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Prep Courses: </strong> Utilize mortgage broker exam prep resources and certification courses to increase your chances of passing.</li>
                                        </ul>
                                        <h4>3.3. Take and Pass the Exam </h4>
                                        <ul>
                                            <li><strong>Passing Scores: </strong> Achieve the required passing scores for both the national and state components of the exam.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 4: Submit Background Checks and Credit Report</h3>
                                        <h4>4.1. Criminal Background Check </h4>

                                        <ul>
                                            <li><strong>Fingerprint Submission:</strong>  Submit your fingerprints through an approved vendor for a thorough criminal background check. </li>
                                        </ul>

                                        <h4>4.2. Credit Report </h4>

                                        <ul>
                                            <li><strong>Credit Check:</strong>  Authorize NMLS to obtain a credit report to assess your financial responsibility. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 5: Apply for State Licenses </h3>

                                        <h4>5.1. Complete State-Specific Applications </h4>

                                        <ul>
                                            <li><strong>NMLS Submission:</strong>  Use the NMLS platform to submit your application for each state where you wish to be licensed. Each state may have unique requirements and fees. </li>
                                        </ul>

                                        <h4>5.2. Pay State Licensing Fees </h4>

                                        <ul>
                                            <li><strong>Fee Payment:</strong>  Pay the required state licensing fees through the NMLS system. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 6: Obtain Surety Bond (if required) </h3>

                                        <h4>6.1. Surety Bond Requirement </h4>

                                        <ul>
                                            <li><strong>State Requirements:</strong>  Some states require mortgage brokers to obtain a surety bond. The bond amount varies by state and serves as a financial guarantee of your professional conduct. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 7: Receive Your License </h3>

                                        <h4>7.1. Application Review </h4>

                                        <ul>
                                            <li><strong>Regulatory Approval: </strong> The state regulatory authority will review your application, background check, credit report, and exam results. </li>
                                        </ul>

                                        <h4>7.2. License Issuance </h4>

                                        <ul>
                                            <li><strong>Receive License: </strong> Upon approval, you will receive your mortgage broker license, allowing you to legally operate in your chosen state(s). </li>
                                        </ul>
                                    </div>
                                </Grid>

<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                             Join Now
                          </Link>
                              </Grid>
                            </Grid>
                            {/* </Grid> */}
                        </div></Container>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}><Container>
                        <div className='six-reasons'>
                            <Grid container>
                                <Grid xs={12} sm={12} item>
                                    <div class="heading text-center">
                                        <h2><span>Six Top Reasons</span> to Become a Mortgage Broker in Florida, Georgia, and Texas with RateShop Mortgage </h2>
                                    </div>
                                    <p className='text-center'>Are you considering a career as a Mortgage Broker or Mortgage Loan Originator in Florida, Georgia, or Texas? This dynamic and rewarding profession offers numerous benefits, making it an excellent choice for those looking to enter the real estate finance industry. Here are six top reasons to become a mortgage broker in these states, and how RateShop Mortgage can support you in this journey. </p>
                                </Grid>
                                {/* <Grid xs={12} sm={5} item>
                                <img src={topsixreasosn}></img>
                            </Grid> */}
                            </Grid>
                            <ol className='top-six-reasons'>
                                <li>
                                    <figure>
                                        <img src={highdemand}></img>
                                    </figure><div>
                                        <h3>High Demand for Mortgage Brokers</h3>
                                        <p>With the booming real estate markets in Florida, Georgia, and Texas, there is a constant demand for mortgage brokers. Homebuyers need professional guidance to navigate the complex mortgage process. As a mortgage broker, you'll be in a prime position to help clients secure the best loan options, ensuring a steady stream of business opportunities. RateShop Mortgage connects you with a broad network of clients, increasing your visibility and potential client base. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={lucrativeearning}></img>
                                    </figure><div>
                                        <h3>Lucrative Earnings Potential</h3>
                                        <p>Mortgage brokers in the US, especially in high-demand states like Florida, Georgia, and Texas, can earn a substantial income. According to industry data, the salary for mortgage brokers varies, but with dedication and a strong client base, you can achieve significant financial rewards. Your earnings are often commission-based, so the more loans you close, the higher your income. RateShop Mortgage provides competitive commission structures and incentives to maximize your earnings. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={careerpath}></img>
                                    </figure><div>
                                        <h3>Flexible Career Path</h3>
                                        <p>Becoming a mortgage broker offers a flexible career path. Whether you're transitioning from another profession or starting fresh, you can find mortgage broker training programs that fit your schedule. Online mortgage broker courses provide the flexibility to learn at your own pace, making it easier to balance your education with other commitments. RateShop Mortgage offers support and training programs along with ongoing one-on-one coaching to help you succeed. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={comprehensivetraining}></img>
                                    </figure><div>
                                        <h3>Comprehensive Training and Support </h3>
                                        <p>Mortgage broker certification and licensing requirements are well-structured in these states. You can find mortgage broker training programs and certification courses that prepare you thoroughly for the mortgage broker exam. Additionally, continuing education is essential to stay updated with the latest industry trends and regulations, ensuring you remain a competitive professional. RateShop Mortgage provides access to the best mortgage broker schools and resources to keep you informed and compliant. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={careergrowth}></img>
                                    </figure><div>
                                        <h3>Career Growth Opportunities </h3>
                                        <p>The career outlook for mortgage brokers and mortgage loan originators is promising. As the real estate market continues to grow, so does the need for skilled professionals. Steps to become a mortgage broker include gaining experience, building a network, and possibly starting your own mortgage brokerage business. The entrepreneurial aspect of this career can lead to significant growth and success. RateShop Mortgage offers mentorship and business development resources to help you grow your career. </p></div></li>
                                <li>
                                    <figure>
                                        <img src={rewardingwork}></img>
                                    </figure><div>
                                        <h3>Impactful and Rewarding Work </h3>
                                        <p>Helping clients achieve their dream of homeownership is one of the most rewarding aspects of being a mortgage broker. Your role in securing the best mortgage loan terms for your clients can have a lasting positive impact on their financial well-being. This sense of fulfillment, combined with the potential for a lucrative career, makes becoming a mortgage broker an appealing choice. RateShop Mortgage supports you with tools and resources to deliver exceptional service to your clients. </p></div></li>

                            </ol>
                        </div>
                        {/*  */}
                        <div className='how-rateshop-help'>
                            <Grid container>
                                <Grid xs={12} item className=' text-center'>
                                    <div class="heading">
                                        <h2><span>How RateShop Mortgage</span> Can Help </h2>
                                        <p>RateShop Mortgage is dedicated to helping aspiring mortgage brokers in Florida, Georgia, and Texas succeed. Our comprehensive support includes: </p>
                                    </div>
                                </Grid>

                                <Grid xs={12} lg={6} item>
                                    <ul>
                                        <li>
                                            <figure>
                                                <img src={extensivetraining}></img>
                                            </figure>
                                            <p><strong>Extensive Training Programs:</strong> Access to top mortgage broker training programs and certification courses.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={ten}></img>
                                            </figure><p><strong>Ongoing Education:</strong> Resources for continuing education to keep you updated with industry trends and regulations.</p></li>
                                        <li>
                                            <figure>
                                                <img src={competitivecommission}></img>
                                            </figure><p><strong>Competitive Commission Structures:</strong> Lucrative earnings potential with competitive commission rates and incentives. </p></li>
                                        <li>
                                            <figure>
                                                <img src={mentorship}></img>
                                            </figure><p><strong>Mentorship and Business Development:</strong> Guidance and resources to help you grow and succeed in your career.</p> </li>
                                        <li>
                                            <figure>
                                                <img src={clientnetwork}></img>
                                            </figure><p><strong>Client Network:</strong> Connection to a broad network of clients to enhance your visibility and client base. </p> </li>
                                        <li>
                                            <figure>
                                                <img src={toolsandresources}></img>
                                            </figure><p><strong>Exceptional Tools and Resources:</strong> Tools and resources to provide outstanding service to your clients. </p> </li>
                                    </ul>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <img src={howrateshopcanhelp}></img>
                                </Grid>

                                <Grid xs={12} className='d-flex justify-content-center mt-30'>
                                    {/* new  */}
                            <Link onClick={handleClickOpen1} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                Schedule a Meeting
                            </Link>
                                </Grid>
                            </Grid>
                        </div>
                        </Container>
                        <section className="work-with">
                <Container>
                    <div class="heading text-center"><h2><span>Why work </span> with some, When you can work with All of them</h2></div>
                </Container>
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                        1300: {
                            slidesPerView: 7,
                        },
                    }}
                    spaceBetween={50}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                    }}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" />
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper>
                {/* <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                        1300: {
                            slidesPerView: 7,
                        },
                    }}
                    spaceBetween={50}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                        reverseDirection: true,
                    }}
                    speed={2000}
                    simulateTouch={false}
                    modules={[Autoplay]}
                    allowTouchMove={false}
                >
                    {partnerLogos.map((item) =>
                        <SwiperSlide className="item">
                            <figure>
                                <img src={item.img} alt="partner" />
                            </figure>
                        </SwiperSlide>
                    )}
                </Swiper> */}
            </section><Container>
                        <div className='sales-overview mortgage-broker pt-0'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={salaryoverview}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Salary Overview </h2></div>
                                    <p>Mortgage brokers in the US have the potential to earn a lucrative income, often based on commission structures. The average salary can vary widely depending on the state and the broker's experience level. Here, we analyze mortgage broker salaries in some of the top states for this profession: Florida, Texas, and Georgia. </p>
                                </Grid>
                            </Grid>
                        </div>

                        {/* state */}

                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>Lucrative Market</span>  with Growth Potential </h2>
                                <p>Texas offers significant opportunities for mortgage brokers due to its booming housing market. The average salary for mortgage brokers in Texas ranges from $55,000 to $110,000 annually. Major cities like Houston, Dallas, and Austin provide ample opportunities for brokers to build a successful career. </p></div>
                            <p><strong>Steps to Become a Mortgage Broker in Texas:  </strong></p>
                            <ul>
                                <li>Complete the required pre-licensing education through online mortgage broker courses or in-person programs. </li>
                                <li>Pass the Texas mortgage broker exam. </li>
                                <li>Apply for a license through the Texas Department of Savings and Mortgage Lending. </li>
                                <li>Engage in ongoing education to maintain your license and stay competitive.  </li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='high-demands build-business'>
                            <div class="heading text-center">
                                <h2><span>Key Factors</span> Influencing Mortgage Broker Income </h2>
                                <p>Several factors influence the income and compensation of mortgage brokers across different states: </p>  </div>

                            <ul>
                                <li><strong>Market Demand:</strong> States with booming real estate markets tend to offer higher earning potential for mortgage brokers. </li>
                                <li><strong>Experience and Network:</strong> Experienced brokers with a strong network of clients and real estate professionals typically earn more. </li>
                                <li><strong>Commission Structure:</strong> Mortgage brokers often earn a significant portion of their income through commissions, making the number of loans closed a crucial factor. </li>
                                <li><strong>Education and Certification:</strong> Completing mortgage broker training programs and obtaining the necessary certifications can enhance earning potential. </li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className='sales-overview  build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker</span> Job Responsibilities </h2></div>
                                    <p>A career as a mortgage broker or mortgage loan originator (MLO) is both rewarding and impactful, blending financial expertise with the ability to help clients achieve their homeownership dreams. Understanding the job responsibilities of a mortgage broker is crucial for those considering this profession. Here, we explore the key duties of a mortgage broker, incorporating essential keywords like mortgage broker certification, training programs, licensing requirements, and more. </p>
                                </Grid>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={brokerresponsibilities}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    <div class="heading pt-30">
                                        <h2> Key Responsibilities  of a Mortgage Broker</h2></div>
                                    <ol className='key-responsibilities'>
                                        <li>
                                            <h3>Client Consultation and Assessment </h3>
                                            <p>One of the primary responsibilities of a mortgage broker is to consult with clients to understand their financial situation, needs, and goals. This involves: </p>
                                            <ul>
                                                <li>Conducting in-depth interviews to gather financial information.     </li>
                                                <li>Assessing credit reports and financial documents. </li>
                                                <li>Advising clients on mortgage products that best suit their needs. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Loan Application and Documentation</h3>
                                            <p>Mortgage brokers and mortgage loan originators play a crucial role in the loan application process. Their duties include: </p>
                                            <ul>
                                                <li>Helping clients complete mortgage loan applications. </li>
                                                <li>Ensuring all required documentation is collected and accurate. </li>
                                                <li>Submitting loan applications to lenders for approval. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Market Research and Analysis </h3>
                                            <p>A successful mortgage broker stays informed about market trends and mortgage products. This involves: </p>
                                            <ul>
                                                <li>Researching different loan products and interest rates. </li>
                                                <li>Analyzing market trends to provide clients with the best mortgage options. </li>
                                                <li>Keeping up-to-date with changes in mortgage broker licensing requirements and regulations. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Liaison Between Clients and Lenders </h3>
                                            <p>Mortgage brokers act as intermediaries between clients and lenders. Their responsibilities include: </p>
                                            <ul>
                                                <li>Communicating with lenders to find the best loan terms and interest rates. </li>
                                                <li>Negotiating loan terms on behalf of clients. </li>
                                                <li>Keeping clients informed throughout the loan approval process. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3> Compliance and Regulatory Adherence </h3>
                                            <p>Ensuring compliance with state and federal regulations is a critical aspect of a mortgage broker's job. This involves: </p>
                                            <ul>
                                                <li>Understanding and adhering to mortgage broker state regulations. </li>
                                                <li>Keeping up with continuing education requirements to maintain mortgage broker certification. </li>
                                                <li>Ensuring all transactions comply with legal and ethical standards. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Building and Maintaining Relationships </h3>
                                            <p>Building strong relationships with clients, lenders, and other real estate professionals is essential for a mortgage broker. This includes: </p>
                                            <ul>
                                                <li>Networking with real estate agents, lenders, and clients. </li>
                                                <li>Providing excellent customer service to build trust and repeat business. </li>
                                                <li>Engaging in professional development through mortgage broker training programs and certification courses. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Marketing and Business Development </h3>
                                            <p>Mortgage brokers often engage in marketing and business development activities to grow their client base. This involves: </p>
                                            <ul>
                                                <li>Developing a business plan and marketing strategy. </li>
                                                <li>Utilizing social media and online platforms to attract clients. </li>
                                                <li>Attending industry events and networking opportunities to build professional connections. </li>
                                            </ul>
                                        </li>

                                    </ol>
                                </Grid>
<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                              Join Now
                          </Link>
                              </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={prerequisites}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Prerequisites</span> to Become a Mortgage Broker in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific prerequisites and following a series of steps. This guide outlines the essential requirements and steps to help you embark on a successful career in this dynamic field. </p>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Prerequisites for Becoming a Mortgage Broker in Texas  </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Education Requirements </h3>
                                            <ul>
                                                <li><strong>Pre-Licensing Education:</strong> Complete 20 hours of NMLS-approved pre-licensing education, covering federal and state mortgage laws, ethics, and loan origination.    </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam </h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test:</strong> This includes both national and Texas-specific components. Utilize mortgage broker training programs to prepare for the exam. </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:</strong> Submit fingerprints for a criminal background check.  </li>
                                                <li><strong>Credit Report:</strong> A credit report review is necessary to assess your financial responsibility.  </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration:</strong> Create an account with the NMLS and submit your application. </li>
                                                <li><strong>Texas State License:</strong> Create an account with the NMLS and submit your application. </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}
                                </Grid>
                            </Grid>
                        </div>
                        {/*  */}
                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={8} item>
                                    <div class="heading">
                                        <h2><span>Mortgage Broker Licensing</span> Requirements in Florida, Georgia, and Texas </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) in states like Florida, Georgia, and Texas involves meeting specific requirements. This guide outlines the essential steps and prerequisites for obtaining a mortgage broker license, integrating the role of an MLO, and showcasing how RateShop Mortgage can assist you in this process.  </p>
                                </Grid>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={requirements}></img>
                                </Grid>
                                <Grid xs={12} item>
                                    {/* state */}
                                    <h3 className='pt-30'>Mortgage Broker Licensing Requirements in Texas  </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3> Pre-Licensing Education</h3>
                                            <ul>
                                                <li><strong>Complete Pre-Licensing Education:</strong>Complete 20 hours of NMLS-approved pre-licensing education, covering federal and state mortgage laws, ethics, and loan origination practices.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Licensing Exam</h3>
                                            <ul>
                                                <li><strong>Pass the SAFE Mortgage Loan Originator Test: </strong> The test includes both national and Texas-specific components. Utilize mortgage broker training programs to prepare for the exam.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Background Check  </h3>
                                            <ul>
                                                <li><strong>Criminal Background Check:  </strong> Submit fingerprints for a criminal background check.  </li>
                                                <li><strong>Credit Report: </strong> A credit report review is necessary to assess your financial responsibility.   </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Apply for a License </h3>
                                            <ul>
                                                <li><strong>NMLS Registration:</strong> Create an account with the NMLS and submit your application.</li>
                                                <li><strong>Texas State License</strong> Apply for your mortgage broker license through the Texas Department of Savings and Mortgage Lending.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    {/*  */}

                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60 build-business'>
                            <Grid container  >
                                <Grid xs={12} item>
                                    <div class="heading">
                                        <h2><span>Understanding</span> the Nationwide Multistate Licensing System (NMLS) </h2></div>
                                    <p>The Nationwide Multistate Licensing System (NMLS) is an essential component for anyone aspiring to become a mortgage broker or mortgage loan originator (MLO) in the United States. It serves as the centralized platform for licensing and regulatory information for the financial services industry. This guide provides an in-depth look at what NMLS is, its purpose, and how it impacts the mortgage industry, integrating relevant keywords like mortgage broker certification, training programs, and licensing requirements.  </p>
                                    <div class="heading pt-20">
                                        <h2>What is NMLS?</h2></div>
                                    <p>The NMLS is a secure, web-based system created to streamline the licensing process for mortgage professionals, including mortgage brokers and MLOs. It was developed by the Conference of State Bank Supervisors (CSBS) and the American Association of Residential Mortgage Regulators (AARMR) to enhance regulatory oversight and consumer protection. </p>

                                    <h3 className='pt-20'>Key Functions of NMLS </h3>
                                    <ol className='cs-markers'>
                                        <li>
                                            <h3>Centralized Licensing Platform </h3>
                                            <p>NMLS provides a single platform for managing state licenses for mortgage professionals. It allows users to apply for, renew, and maintain their licenses in one place, ensuring compliance with state-specific regulations.</p>
                                        </li>
                                        <li>
                                            <h3>Uniform Licensing Standards </h3>
                                            <p>By establishing uniform licensing standards, NMLS helps ensure that mortgage brokers and MLOs meet consistent education and ethical requirements across states. This includes pre-licensing education, continuing education, and background checks.  </p>
                                        </li>
                                        <li>
                                            <h3> Consumer Protection </h3>
                                            <p>NMLS enhances consumer protection by maintaining a public access database where consumers can verify the licensing status and history of mortgage professionals. This transparency helps build trust and credibility in the mortgage industry. </p>
                                        </li>
                                        <li>
                                            <h3>Regulatory Oversight </h3>
                                            <p>The system facilitates better regulatory oversight by providing state regulators with a comprehensive tool to monitor and supervise licensed mortgage professionals. It also aids in identifying and addressing any regulatory issues promptly. </p>
                                        </li>
                                    </ol>
                                    <div class="heading pt-60">
                                        <h2>Steps to Use NMLS for Mortgage Licensing</h2></div>
                                 
                                       
                                    <ol className='text-pt-0   cs-markers rs-steps-cs'>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 1</p>
                                                <h3>Create an NMLS Account </h3>
                                            </div>
                                            <ul>
                                                <li> The first step is to create an account on the NMLS website. This account will be used to manage your licensing applications and renewals.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 2</p>
                                                <h3> Complete Pre-Licensing Education </h3>
                                            </div>
                                            <ul>
                                                <li>  Complete the required 20 hours of NMLS-approved pre-licensing education, covering topics like federal and state mortgage laws, ethics, and mortgage origination.   </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 3</p>
                                                <h3>Pass the SAFE Mortgage Loan Originator Test </h3>
                                            </div>
                                            <ul>
                                                <li> The test includes both national and state-specific components. Preparation through mortgage broker training programs and certification courses can significantly improve your chances of passing.     </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 4</p>
                                                <h3>Submit Background Checks and Credit Report </h3>
                                            </div>
                                            <ul>
                                                <li>  Submit fingerprints for a thorough criminal background check.  </li>
                                                <li>  Provide a credit report for a financial responsibility assessment.    </li>
                                            </ul>

                                        </li>
                                        <li>
                                            <div className='text-wrap'>
                                                <p>Step 5</p>
                                                <h3>Apply for State Licenses</h3>
                                            </div>
                                            <ul>
                                                <li> Use the NMLS platform to apply for licenses in your desired states. Each state may have specific requirements and fees.   </li>
                                            </ul>

                                        </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-60 build-business'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} md={4} item className='mob-30'>
                                    <img src={fees}></img>
                                </Grid>
                                <Grid xs={12} md={8} item className='reverse-first'>
                                    <div class="heading">
                                        <h2><span>Licensing Fees</span> and Step-by-Step Process Guide for Mortgage Brokers </h2></div>
                                    <p>Becoming a licensed mortgage broker or mortgage loan originator (MLO) involves several steps, each with associated fees. Understanding the licensing process and associated costs can help you prepare financially and logistically. This guide provides a comprehensive overview of the step-by-step process and licensing fees for aspiring mortgage brokers, incorporating relevant keywords like mortgage broker certification, training programs, and licensing requirements.   </p>
                                </Grid>
                                <Grid xs={12} item>
                                    <h3 className='pt-30'>Licensing Fees for Mortgage Brokers</h3>
                                    <p>The fees for obtaining a mortgage broker license vary by state and include several components. Below are typical fees associated with the licensing process: </p>
                                    <ol className='cs-markers2'>
                                        <li><strong>NMLS Registration Fee:</strong> Typically $30.</li>

                                        <li><strong>Credit Report Fee:</strong> Approximately $15. </li>

                                        <li><strong>FBI Criminal Background Check Fee:</strong> Around $36.25.</li>

                                        <li><strong>State Licensing Fees:</strong> Vary by state, ranging from $100 to $1,000. Some states may also charge an application fee. </li>
                                    </ol>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='sales-overview pb-0  build-business rs-steps'>
                            <Grid container className='align-items-center'>
                                <Grid xs={12} item>
                                    <div class="heading mb-20">
                                        <h2><span>Step-by-Step</span> Process Guide for Mortgage Brokers </h2></div>
                                </Grid>
                            </Grid>
                            <Grid container className='steps-container'>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 1: Pre-Licensing Education </h3>
                                        <h4>1.1. Complete Pre-Licensing Education </h4>
                                        <ul>
                                            <li><strong>NMLS-Approved Courses:</strong> Enroll in and complete 20 hours of NMLS-approved pre-licensing education. These courses cover federal and state mortgage laws, ethics, and mortgage origination practices.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 2: Create an NMLS Account </h3>
                                        <h4>2.1. NMLS Account Registration </h4>
                                        <ul>
                                            <li><strong>Online Registration: </strong> Visit the NMLS website and create an individual account. This account will be used to manage your licensing applications, renewals, and continuing education.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <img src={stepbystep}></img>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 3: Pass the SAFE Mortgage Loan Originator Test  </h3>
                                        <h4>3.1. Schedule the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Components: </strong>The SAFE MLO test includes both a national component and a state-specific component. Schedule your test through the NMLS portal. </li>
                                        </ul>
                                        <h4>3.2. Prepare for the Exam </h4>
                                        <ul>
                                            <li><strong>Exam Prep Courses: </strong> Utilize mortgage broker exam prep resources and certification courses to increase your chances of passing.</li>
                                        </ul>
                                        <h4>3.3. Take and Pass the Exam </h4>
                                        <ul>
                                            <li><strong>Passing Scores: </strong> Achieve the required passing scores for both the national and state components of the exam.</li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={6} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 4: Submit Background Checks and Credit Report</h3>
                                        <h4>4.1. Criminal Background Check </h4>

                                        <ul>
                                            <li><strong>Fingerprint Submission:</strong>  Submit your fingerprints through an approved vendor for a thorough criminal background check. </li>
                                        </ul>

                                        <h4>4.2. Credit Report </h4>

                                        <ul>
                                            <li><strong>Credit Check:</strong>  Authorize NMLS to obtain a credit report to assess your financial responsibility. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 5: Apply for State Licenses </h3>

                                        <h4>5.1. Complete State-Specific Applications </h4>

                                        <ul>
                                            <li><strong>NMLS Submission:</strong>  Use the NMLS platform to submit your application for each state where you wish to be licensed. Each state may have unique requirements and fees. </li>
                                        </ul>

                                        <h4>5.2. Pay State Licensing Fees </h4>

                                        <ul>
                                            <li><strong>Fee Payment:</strong>  Pay the required state licensing fees through the NMLS system. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 6: Obtain Surety Bond (if required) </h3>

                                        <h4>6.1. Surety Bond Requirement </h4>

                                        <ul>
                                            <li><strong>State Requirements:</strong>  Some states require mortgage brokers to obtain a surety bond. The bond amount varies by state and serves as a financial guarantee of your professional conduct. </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid xs={12} lg={4} item>
                                    <div className='inner-wrap'>
                                        <h3 className="pt-30">Step 7: Receive Your License </h3>

                                        <h4>7.1. Application Review </h4>

                                        <ul>
                                            <li><strong>Regulatory Approval: </strong> The state regulatory authority will review your application, background check, credit report, and exam results. </li>
                                        </ul>

                                        <h4>7.2. License Issuance </h4>

                                        <ul>
                                            <li><strong>Receive License: </strong> Upon approval, you will receive your mortgage broker license, allowing you to legally operate in your chosen state(s). </li>
                                        </ul>
                                    </div>
                                </Grid>
<Grid xs={12} className='d-flex justify-content-center mt-30'>
                                  {/* new  */}
                          <Link to='/join-us/packages' className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                             Join Now
                          </Link>
                              </Grid>
                            </Grid>
                            {/* </Grid> */}
                        </div></Container>
                    </CustomTabPanel>
                {/* </Container> */}
               
            </section >
            <section className='mortgge-new'>
                <Container>
                    <img src={mortgageNew} alt='' className='roung-cs-img'></img>
                </Container>
            </section>





















            {/* <section className="build-business join-now-included download-pdf">
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={12}  item>
                            <div class="heading text-center"><h2><span> What you </span> need to know about Licensing with NMLS  </h2></div>  
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} item>
                            <a href='' target='_blank'>
                                <img src={pdf} alt=""></img> 
                                <span>Florida Mortgage Loan Originator License Requirments Checklist </span>
                            </a>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} item>
                            <a href='' target='_blank'>
                                <img src={pdf} alt=""></img> 
                                <span>Georgia Mortgage Loan Originator License Requirments Checklis</span>
                            </a>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} item>
                            <a href='' target='_blank'>
                                <img src={pdf} alt=""></img> 
                                <span>Texas Mortgage Loan Originator License Requirments Checklist </span>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </section> */}


            {/* <section className="build-business join-now-included">
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <div class="heading"><h2><span>5 Easy Steps</span>  to Jumpstart Your Mortgage Career </h2></div>
                            <p>We made is clear and simple to get you the right information so you can plan your success with us: </p>
                            <ul>
                                <li>Complete Your Interview with Our Hiring Team </li>
                                <li>Register Online and get Setup for your Accounts & Transfer</li>
                                <li>Complete your Onboarding & Training</li>
                                <li>Complete your Lender & Technology setup to start deal submissions<br></br>
                                - Receive personalized support and training from an assigned account representative. <br></br> 
                                - Learn how to navigate new systems, tools, and processes with individualized assistance.</li>
                                <li>Schedule your One-on-One Broker Mentor Training and join our Weekly Company and Lender Training Webinars</li>
                            </ul>  
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <img src={supportCenter} alt=""></img> 
                        </Grid>
                    </Grid>
                </Container>
            </section> */}
            {/* join us start */}
            <section className="join-us">
                <Container>
                    <div class="heading text-center"><h2>Join us for the <span>Right Reasons</span></h2></div>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/EIJhmag6Fk8" title="Christopher Yu | Mortgage Advisor RateShop Mortgages Inc." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} item>
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/B8Y_bmAOs6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>

                        </Grid>
                    </Grid>
                </Container>
            </section >
            {/* join us start */}
            <section className="join-us join-bottom d-none">
                <Container>
                    <div class="heading text-center"><h2><span>RateShop Broker Fee</span>  (Level 1 & 2)</h2></div>
                    <Grid container>

                        <Grid xs={12} item>
                            <div id="zf-widget-root-id"></div>
                        </Grid>
                    </Grid>
                </Container>
            </section >

            <CalenderPopup2
                open={open}
                handleClose={handleClose}
            />

<CalenderPopup3
                open={open1}
                handleClose={handleClose1}
            />

        </>
    )
}

export default JoinUsNew;