import React from "react";
import { NavLink } from 'react-router-dom';
import { Container } from "@mui/material";
import logo from '../../../assets/public/images/usa/logo.png';
import './header.scss';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    const [state, setState] = React.useState({
        left: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const [baseUrl, setBaseUrl] = React.useState('/');

    return (
        <>
            <header className="join-header">
                <Container>
                    <nav className="navbar navbar-expand-lg">
                        <NavLink to={baseUrl} className="navbar-brand">
                            <img src={logo} className="img-fluid" />
                        </NavLink>
                        {/* <!-- social links  --> */}
                        <div className="header-social-link">
                            <ul>
                                <li>
                                    <div>
                                        <p>Call Us:<a href="tel:+888-549-1005">888-549-1005</a></p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <p>Email:<a href="mailto:careers@rateshop.us" className="cs-mail">careers@rateshop.us</a></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="header_social-icon">
                            <ul className="d-flex align-items-center">
                                <li>
                                    <a href="tel:+888-549-1005">
                                        <i className="fas fa-phone-alt"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:careers@rateshop.us">
                                        <i className="fas fa-envelope"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- social links end --> */}
                        {/* <!-- Toggle button --> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                        </button>
                        {/* <React.Fragment key="left">
                            <Button key="left" className="mob-menu" onClick={toggleDrawer('left', true)}>
                                <MenuIcon />
                            </Button>
                            <SwipeableDrawer
                                anchor="left"
                                className="join-drawer"
                                open={state.left}
                                onClose={toggleDrawer('left', false)}
                                onOpen={toggleDrawer('left', true)}
                            >
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item active">
                                            <a className="nav-link" href="javascript:void(0);" onClick={toggleDrawer('left', false)}>Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#marketing-sec" onClick={toggleDrawer('left', false)}>Marketing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#tools-sec" onClick={toggleDrawer('left', false)}>Tools</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#about-us-sec" onClick={toggleDrawer('left', false)}>About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to='/join-us/packages'>Join US Now</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </SwipeableDrawer>
                        </React.Fragment> */}
                        {/* <!-- Links --> */}
                        {/* <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav"> */}
                                {/* <li className="nav-item active">
                                    <a className="nav-link" href="javascript:void(0);">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#marketing-sec">Marketing</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#tools-sec">Tools</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#about-us-sec">About Us</a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" to='/join-us/packages'>Join US Now</NavLink>
                                </li> */}
                            {/* </ul>
                        </div> */}
                    </nav>
                </Container>
            </header>
        </>
    );
};

export default Header;
